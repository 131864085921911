@use "../common/colors";
@use '../common/order-history-list';

.order-history-widget {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  overflow: auto;

  > .categories {
    display: flex;
    margin-bottom: 24px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: colors.$blue-gray-500;

    > .category {
      flex: 1;
      padding: 12px 14px;
      margin: 0 8px;
      border-radius: 8px;
      background-color: colors.$blue-gray-50;
      text-align: center;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        background-color: colors.$blue-gray-800;
        color: colors.$white;
      }
    }
  }

  > .labels {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: order-history-list.$grid-template-columns;
    align-items: center;
    gap: 12px;

    margin-bottom: 10px;
    padding: 12px 24px;

    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    white-space: nowrap;
    color: colors.$blue-gray-500;
  }
}
