@use "../common/constants" as constants;
@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;
@use "../common/scrollbar" as scrollbar;
@use '../common/typography' as typography;
@use "../common/cta" as cta;

@mixin order-list-widget {
  .order-count {
    padding: 20px 30px;
    padding-bottom: 0;
  }

  .no-orders {
    text-align: center;
    margin-top: 20vh;
  }

  .orders-container {
    margin: 20px 0px;

    .orders {
      display: grid;
      grid-auto-rows: min-content;
      gap: 15px;
      padding: 10px;
      padding-top: 0;
      margin: 0 20px;

      @include scrollbar.hide();
    }
  }
}

@mixin order-list-item-widget {
  border-radius: constants.$border-radius;
  background: #{default-theme.$modifier-gradient-bg};
  box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
  position: relative;
  padding: 13px 20px;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  height: fit-content;

  .order-data {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 20% 15% 10% 20% 10% 20%;
    align-items: center;
    gap: 1%;

    .customer-info {
      padding: 0px 10px 0px 10px;

      .name {
        color: #{default-theme.$fg};
      }

      .mobile {
        font-size: 14px;
        color: #{default-theme.$tertiary};
      }
    }

    .collection-info {
      .collection-type {
        margin: 0;
        color: #{default-theme.$fg};
      }

      .preset-label {
        color: #{colors.$rose-600};
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
      }
    }

    .grand-total {
      text-align: left;
      margin: 0;
      font-size: 18px;
    }

    .status {
      font-weight: 600;
      width: fit-content;
      padding: 5px 15px;
      border-radius: 30px;

      &.processing {
        color: #{colors.$yellow-600};
        background-color: #{colors.$yellow-100};
      }

      &.cancelled {
        color: #{colors.$rose-800};
        background-color: #{colors.$rose-100};
      }

      &.success {
        color: #{colors.$green-800};
        background-color: #{colors.$green-100};
      }
    }

    .docket-number {
      text-align: left;
      margin: 0;
      font-size: 18px;
    }

    .placed-iso-datetime {
      margin: 0 auto;
      font-size: 13px;
      line-height: 20px;
    }
  }

  .actions {
    display: flex;
    gap: 20px;
    cursor: pointer;

    .print-icon {
      justify-self: end;
      opacity: 0.8;
      padding: 0px 10px;

      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }
  }
}
