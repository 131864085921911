@use "../common/card" as card;
@use "../common/cta" as cta;
@use "../common/ghost" as ghost;
@use "../common/colors" as colors;
@use "../common/typography" as typography;

.future-table-booking-list-item-widget {
  border-bottom: 1px solid colors.$blue-gray-100;
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr 1.5fr repeat(2, 1fr) 2fr max-content;
  align-items: center;
  padding: 20px;
  color: #{colors.$blue-gray-900};
  font-size: 14px;
  .booked-for-time-of-day {
    font-weight: 700;
  }
  &:last-child {
    border-bottom: none;
  }
  .customer-info {
    .name {
      font-weight: 500;
    }
    .mobile {
      color: #{colors.$blue-gray-900};
    }
  }

  .status {
    font-size: 14.5px !important;
    &.accepted {
      @include ghost.green;
    }
    &.new {
      @include ghost.yellow;
    }
    &.cancelled {
      @include ghost.red;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    .cancel-table-booking {
      margin-left: 20px;
      button {
        background-color: transparent;
        font-size: 15px;
        font-weight: 700px;
        padding: 5px 20px;
        border-radius: 3px;
      }
      .cancel-table-booking-button {
        border: 1px solid #{colors.$rose-800};
        color: #{colors.$rose-800};
      }

      button[disabled] {
        border: 1px solid #{colors.$blue-gray-100};
        color: #{colors.$blue-gray-300};
      }
    }
  }
}

.swal2-container {
  .cancel-table-booking {
    .swal2-content {
      .swal2-html-container {
        color: colors.$blue-gray-900;
      }
    }

    .swal2-actions {
      display: flex;
      gap: 15px;
      flex-direction: column;
      margin: 0;
      padding: 30px;
      padding-bottom: 0;

      button {
        @include typography.p();
        text-align: center;
        padding: 12px;
        text-transform: uppercase;
        width: 100%;
        margin: 0;
      }

      .confirm-button {
        text-transform: capitalize;
        width: 100%;
        @include typography.h4;
        text-align: center;
        background: #{colors.$rose-800} !important;
        color: #{colors.$white};
        border: 2px solid #{colors.$rose-800};
        padding: 15px;
        border-radius: 10px;
        cursor: pointer;
        transition: ease-in-out all 0.2s;
        margin-bottom: 0px;
        &:focus {
          border: none;
          outline: none;
        }
      }

      .cancel-button {
        text-transform: capitalize;
        width: 100%;
        @include cta.tertiary-cta;
        color: #{colors.$blue-gray-900};
        border: 2px solid colors.$blue-gray-100;
      }
    }
  }
}
