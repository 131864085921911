@use '../widgets/order-view-widget' as view-widget;
@use '../common/colors' as colors;

.cancel-uber-direct-order-prompt {
  > .swal2-title {
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 26px !important;
    margin-top: 0 !important;
  }

  > .swal2-content {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 21px !important;
  }

  > .swal2-actions {
    padding-top: 20px !important;

    > button {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      text-transform: none !important;
    }

    > .confirm-button {
      background-color: colors.$rose-800 !important;
      border-radius: 5px !important;
    }

    > .cancel-button {
      color: colors.$blue-gray-500 !important;
      border: 1px solid colors.$blue-gray-500;
    }
  }
}

.pos-order-view-widget {
  @include view-widget.order-view-widget;

  &.loading {
    display: block;
  }
}
