@use "../common/typography" as typography;
@use "../common/constants" as constants;
@use "../common/cta" as cta;
@use "../common/scrollbar" as scrollbar;
@use '../common/colors' as colors;
@use "../common/default-theme" as default-theme;

.customer-info-and-requested-for-edit-widget {
  height: 90vh;
  width: 75vw;
  display: grid;
  grid-template-rows: repeat(2, min-content) 1fr min-content;
  z-index: 100;

  > .header {
    padding: constants.$modal-horizontal-gap;
    padding-bottom: 30px;

    .collection-type {
      position: absolute;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, min-content);
      gap: 10px;
      align-items: center;

      > .label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        color: #{colors.$blue-gray-500};
      }
    }

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #{colors.$blue-gray-900};
    }
  }

  .requested-for-selector {
    padding: 0 constants.$modal-horizontal-gap;
    padding-bottom: 18px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    .requested-for-asap,
    .request-another-time {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 12px 16px;
      background: colors.$modifier-gradient-bg;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      cursor: pointer;

      &.selected {
        background: colors.$blue-gray-900;

        > .label {
          color: colors.$white;
        }
      }
    }

    /**
     * Checkbox icon
     */
    .requested-for-asap,
    .request-another-time {
      position: relative;

      .selected-icon {
        position: absolute;
        top: -6px;
        right: -8px;
        z-index: 2;
      }
    }

    .request-another-time {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 999;

      > .label {
        text-align: center;
        flex-grow: 1;
      }

      > .request-another-time-options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: colors.$white;
        border: solid 1px colors.$blue-gray-100;
        border-radius: 4px;

        > .option {
          padding: 10px 16px;
          border-bottom: 1px solid colors.$blue-gray-100;

          display: flex;
          align-items: center;

          img {
            margin-right: 14px;
          }

          // Remove bottom margin if last option
          &:last-child {
            border-bottom: 0;
          }

          &.selected {
            background-color: colors.$light-gray-100;
            font-weight: 500;
          }
        }
      }

      .drop-down-icon {
        position: absolute;
        right: 16px;
      }
    }
  }

  .main-panel {
    padding: 0 constants.$modal-horizontal-gap;
    overflow: hidden auto;

    .suburb-list {
      height: inherit;
    }
    .empty-collection-times {
      height: inherit;
      width: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: #{colors.$blue-gray-500};
    }

    > .iso-date-picker-widget,
    .time-of-day-list-widget {
      grid-template-rows: unset;

      > .header {
        display: none;
      }
    }
  }

  .actions {
    display: flex;
    grid-gap: 10px;
    height: fit-content;
    align-self: self-end;
    padding: constants.$modal-horizontal-gap;

    > button {
      flex-basis: 0;
      flex: 1 1 0px;
    }

    button.done-button {
      @include cta.primary-cta;
    }
    button.cancel-button,
    button.back-button {
      @include cta.link-cta;
      border: 2px solid colors.$blue-gray-300;
    }
  }
}
