@use '../common/colors';

.tyro-terminal-info-widget {
  display: flex;
  flex-direction: column;
  width: 60vw;
  padding: 40px 32px;

  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  > *:not(.actions) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 12px;
    margin-top: 12px;
    border-top: solid 1px colors.$blue-gray-100;

    &:first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
    }

    > .label {
      font-weight: 500;
    }
  }

  > .actions {
    padding: 0 24px;

    > .ok-button {
      width: 100%;
      padding: 14px 24px;
      background-color: colors.$teal-600;
      border-radius: 4px;
      margin-top: 40px;

      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: colors.$white;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}
