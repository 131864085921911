@use "../common/typography" as typography;
@use "../common/default-theme" as default-theme;
@use "../common/cta"as cta;
@use "../common/buttons" as buttons;
@use "../common/scrollbar" as scrollbar;
@use "../common/colors" as colors;

.uber-delivery-payment-over-phone-confirmation-widget {
  padding: 0px 15px;
  .header {
    padding: 30px 70px;
    text-align: center;
    .title {
      @include typography.h1;
      margin-bottom: 25px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;

    .cancel-button {
      flex: 1;
      margin-right: 15px;
      text-align: center;
      background: transparent;
      border: 1px solid #{default-theme.$secondary-fg};
      color: #{colors.$blue-gray-700};
      padding: 15px;
      border-radius: 5px;
      cursor: pointer;
      transition: ease-in-out all 0.2s;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      &:focus {
        border: none;
        outline: none;
      }
    }
    .confirm-button {
      flex: 1;
      @include cta.primary-cta;
      border-radius: 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
