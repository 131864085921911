@use "../common/typography";
@use "../common/colors";
@use "../common/elements";

%dine-in-info {
  @include typography.h5;
  font-weight: 400;
  margin-left: 8px;
}

.dine-in-info-widget {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 8px;
  border-bottom: solid 1px colors.$blue-gray-800;

  .icon {
    @include elements.icon;
    padding: 0px 7px 0px 13px;
  }

  .no-dine-in-info {
    @extend %dine-in-info;
    color: #{colors.$white}80;
  }

  .dine-in-info {
    @extend %dine-in-info;
    color: colors.$white;
  }
}
