@use '../common/colors';
@use '../common/typography';

.requested-for-preset-selector-widget {
  .presets {
    display: grid;
    gap: 13px;

    .preset {
      @include typography.h5;
      background-color: colors.$white;
      color: colors.$blue-gray-900;
      cursor: pointer;
      padding: 13px;
      font-size: 16px;
      line-height: 22px;
      border-radius: 8px;
      transition: ease-in-out all 0.2s;
      background-image: url('../images/icon-stopwatch.svg');
      background-repeat: no-repeat;
      background-position: 92% center;
      background-size: 22px;
      justify-content: start;

      &.selected {
        background-color: colors.$blue-gray-900;
        color: colors.$white;
        background-image: url('../images/icon-stopwatch-active.svg');
      }

      &:first-child {
        background-image: url('../images/icon-lightning.svg');
        background-size: 20px;

        &.selected {
          background-image: url('../images/icon-lightning-active.svg');
        }
      }

      &:last-child {
        background-image: url('../images/icon-datetime.svg');
        background-position: 93% center;
        background-size: 25px;

        &.selected {
          background-image: url('../images/icon-datetime-active.svg');
        }
      }
    }
  }
}
