@use "../common/colors";
@use "../common/elements";
@use "../common/payment-summary";

.pos-order-version-payment-summary-widget {
  > .header {
    display: flex;
    align-items: center;
    cursor: pointer;

    > .label {
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      color: colors.$blue-gray-900;
    }

    > .icon {
      margin-left: 5px;
      @include elements.icon;
    }
  }

  > .versions {
    margin-top: 12px;

    > .version-payment-info {
      border-top: 1px solid colors.$blue-gray-100;
      @include payment-summary.payment;
    }
  }
}
