@use '../common/card' as card;
@use '../common/scrollbar' as scrollbar;
@use '../common/colors' as colors;
@use '../common/typography' as typography;

.deal-suggestions-widget {
  height: 90vh;
  width: 75vw;
  max-width: 1500px;
  display: flex;
  padding: 22px 44px 36px;
  flex-direction: column;

  > .header {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    margin-bottom: 12px;
    .title {
      @include typography.h1;
      text-align: center;
      margin-bottom: unset;
    }
    .close-button {
      position: absolute;
      top: 19px;
      right: 24px;
      cursor: pointer;
    }
  }

  .deal-suggestions {
    display: grid;
    gap: 20px;
    overflow: auto;
    @include scrollbar.hide;
    position: relative;
    padding: 20px 0px 30px;

    .header {
      @include typography.h2;
    }
  }
}
