@use "../common/scrollbar" as scrollbar;

.new-table-booking-list-widget {
  .no-table-bookings {
    text-align: center;
    margin-top: 20vh;
  }

  .table-bookings {
    display: grid;
    grid-auto-rows: min-content;
    gap: 15px;
    padding: 24px;

    overflow: hidden scroll;
    max-height: calc(100vh - 126px);

    @include scrollbar.hide();
  }
  
}