@use '../common/colors' as colors;

%notification-widget {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 999;
  bottom: 4vh;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 1.5s;
  animation: slide 0.5s forwards;
  animation-delay: 1.5s;
  cursor: pointer;
}

%lock-screen-notification-widget {
  bottom: 9vh;
  -webkit-animation: lockScreenSlide 0.5s forwards;
  -webkit-animation-delay: 1.5s;
  animation: lockScreenSlide 0.5s forwards;
  animation-delay: 1.5s;
}

@-webkit-keyframes slide {
  100% {
    left: 70px; // sidebar width
  }
}

@keyframes slide {
  100% {
    left: 70px; // sidebar width
  }
}

@-webkit-keyframes lockScreenSlide {
  100% {
    left: 0px; // sidebar width
  }
}

@keyframes lockScreenSlide {
  100% {
    left: 0px; // sidebar width
  }
}
