@use "../common/constants"  as constants;

.uber-proof-of-delivery-widget {
  > img {
    height: 90vh;
    width: 90vw;
    max-width: 1200px;
    border-radius: constants.$border-radius-heavy;
  }
}
