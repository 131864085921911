@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;

.form-single-select-pill {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: #{default-theme.$fg};
  }

  .help-text {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    margin-bottom: 1rem;
    color: #{colors.$rose-500};
  }

  .error {
    margin-right: auto;
    margin-left: 10px;
  }

  .options.small {
    display: flex;
    flex-wrap: wrap;
    justify-self: end;

    .option {
      cursor: pointer;

      background: linear-gradient(180deg, #F7F9FC 0%, #FFFFFF 100%);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
      border-radius: 6px;

      color: #{default-theme.$fg};

      margin: 0 5px;
      padding: 5.5px 8px;
      text-align: center;
      
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      justify-content: center;
      align-items: center;
    }

    .option.selected {
      background: #{default-theme.$fg};
      color: #{default-theme.$bg};
    }

  }

}
