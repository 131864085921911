@use '../common/colors' as colors;
@use '../common/default-theme' as default-theme;
@use '../common/card' as card;

.top-products-bar-charts-view-widget {
  @include card.container;
  background: #{default-theme.$modifier-gradient-bg};
  padding: 36px 24px;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);

  .view-selectors {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 20px;
    border-bottom: 1px #{colors.$blue-gray-100} solid;

    .option {
      cursor: pointer;
      opacity: 0.5;
      padding-bottom: 8px;
      &.selected {
        opacity: 1;
        border-bottom: 2px #{colors.$blue-500} solid;
      }
    }
  }

  .bar-chart-view {
    padding-top: 12px;
  }
}
