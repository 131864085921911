@use '../common/colors';

.third-party-delivery-page {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100vh;

  > .header {
    > .wrapper {
      border-bottom: none;
      grid-template-areas:
        'title actions'
        'subtitle empty';
      justify-content: start;
      align-items: baseline;
      column-gap: 24px;
    }
  }

  > .body {
    overflow: hidden;
  }

  .disabled {
    margin: 30px;
  }

  .action-link {
    color: colors.$blue-gray-500;
    display: grid;
    grid-auto-flow: column;
    gap: 12px;
    align-items: center;

    cursor: pointer;
  }

  &.delivery-complete {
    > .header {
      > .wrapper {
        grid-template-areas:
          'actions'
          'title'
          'subtitle';

        .action-link {
          margin-bottom: 13.5px;
        }
      }
    }
  }
}
