@use "./colors" as colors;
@use "./cta" as cta;
@use "./typography" as typography;

@mixin request-failed-widget {
  width: 750px;
  height: 500px;
  display: grid;
  place-items: center;
  padding: 50px 36px;

  .warning-icon {
    width: 82px;
  }

  .title {
    color: #{colors.$blue-gray-900};
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    margin-top: 40px;
  }

  .message {
    color: #{colors.$blue-gray-500};
    font-weight: 400;
    font-size: 17px;
    text-align: center;
    display: grid;
    grid-auto-flow: column;
    gap: 6px;
    margin-top: 16px;
  }

  .actions {
    margin-top: 18px;
    display: grid;
    gap: 15px;
    width: 100%;

    > button {
      padding: 14px !important;
      border-radius: 5px !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
    }

    .retry-button {
      @include cta.primary-cta;
    }

    .use-in-house-button {
      @include cta.secondary-cta;
      border: 1px solid #{colors.$teal-600};

      > .label {
        color: #{colors.$teal-600};
      }
    }

    .reject-order-button {
      @include cta.secondary-cta;
      border: none;
      color: #{colors.$rose-500} !important;
    }
  }
}

@mixin uber-delivery-info-list-item-widget {
  display: grid;
  grid-template-columns: 3fr repeat(5, 1fr) 1.5fr;
  gap: 12px;
  align-items: center;

  border-bottom: 1px solid colors.$blue-gray-100;

  padding: 16px 24px;

  @include typography.h5;
  font-weight: 400;
  color: colors.$blue-gray-900;

  cursor: pointer;

  &.delivery-complete {
    grid-template-columns: 3fr repeat(4, 1fr) 1.5fr;
  }

  > .customer-info {
    .name {
      font-weight: 500;
      line-height: 24px;
    }

    .address,
    .mobile {
      color: colors.$blue-gray-500;
    }
  }

  > .driver-status {
    width: max-content;
    padding: 4px 12px;
    border-radius: 100px;

    &.confirmed {
      background: colors.$yellow-100;
      color: colors.$yellow-600;
    }

    &.assigned {
      background: colors.$green-100;
      color: colors.$green-900;
    }

    &.on-its-way {
      background: colors.$yellow-100;
      color: colors.$yellow-600;
    }

    &.returned {
      background: colors.$rose-100;
      color: colors.$rose-800;
    }

    &.cancelled {
      background: colors.$rose-100;
      color: colors.$rose-800;
    }
  }

  > .driver-info {
    display: grid;
    align-items: center;
    column-gap: 10px;
    grid-template-areas:
      'image    name'
      'image    vehicle'
      'image    mobile';

    img {
      grid-area: image;
      border-radius: 50%;
      width: 52px;
    }

    .name {
      grid-area: name;
      font-weight: 500;
      line-height: 24px;
    }

    .vehicle-type {
      grid-area: vehicle;
    }

    .mobile {
      grid-area: mobile;
    }

    .vehicle-type,
    .mobile {
      color: colors.$blue-gray-500;
    }
  }

  > .delivery-status {
    width: max-content;
    padding: 4px 12px;
    border-radius: 100px;

    &.delivered, &.completed {
      background: colors.$green-100;
      color: colors.$green-900;
    }

    &.cancelled, &.rejected {
      background: colors.$rose-100;
      color: colors.$rose-800;
    }

    &.returned {
      background: colors.$rose-100;
      color: colors.$rose-800;
    }
  }
}
