@use '../common/colors' as colors;
@use "../common/cta" as cta;
@use "../common/typography" as typography;

.table-booking-edit-widget {
  padding: 20px;

  .header {
    @include typography.h1;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
  }
  .table-booking-booking-details-edit-widget,
  .table-booking-contact-details-edit-widget {
    .sub-title {
      margin-bottom: 20px;
      @include typography.h2;
    }

    form {
      .form-items {
        width: 60vw;
      }
      .actions {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
        grid-area: actions;
        margin-top: 20px;
        button {
          width: 100%;
        }
        .cancel-button,
        .go-back-button {
          @include cta.link-cta;
          color: colors.$blue-gray-900;
          border: 2px solid colors.$blue-gray-300;
          background-color: #fff;
          font-size: 15px;
        }

        .continue-button,
        .submit-button {
          @include cta.primary-cta;
        }

        .disabled-continue-button,
        .disabled-submit-button {
          opacity: 0.6;
          @include cta.primary-cta;
        }
      }
    }
    .booking-details {
      form {
        .form-items {
          > div {
            width: inherit;
            display: grid;

            &.disabled-seating-area-and-notes {
              grid-template-columns: 1fr 20%;
              grid-template-areas:
                'iso-date-date-picker          iso-date-date-picker'
                'time-of-day                   pax-and-seating-area'
                'actions                       actions';

              .pax-and-seating-areas {
                grid-template-columns: 1fr;
                grid-area: pax-and-seating-area;
                margin-top: 12px;
              }
              .iso-date-date-picker {
                grid-area: iso-date-date-picker;
                margin-bottom: 15px;
              }

              .time-of-day {
                grid-area: time-of-day;
                margin: 6px 10px 0px 0px;
              }

              .actions {
                margin-top: 30px;
              }
            }

            &.disabled-seating-area {
              gap: 10px;
              grid-template-columns: repeat(2, 1fr) 20%;
              grid-template-areas:
                'iso-date          time-of-day       pax-and-seating-area '
                'special-request   special-request   special-request'
                'actions           actions           actions';

              .pax-and-seating-areas {
                display: grid;
                grid-template-columns: 1fr;
                grid-area: pax-and-seating-area;
                gap: 15px;
                margin-top: 0px;
                .pax {
                  padding-bottom: 0px;
                  max-height: 82px;
                }
              }
              .special-request {
                margin-top: 16px;
              }

              .time-of-day {
                grid-area: time-of-day;
                margin-left: 0px;
              }
              .actions {
                margin-top: 10px;
              }
            }

            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
              'iso-date                          time-of-day'
              'pax-and-seating-area              pax-and-seating-area'
              'special-request                   special-request'
              'actions                           actions';
            .iso-date {
              grid-area: iso-date;
              margin-bottom: 8px;
            }
            .time-of-day {
              grid-area: time-of-day;
              margin-left: 10px;
            }

            .form-select-icon input {
              background-image: url('../images/icon-chevron-down-grey.svg');
              background-repeat: no-repeat;
              background-position: right 20px top 10px;
              background-position-y: 60%;
              background-size: 20px !important;
            }
            .pax-and-seating-areas {
              display: grid;
              grid-template-columns: 18% 80%;
              grid-area: pax-and-seating-area;
              gap: 15px;
              margin-top: 25px;
              .seating-areas {
                position: relative;
                .seating-area-options {
                  overflow: auto;
                  max-height: 230%;
                  border-radius: 8px;
                  position: absolute;
                  top: 105%;
                  left: 0;
                  width: 100%;
                  float: right;
                  background-color: colors.$white;
                  border: solid 1px colors.$blue-gray-100;
                  border-radius: 4px;
                  z-index: 1;
                  > .option {
                    cursor: pointer;
                    padding: 16px;
                    border-bottom: 1px solid colors.$blue-gray-100;
                    display: flex;
                    align-items: center;

                    &:last-child {
                      border-bottom: 0;
                    }

                    &.selected {
                      background-color: colors.$light-gray-100;
                      font-weight: 500;
                    }
                  }
                }
              }
            }

            .special-request {
              grid-area: special-request;
              margin-top: 35px;
              .notes-character-limit {
                float: right;
                color: #{colors.$blue-gray-400};
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

    .contact-details {
      form {
        .form-items {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            'contact-number        customer-name'
            'customer-email        customer-email'
            'actions               actions';
          gap: 17px;
          .contact-number {
            grid-area: contact-number;
          }
          .customer-name {
            grid-area: customer-name;
          }
          .customer-email {
            margin: 20px 0px 10px 0px;
            grid-area: customer-email;
          }
        }
      }
    }

    .form-items {
      .form-item {
        position: relative;

        label {
          padding: 5px 0px 10px 0px;
          position: absolute;
          top: 5px;
          left: 6px;
          font-size: 15px;
          line-height: 30px;
          color: #{colors.$blue-gray-500};
        }

        .meta {
          position: absolute;
          top: 78px;
          .error {
            font-size: 14px;
            color: #{colors.$rose-500};
          }
        }

        > .form-input > div > input,
        textarea {
          border-radius: 8px;
          padding: 40px 10px 15px 10px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5em;
          letter-spacing: 0.005em;
          color: colors.$blue-gray-900;
          &.invalid {
            border: 1px solid colors.$rose-500 !important;
          }
        }

        ::placeholder {
          color: colors.$blue-gray-600;
        }
      }
    }
  }
}
