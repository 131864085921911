@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;

.pos-customer-list-item-widget {
  color: #{default-theme.$fg};
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr 3fr min-content;
  gap: 15px;
  padding: 13px 20px;
  cursor: pointer;

  .name {
    font-weight: 600;
  }
}
