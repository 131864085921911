@use "../common/colors";
@use '../common/elements';

.cart-actions-widget {
  .customizations {
    display: flex;
    padding: 12px;
    border-top: 1px solid #{colors.$blue-gray-800};

    > div {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }

    %customization {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #{colors.$white}1a;
      width: 100%;
      padding: 12px 10px;
      border-radius: 8px;
      cursor: pointer;
    
      .icon {
        @include elements.icon($size: 28px);
      }
    }

    .add-surcharge-button {
      @extend %customization;

      &.has-value {
        background-color: #{colors.$rose-500}40;
      }
    }

    .add-discount-button {
      @extend %customization;

      &.has-value {
        background-color: #{colors.$yellow-600}40;
      }

      &.is-loyalty {
        background-color: #{colors.$blue-600}40;
      }
    }

    .add-order-comments-button {
      @extend %customization;

      &.has-value {
        background-color: #{colors.$teal-500}40;
      }
    }
  }

  .checkout-actions {
    display: flex;
    padding: 12px;
    padding-top: 0;

    .send-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: colors.$yellow-600;
      border-radius: 8px;
      padding: 0px 25px;
      margin-right: 8px;
      cursor: pointer;

      .icon {
        @include elements.icon($size: 28px);
      }
    }

    .checkout-button {
      flex-grow: 1;
      font-size: 17px;
      letter-spacing: 0;
    }
  }
}

.confirm-surcharge {
  .swal2-modal {
    width: 530px !important;
    padding: 32px 24px !important;

    .swal2-header {
      padding-top: 12px;
      padding-bottom: 16px;

      .swal2-title {
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0.36px;
      }
    }

    .swal2-html-container {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.08px;
      color: colors.$blue-gray-700;
    }

    .swal2-actions {
      flex-direction: row-reverse;
      flex-wrap: unset;

      .cancel-button {
        border: 1px solid colors.$blue-gray-500;
        color: colors.$blue-gray-500;
        text-transform: none;
      }
    }
  }
}