@use '../common/default-theme' as default-theme;
@use "../common/cta" as cta;
@use '../common/buttons' as buttons;
@import '../common/typography';

.add-item-custom-discount-widget {
  width: 430px;
  margin: 60px 160px;

  .title {
    @include h1;
    margin-bottom: 30px;
  }

  .unit-toggle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #{default-theme.$secondary-bg};
    border: 1px solid #{default-theme.$bg};
    margin-bottom: 25px;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #{default-theme.$fg};
    border-radius: 8px;

    div {
      cursor: pointer;
      display: grid;
      align-items: center;
      text-align: center;
      &.selected {
        padding: 10px 12px;
        border-radius: 8px;
        background: #{default-theme.$fg};
        color: #{default-theme.$bg};
      }
    }
  }
  // --- DISCOUNT INPUT BOX --- //
  .discount-input-box {
    border-radius: 8px;
    display: grid;
    padding: 12.75px 20px;
    grid-template-columns: min-content 1fr;
    .discount-icon {
      background: url('../images/custom-discount-icon.svg');
      height: 40px;
      width: 40px;
    }
    .discount-value {
      display: grid;
      align-items: center;
      input {
        outline: none;
        border: none;
        background: none;
        text-align: right;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        line-height: 35px;
        overflow: hidden;
        width: 100%;
      }
    }
  }
  .valid.discount-input-box {
    border: 1px solid #{default-theme.$primary};
  }
  .invalid.discount-input-box {
    border: 1px solid #{default-theme.$secondary};
  }

  .item-info {
    margin: 24px 0;
    background: #{default-theme.$secondary-bg};
    border-radius: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #{default-theme.$secondary-fg};

    .discount-info {
      display: grid;
      padding: 15px 15px;
      grid-template-areas:
        'name       .    original-price'
        'discount   .    discount-charge';

      .name {
        grid-area: name;
        margin-bottom: 5px;
      }

      .original-price {
        grid-area: original-price;
        text-align: right;
      }

      .discount {
        grid-area: discount;
      }

      .discount-charge {
        grid-area: discount-charge;
        color: #{default-theme.$secondary};
        text-align: right;
      }
    }

    .new-price-info {
      grid-area: new-price-info;
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      padding: 15px 15px;
      color: #{default-theme.$fg};
      border-top: 1px solid #{default-theme.$border-light};
    }
  }

  /**** CUSTOM DISCOUNT ACTIONS ****/
  .add-discount.actions {
    display: grid;
    grid-template-areas:
      'confirm'
      'cancel';
    grid-gap: 20px;
  }

  .edit-discount.actions {
    display: grid;
    grid-template-areas:
      'clear confirm'
      'cancel cancel';
    grid-gap: 20px 10px;
  }

  button.confirm {
    grid-area: confirm;
    @include cta.primary-cta;
    font-size: 17px;
    line-height: 23px;
    height: 50px;
  }

  button.clear {
    grid-area: clear;
    @include buttons.primary-transparent;
    font-size: 17px;
    line-height: 23px;
    height: 50px;
  }

  button.cancel {
    grid-area: cancel;
    @include cta.link-cta;
    line-height: 22px;
    height: 40px;
  }

  /*--- Hide number-type input spin box ---*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  /*--------------------------------------*/
}
