@use '../common/colors' as colors;

.loyalty-card-widget {
  position: relative;
  width: 312px;
  height: 196px;
  border-radius: 10px;
  box-shadow: 1px 3px 4px 1px rgb(0 0 0 / 25%);
  background-color: colors.$light-gray-900;

  
  .background {
    img,
    .imgix img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      position: absolute;
      top: 0;
    }
  }

  .details {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 27px;

    display: grid;
    grid-template-areas:
      'balance          emv-chip'
      'count-progress   count-progress'
      'customer-info    customer-info';
      

    .balance {
      grid-area: balance;
      display: flex;
      align-items: end;
      height: inherit;
      font-family: 'larabie';
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      color: colors.$white;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8);
      letter-spacing: 1.3px;
    }

    .mock-emv-chip {
      grid-area: emv-chip;
      display: flex;
      justify-content: end;
      align-items: end;

      img {
        width: 32px;
      }
    }

    .count-progress {
      grid-area: count-progress;
      display: grid;
      grid-auto-flow: column;
      column-gap: 5px;
      align-items: center;
      margin-top: 10px;

      .count {
        background-color: colors.$light-gray-200;
        border-radius: 10px;
        height: 10px;
        box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.29);

        &.active {
          background-color: colors.$yellow-green-300;
          border: 1px solid rgba(0, 0, 0, 0.21);
          box-shadow: inset 0px -2px 4px rgba(0, 0, 0, 0.2), inset 0px -3px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }

    .customer-info {
      grid-area: customer-info;
      font-family: 'kredit';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: colors.$white;
      text-shadow: 1px 1px 1px colors.$black;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: 2px;

      .name {
        -webkit-line-clamp: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}