@use "../common/colors" as colors;
@use "../common/typography" as typography;
@use "../common/cta" as cta;

.service-worker-update-prompt-widget {
  position: fixed;
  top: 0;
  z-index: 2001;

  .update-prompt {
    display: flex;
    height: 69px;
    width: 100vw;
    background: colors.$yellow-100;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px 0 102px; // (sidebar width) 70px + 32px

    .text {
      @include typography.h3;
      display: flex;
      align-items: center;
      font-weight: 400;
      line-height: 24px;

      span {
        color: colors.$rose-800;
        font-weight: 500;
        margin: 0 10px;
      }
    }

    .actions {
      display: grid;
      gap: 12px;
      align-items: center;
      grid-auto-flow: column;
      cursor: pointer;

      .refresh-button {
        @include cta.primary-cta;
        @include typography.h5;
        border-radius: 4px;
        color: colors.$white;
        padding: 9px 24px;
        line-height: 21px;
      }

      .later-button {
        @include typography.h5;
        border-radius: 4px;
        border: 1px solid colors.$yellow-600;
        padding: 8px 24px;
        line-height: 21px;
        color: colors.$rose-800;
        background-color: transparent;
      }
    }
  }
}
