@use "../common/typography"as typography;
@use "../common/cta" as cta;
@use "../common/default-theme"as default-theme;

.table-custom-widget {
  padding: 60px 160px;
  width: 750px;

  .title {
    @include typography.h1;
    text-align: center;
    margin-bottom: 38px;
  }

  form {
    margin-bottom: 24px;
  }

  .actions {
    display: grid;
    gap: 10px;

    .confirm-btn {
      @include cta.primary-cta;
    }
    .back-btn {
      @include cta.link-cta;
      border: 1px solid #{default-theme.$secondary-fg};
      border-radius: 10px;
      font-size: 15px;
    }
    .cancel-btn {
      @include cta.link-cta;
    }
  }
}
