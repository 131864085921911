@use "../common/typography" as typography;
@use "../common/cta"as cta;
@use "../common/buttons" as buttons;
@use "../common/scrollbar" as scrollbar;
@use "../common/colors" as colors;
@use "../common/constants" as constants;

.uber-delivery-collect-payment-for-pos-order-widget {
  padding: 0px 20px;
  width: 40vw;
  .header {
    padding: 30px 10px;
    text-align: center;

    .title {
      @include typography.h1;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 25px;
    }

    .label {
      color: #{colors.$blue-gray-900};
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;

      .bold {
        @include typography.bold;
      }
    }
  }

  .payment-amount {
    padding: 30px;
    border-radius: constants.$border-radius;
    background: linear-gradient(90deg, rgba(246, 248, 251, 0.7) 0%, rgba(233, 233, 233, 0.7) 100%);
    display: grid;
    grid-auto-flow: column;
    margin-bottom: 15px;

    .amount {
      color: #000;
      text-align: right;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;

      &::first-letter {
        margin-right: 10px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    > button {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      border-radius: 5px !important;
    }

    .cancel-button {
      flex: 1;
      margin-right: 15px;
      text-align: center;
      background: transparent;
      border: 1px solid #{colors.$blue-gray-500};
      color: #{colors.$blue-gray-500};
      padding: 15px;
      cursor: pointer;
      transition: ease-in-out all 0.2s;

      &:focus {
        border: none;
        outline: none;
      }
    }
    .payment-success-button {
      flex: 1;
      @include cta.primary-cta;
    }
  }
}
