@use '../common/colors';
@use '../common/typography';
@use '../common/elements';
@use '../common/ghost';
@use '../common/pill';

$grid-template-columns: 1fr 1fr 0.5fr 1.25fr 1.5fr 0.5fr 1fr 0.35fr;

@mixin container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: $grid-template-columns;
  align-items: center;
  gap: 12px;

  padding: 26px 24px;

  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  white-space: nowrap;
  color: colors.$blue-gray-900;

  cursor: pointer;
}

@mixin content {
  .orders-list-item-customer-info-widget {
    min-width: 0;
    .customer-name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
    }
    .customer-phone-number {
      color: colors.$blue-gray-500;
    }
  }

  .orders-list-item-collection-info-widget {
    .collection-type {
      display: flex;
      font-weight: 500;
    }
    .collection-type > .uber-icon {
      margin-left: 8px;
      @include elements.icon(24px);
    }
    .requested-for {
      color: colors.$blue-gray-500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .orders-list-item-payment-status-widget {
    display: flex;
    align-items: center;

    > .payment-status {
      &.paid {
        @include pill.primary-green;
      }
      &.not-paid {
        @include pill.primary-red;
      }
      &.refunded {
        @include pill.primary-yellow;
      }
    }

    > .bullet {
      margin: 0 4px;
    }
  }

  .orders-list-item-print-order-button {
    @include elements.icon(22px);
  }
}
