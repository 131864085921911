@use "../common/item-option-styles" as item-option-styles;
@use "../common/constants"  as constants;

.suburb-list-widget {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: min-content minmax(100px, max-content);

  .header {
    padding: constants.$modal-horizontal-gap;
    display: grid;
    grid-template-columns: 1fr max-content min-content;
    gap: 20px;
    align-items: center;

    .search-suburb {
      box-shadow: 0px 0px 5px rgba(49, 48, 62, 0.15), 0px 4px 6px rgba(49, 48, 62, 0.15);
      border-radius: 10px;
    }

    > button {
      min-width: 20%;
      padding: 12px 40px;
    }
  }

  .no-suburb {
    margin-top: 100px;
    text-align: center;
  }

  .suburbs {
    @include item-option-styles.options;
    padding: constants.$modal-horizontal-gap;
    padding-top: 10px;
  }
}
