@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;
@use "../common/scrollbar" as scrollbar;
@use "../common/typography" as typography;

.print-station-print-options-widget {
  display: grid;
  grid-auto-rows: min-content;
  padding: 16px;
  background: #{default-theme.$secondary-bg};
  border: 1px solid #{colors.$blue-gray-100};
  border-radius: 4px;
  min-height: 150px;

  .label {
    font-weight: 900;
    font-size: 16px;
    padding-bottom: 10px;
    color: #{default-theme.$fg};
  }

  .number-of-copies {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .name {
      @include typography.h3();
      font-weight: 500;
      color: #{default-theme.$tertiary};
    }

    .quantity {
      display: grid;
      grid-auto-flow: column;
      width: fit-content;

      .btn {
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        display: grid;
        justify-content: center;
        align-items: center;
        color: #{colors.$white};
      }

      .icon {
        box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
      }

      .dec {
        @extend .btn;
        background: #{default-theme.$tertiary};
        padding: 5px 8px;
        height: 28px;
        width: 28px;
      }

      .number {
        margin: auto 0px;
        min-width: 50px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #{colors.$blue-gray-800};
      }

      .inc {
        @extend .btn;
        background: #{default-theme.$primary};
        padding: 5px 8px;
        height: 28px;
        width: 28px;
      }
    }
  }
}
