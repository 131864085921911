@use '../common/colors' as colors;
@use '../common/typography' as typography;

.print-station-list-widget {
  .print-stations {
    columns: 300px;
    column-gap: 24px;
  }

  .empty-list {
    @include typography.h2;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;

    .message {
      display: flex;
      flex-flow: column;
      align-items: center;

      .icon {
        width: 40px;
        margin-bottom: 10px;
      }
    }
  }
}
