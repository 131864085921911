@use "../common/item-option-styles" as item-option-styles;
@use "../common/constants" as constants;
@use "../forms/form" as form;
@use '../common/colors' as colors;
@use "../common/cta" as cta;

.iso-date-picker-widget {
  height: auto;
  position: relative;
  display: grid;
  grid-template-rows: min-content minmax(100px, max-content) min-content;

  button {
    @include cta.link-cta;
    border: 2px solid colors.$blue-gray-300;
    margin-top: 18px;
    color: colors.$blue-gray-900;
  }

  .header {
    padding: 0 constants.$modal-horizontal-gap;
    display: grid;
    grid-template-columns: 1fr max-content min-content;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: #{colors.$blue-gray-900};
      margin-bottom: 30px;
    }
  }

  .DayPicker-Caption {
    margin-top: 24px;
    margin-bottom: 24px;

    > div {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
  }

  .DayPicker-wrapper {
    background: colors.$white;
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;
  }

  .DayPicker-Month {
    margin: 10px 26px;
    border-spacing: 46px 6px;
    border-collapse: separate;
  }

  .DayPicker-Weekday {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .DayPicker-Day {
    padding: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }

  .DayPicker-Day--outside {
    outline: unset !important;
  }

  .DayPicker-Day--today {
    color: #000;
  }

  .DayPicker-Day--highlighted {
    color: #000;
    outline-offset: -10px;
    border-radius: 25%;
    outline: 2px solid colors.$teal-500;
  }

  .DayPicker-NavButton--prev {
    background-image: url('../images/icon-chevron-left.svg');
    left: 80px;
    top: 29px;
  }

  .DayPicker-NavButton--next {
    background-image: url('../images/icon-chevron-right.svg');
    right: 80px;
    top: 29px;
  }
}

// add padding when used as a modal
.modal {
  .iso-date-picker-widget {
    padding: 25px 24px;

    .header {
      .title {
        margin-bottom: 24px;
      }
    }
  }
}
