@use '../common/typography' as typography;
@use '../common/colors' as colors;
@use '../common/constants' as constants;
@use '../common/buttons' as buttons;

.print-station-list-item-widget {
  break-inside: avoid;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 24px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
  background: colors.$modifier-gradient-bg;
  border: 1px solid colors.$blue-gray-100;
  border-radius: 6px;
  cursor: pointer;

  .print-station-name {
    @include typography.h2-headline;
    margin-bottom: 10px;
  }

  .print-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 10px;
    border: 1px solid colors.$blue-gray-100;
    border-radius: 12px;

    .title {
      @include typography.body-2-500;
      margin-bottom: 12px;
    }

    .info {
      @include typography.caption-2-400;
      color: colors.$blue-gray-700;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    button {
      @include buttons.secondary-outlined-medium;
      margin: 12px 16px 0px 16px;
    }
  }

  .actions {
    display: flex;

    button {
      flex-grow: 1;
    }

    .delete-button {
      @include buttons.secondary-text-medium;
    }
  }
}
