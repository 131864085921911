@use '../common/colors' as colors;
@use '../common/typography' as typography;

.manage-sold-out-items-widget {
  .no-items {
    text-align: center;
    margin-top: 60px;
  }
  .category-name {
    padding: 24px;
    background: #{colors.$blue-gray-50};
    @include typography.h1;
  }
  .category-items {
    .item {
      display: grid;
      grid-template-areas: 'item-name        availability-label    availability-toggle';
      grid-template-columns: 1fr max-content min-content;
      border-bottom: 1px solid #{colors.$blue-gray-100};

      .item-name {
        grid-area: item-name;
        align-items: center;
        padding: 24px;
        @include typography.h3;
      }
      .item-availability-label {
        grid-area: availability-label;
        align-items: center;
        padding: 24px;
        @include typography.h3;

        &.sold-out {
          color: #{colors.$blue-gray-500};
        }
        &.available {
          color: #{colors.$green-500};
        }
      }
      .switch {
        grid-area: availability-toggle;
        align-items: center;
        padding: 5px 20px;
      }
    }
  }
}
