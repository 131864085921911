@use "../common/colors";
@use "../common/employee-time-clock" as employeeTimeClock;

@mixin button {
  font-size: 12px;
  height: 30px;
  padding: 5px 15px !important;
}

.employee-time-clock-shift-list-item-edit-widget {
  @include employeeTimeClock.list-item;
  grid-template-columns: 0.9fr .7fr 0.2fr .7fr 1.2fr repeat(4, 1fr);
  align-items: center;

  &.invalid {
    border-color: colors.$rose-500;
    border-bottom: 1px solid colors.$rose-500;

    &:last-child:not(:only-child) {
      border-bottom: 1px solid colors.$rose-500;
      border-radius: 0 0 8px 8px;
    }
  }

  &.invalid + div:has(.edit) {
    border-top: none;
  }

  &.edit:not(.invalid) {
    border-color: colors.$blue-500;
    border-bottom: 1px solid colors.$blue-500;

    &:last-child:not(:only-child) {
      border-bottom: 1px solid colors.$blue-500;
      border-radius: 0 0 8px 8px;
    }
  }

  &.edit + div:has(.edit):not(.invalid) {
    border-top: none;
  }

  &.edit + div:has(.invalid) {
    border-top: none;
  }

  > .date-end {
    display: flex;
    justify-content: center;
  }

  > .date-end,
  > .date-start {
    min-width: 112px;
    pointer-events: none;

    &.edit {
      display: flex;
      border: 1px solid colors.$blue-gray-200;
      pointer-events: all;
      justify-content: center;
    }

    &.invalid {
      border-color: colors.$rose-500;
    }
  }

  &:only-child {
    border-bottom: 1px solid colors.$blue-gray-100;
    border-radius: 8px;

    &.invalid {
      border-bottom: 1px solid colors.$rose-500;
    }
  }

  &:last-child:not(:only-child) {
    border-bottom: 1px solid colors.$blue-gray-100;
    border-radius: 0 0 8px 8px;
  }

  > .actions {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    padding-right: 0;
    justify-self: center;

    .edit-button {
      @include employeeTimeClock.timesheet-button(colors.$blue-500, transparent);
      border: 1px solid colors.$blue-500;
      @include button();
    }

    .save-button {
      @include employeeTimeClock.timesheet-button(colors.$white, colors.$teal-600);
      border: 1px solid colors.$teal-600;
      @include button();
    }

    .cancel-button {
      @include employeeTimeClock.timesheet-button(colors.$blue-gray-900, transparent);
      border: 1px solid colors.$blue-gray-500;
      @include button();
      .label {
        color: colors.$blue-gray-500;
      }
    }
  }
}
