@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;

.menu-item {
  display: grid;
  grid-template-areas:
    'name         name'
    'description  description'
    'price        soldout';
  grid-template-rows: 1fr min-content;
  grid-template-columns: min-content 1fr;
  grid-gap: 8px;
  background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
  border-radius: 6px;
  padding: 12px;
  height: 100%;
  cursor: pointer;

  .name {
    grid-area: name;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: #{default-theme.$restoplus-gray-dark};
  }

  .price {
    grid-area: price;
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    color: #{default-theme.$primary};
    align-self: center;

    &::first-letter {
      opacity: 0.6;
    }
  }

  .description {
    grid-area: description;
    color: #{default-theme.$secondary-fg};
  }

  .soldout {
    grid-area: soldout;
    background-color: #{default-theme.$fg};
    color: #{default-theme.$bg};
    text-align: center;
    font-size: 11px;
    align-self: center;
    border-radius: 3px;
  }

  &.soldout {
    cursor: not-allowed;
    .price {
      color: #{default-theme.$fg};
    }
  }

  &.custom-background-color {
    .name {
      color: #333348;
    }

    .price {
      color: #333348;

      &::first-letter {
        opacity: 1;
      }
    }
  }
}
