@use "../common/typography.scss";
@use "../common/colors";
@use "../common/default-theme";
@use "../common/elements";

%button {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
}

.popout-collection-type-selector-widget {
  display: flex;
  flex-direction: column;
  padding: 0;

  > .button {
    @extend %button;
    position: relative;
    width: 200px;

    &:not(:last-child) {
      border-bottom: 1px solid colors.$blue-gray-100;
    }

    &:only-child {
      border-bottom: 0;
    }

    > .check {
      @include elements.icon($size: 20px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
    }

    > .icon {
      @include elements.icon($size: 20px);
    }

    > .title {
      @include typography.h3;
      font-weight: 400;
      line-height: 24px;
      margin-left: 12px;
    }
  }
}
