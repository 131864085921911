@use '../common/colors' as colors;
@use "../common/typography";

.loyalty-credits-widget {
  user-select: none;
  position: relative;
  margin: 12px 12px 36px;
  border-radius: 10px;
  border: 1px solid colors.$blue-gray-100;
  padding: 12px 22px 12px 12px;
  display: grid;
  grid-template-areas:
    'header header'
    'amount button'
    'info info';
  align-items: center;

  &.redeemable {
    border: 1px solid colors.$blue-400;
    background-color: colors.$blue-50;
  }

  > .header {
    grid-area: header;
    @include typography.h1;
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.005em;
  }

  > .amount {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.015em;
    grid-area: amount;
    margin-bottom: 12px;
  }

  > .action {
    grid-area: button;
    margin-left: auto;
    padding: 9.5px 24px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 12px;

    &.revert-button {
      color: colors.$blue-800;
      background-color: colors.$white;
      border: 1px solid colors.$blue-500;
    }

    &.redeem-button {
      color: colors.$blue-gray-500;
      background-color: colors.$blue-gray-100;

      &.redeemable {
        color: colors.$white;
        background-color: colors.$blue-500;
      }
    }
  }

  > .progress-info {
    display: flex;
    grid-area: info;
    align-items: center;
    padding: 2px 0px;

    .label {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      margin-right: 6px;
      color: colors.$blue-gray-900;
    }

    .bar {
      position: relative;
      height: 6px;
      width: 100%;
      background: colors.$blue-200;
      border-radius: 25px;

      .fill {
        position: absolute;
        height: 100%;
        background: colors.$blue-500;
        border-radius: 15px;
        width: 0;
        transition: width 750ms ease-out;
      }
    }
  }

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:after {
    position: absolute;
    bottom: -26px;
    content: '';
    border: none;
    border-top: 1px solid colors.$blue-gray-400;
    width: 100%;
  }
}
