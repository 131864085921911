$bg: #ffffff;
$fg: #1c1d3e;

$transparent-bg: #{$bg}20;

$secondary-bg: #f6f8fb;
$secondary-fg: #7d7e92;

$login-bg: #2d2e4c;

$primary: #4cbba1;
$primary-light: #ecf9f6;
$secondary: #da6565;
$tertiary: #595976;

$highlight: #34c759;
$disabled: #b0b0b8;

$card-bg: white;
$card-bg-alt: #fafafb;

$link-fg: #1e75ff;

$border: #4f506a;
$border-light: #d9d9e6;

$form-element-bg: white;
$form-element-fg: #44444f;
$form-element-border: #ddd;
$form-element-error: #fe5b5b;

$button-primary-bg: $primary;
$button-primary-fg: white;

$button-secondary-bg: #eee;
$button-secondary-fg: #454545;

$button-success-bg: #454545;
$button-success-fg: white;

$button-danger-bg: red;
$button-danger-fg: white;

$message-success-text: #006644;
$message-success-bg: #e3fcef;
$message-success-fg: #36b37e;

$message-error-text: #bf2600;
$message-error-bg: #ffebe6;
$message-error-fg: #da6565;

$message-info-text: #0071b3;
$message-info-bg: #e6edfa;
$message-info-fg: #00a3ff;

$message-warning-text: #ff8b00;
$message-warning-bg: #fffae6;
$message-warning-fg: #ffab00;

$message-loading-text: #505f79;
$message-loading-bg: white;
$message-loading-fg: #ffab00;

$restoplus-gray-dark: #343553;
$restoplus-white-transluscent: rgb(255, 255, 255, 0.6);

$gray: #535a64;
$light-gray: #9999a6;

$modifier-gradient-bg: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
$modifier-gradient-active-bg: linear-gradient(180deg, rgba(44, 45, 86, 1) 0%, rgba(12, 13, 52, 1) 100%);
