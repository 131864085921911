@use "../common/card" as card;
@use "../common/cta" as cta;
@use "../common/colors" as colors;
@use "../common/ghost" as ghost;

.table-booking-history-list-item-widget {
  border-bottom: 1px solid colors.$blue-gray-100;
  display: grid;
  grid-template-columns: 1fr 1.5fr repeat(4, 1fr) 1.5fr 50px;
  align-items: center;
  padding: 20px;
  color: #{colors.$blue-gray-900};
  font-size: 14px;
  .booked-for-date {
    .time {
      font-weight: 700;
    }
  }
  &:last-child {
    border-bottom: none;
  }

  .customer-info {
    .name {
      font-weight: 500;
    }
    .mobile {
      color: #{colors.$blue-gray-900};
    }
  }

  .status {
    font-size: 14.5px !important;
    &.accepted {
      @include ghost.green;
    }
    &.new {
      @include ghost.yellow;
    }
    &.cancelled {
      @include ghost.red;
    }
  }

  .print-table-booking {
    justify-self: center;
  }
}
