@use "../common/typography";
@use "../common/colors";

.current-date-and-time-info-widget {
  text-align: right;

  .time {
    font-size: 25px;
    line-height: 35px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .date {
    @include typography.h5;
    font-weight: 400;
    color: colors.$blue-gray-700;
  }
}
