@use '../common/colors';
@use '../common/elements';
@use '../common/text-input';
@use '../common/switch';
@use '../forms/form';

@mixin _permission {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  gap: 4px 0;
  padding: 20px 24px;
  border: 1px solid colors.$blue-gray-100;
  border-radius: 12px;

  align-items: center;

  > .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  > .description {
    color: colors.$blue-gray-700;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  > .switch {
    grid-row: 1/-1;
    @include switch.form-switch;
    justify-self: end;
  }
}

@mixin _permission-group {
  padding: 20px 24px;
  border: 1px solid colors.$blue-gray-100;
  border-radius: 12px;

  > * {
    @include _permission;
    padding: 0;
    border: 0;
    border-radius: 0;

    border-top: solid 1px colors.$blue-gray-100;
    margin-top: 16px;
    padding-top: 16px;

    &:first-child {
      border-top: 0;
      padding-top: 0;
      margin-top: 0;
    }
  }
}

.tyro-settings-widget {
  form {
    .form-item-container {
      @include form.form-item-container;
    }

    .text-input {
      @include text-input.form-text-input;
    }
  }

  > .terminal-pairing {
    > .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }

    > .description {
      margin-top: 8px;

      color: colors.$blue-gray-700;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    > .form {
      display: grid;
      margin-top: 40px;
      gap: 16px 64px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      grid-auto-flow: column;

      > .actions {
        display: flex;

        > * {
          height: fit-content;
          margin-left: 16px;

          &:first-child {
            margin-left: 0;
          }
        }

        > .test-button {
          width: fit-content;
          padding: 10px 24px;
          background-color: colors.$blue-gray-100;
          border-radius: 4px;

          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: colors.$blue-gray-900;
          white-space: nowrap;
          cursor: pointer;
        }

        > .authorise-button {
          width: fit-content;
          padding: 10px 24px;
          background-color: colors.$teal-600;
          border-radius: 4px;

          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: colors.$white;
          white-space: nowrap;
          cursor: pointer;
        }
      }

      > .help {
        grid-row: 1 / -1;
        height: fit-content;
        background-color: colors.$light-gray-50;
        padding: 24px;
        border-radius: 12px;

        > .label {
          display: flex;
          align-items: center;

          color: colors.$blue-gray-700;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          > .icon {
            @include elements.icon;
            margin-right: 8px;
          }
        }

        > .instruction {
          margin-top: 24px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          > .label {
            font-weight: 500;
          }

          > .steps {
            margin-top: 16px;
            list-style-position: inside;
          }
        }
      }
    }
  }

  > .permissions {
    margin-top: 40px;

    > .label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    > * {
      margin-top: 12px;
    }

    .integrated-receipts-permission {
      @include _permission-group;

      > .merchant-permission {
        display: grid;
        grid-template-areas: 'label switch' 'description description';

        > .label {
          grid-area: label;
        }
        > .switch {
          grid-area: switch;
        }
        > .description {
          grid-area: description;
          margin-right: 56px;
        }
      }
    }
  }

  > .retrieve-iclient-logs-button {
    width: fit-content;
    padding: 10px 24px;
    margin-top: 56px;
    border: 1px solid colors.$blue-400;
    border-radius: 4px;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: colors.$blue-800;
    white-space: nowrap;
    cursor: pointer;
  }
}
