.permission-manager {
  .modal {
    cursor: 'pointer';
    .link {
      pointer-events: none;
    }
  }

  .page {
    margin: 20px;
  }

  &.loading {
    height: 100%;
    padding-left: 70px;
  }

  &.no-employee {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 70px;
  }
}
