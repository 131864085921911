@use "../common/constants"as constants;
@use "../common/viewport"as viewport;
@use "../common/switch"as switch;
@use "../common/default-theme"as default-theme;
@use "../common/colors" as colors;
@use "../common/typography" as typography;
@use "../common/buttons" as buttons;

$padding: 10px 14px;

@mixin form-input {
  background-color: #{default-theme.$form-element-bg};
  border-radius: constants.$border-radius;
  border: 1px solid #{default-theme.$form-element-border};
  box-sizing: border-box;
  color: #{default-theme.$form-element-fg};
  font-weight: 400;
  margin: 0;
  transition: all 0.3s;
  width: 100%;
  padding: $padding;
}

@mixin help-text {
  font-size: 12px;
  color: #6c6c6c;
}

form {
  //
  // padding-top: 20px;

  label {
    font-size: 0.8rem;
    margin-left: 4px;
  }

  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  textarea {
    @include form-input();
  }

  input[type='file']::-webkit-file-upload-button {
    border: 1px solid #{default-theme.$form-element-border};
    background: #{default-theme.$form-element-bg};
    border-radius: 5px;
  }

  input[type='file'] {
    font-family: Poppins, sans-serif;
  }

  .preview {
    margin-bottom: 15px;

    img {
      border-radius: 20px;
      max-width: 100px;
      padding: 0;
      margin: 0;
      border: 3px solid #f1f1f5;
    }
  }

  .form-input {
    &.has-icon {
      @include form-input();
      padding: 0;
      display: flex;
      .icon {
        width: 30px;
        border-top-left-radius: constants.$border-radius;
        border-bottom-left-radius: constants.$border-radius;
        display: flex;
        padding-left: 12px;
        // background-color: white;
      }
      img {
        width: 100%;
        align-self: center;
      }
      input {
        margin: 0;
        border: 0;
      }
    }

    .has-prepend {
      display: flex;

      .prepend-text {
        @include form-input();
        width: auto;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      input {
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .form-items {
    display: grid;
    grid-row-gap: 18px;
  }

  .form-buttons {
    margin-top: 20px;
  }

  .select {
    @include form-input();
    padding: $padding;
  }

  .meta {
    margin-top: 5px;
    margin-left: 5px;
  }

  .error {
    font-size: 12px;
    color: #{default-theme.$form-element-error};
  }

  .help {
    @include help-text();
  }

  select {
    border: none;
    box-sizing: none;
    margin: 0;
    padding: none;
    width: 100%;
    background: transparent;
  }

  .checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@include viewport.tablet {
  form.responsive {
    display: grid;
    grid-template-columns: 180px 4fr 1fr;

    .form-items > .form-item > label {
      align-self: center;
      text-align: right;
      margin-right: 15px;
    }

    .form-items {
      row-gap: 25px;
      grid-row: 1;
      grid-column: 1 / -1;
    }

    .form-items > .form-item {
      display: grid;
      grid-template-columns: 180px 4fr 1fr;

      label {
        grid-row: 1;
        grid-column: 1 / 2;
      }

      .form-input {
        grid-row: 1;
        grid-column: 2 / 3;
      }

      .meta {
        grid-row: 2;
        grid-column: 2 / 3;
      }
    }
    .form-input {
      margin-top: 0px;
    }
    .form-buttons {
      padding-left: 4px;
      margin-top: 30px;
      grid-row: 2;
      grid-column: 2 / 3;
    }
  }

  form.horizontal {
    .form-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      grid-column-gap: 20px;
    }
  }
}

@mixin inline {
  padding: 20px 15px;
  display: grid;
  grid-gap: 18px;

  // make switch display in same line
  .switch {
    @include switch.inline();
    label {
      text-align: left !important;
    }
  }
}

// invalid states

textarea,
input,
.input-box {
  &.invalid {
    border-color: #{default-theme.$form-element-error} !important;
  }
}

input:disabled {
  background: #f9f9f9 !important;
}

.form-input.disabled {
  background: #f9f9f9 !important;
}

@mixin top-align-label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  padding: 8px 18px !important;
  font-weight: 400;
}

/**
  * Form Containers
  */
@mixin form-group-container {
  margin: 0;
  padding: 16px 24px;
  border: 1px solid colors.$blue-gray-100;
  border-radius: 8px;

  .form-group {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .form-group-label {
      @include typography.body-3-500;
      color: #{colors.$blue-gray-900};
    }

    .form-group-help-text {
      @include typography.caption-2-400;
      color: colors.$blue-gray-700;
      display: flex;

      .info-icon {
        margin-right: 5px;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  > .form-item-container:nth-child(2) {
    padding-top: 20px;
  }
  > .form-item-container:not(:last-child) {
    padding-bottom: 15px;
  }
}

@mixin form-item-container {
  .form-item {
    margin: 0;
    padding: 8.5px 0px;
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;

    /* border states */
    &.invalid {
      border: 1px solid colors.$rose-500;
    }

    &.focus {
      border: 1px solid colors.$teal-500;
    }

    &.disabled {
      border: 1px solid colors.$light-gray-100;
      background-color: #{colors.$blue-gray-500}15;
    }

    /* label */
    .form-label {
      @include typography.caption-1-400;
      color: colors.$blue-gray-500;
      margin-bottom: 2px;
      padding: 0px 12px;
    }
  }

  /* switch */
  &.switch > .form-item {
    display: flex;
    align-items: center;

    .form-label {
      flex-grow: 1;
    }
  }

  /* meta */
  .form-meta {
    margin: 4px;

    .error {
      @include typography.caption-2-400;
      color: colors.$rose-800;
    }

    .help {
      @include typography.caption-2-400;
      color: colors.$blue-gray-500;
    }
  }
}

@mixin form-buttons {
  display: flex;
  margin: 40px 24px 0px 24px;

  button {
    font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
    flex-grow: 1;
  }

  .primary {
    @include buttons.primary-large;
  }
}
