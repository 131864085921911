.tyro-iclient-logs-widget {
  width: 50vw;
  height: 55vh;

  > .spinner-container {
    position: absolute;
    inset: 0;
  }

  > iframe {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    border: 0;
    border-radius: 20px;
  }
}
