@use '../common/default-theme' as default-theme;
@use '../common/typography' as typography;
@use '../common/scrollbar' as scrollbar;
@use '../common/colors' as colors;

.sidebar {
  height: 100%;
  width: 70px;
  position: fixed;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #{default-theme.$fg};
  overflow-y: auto;
  @include scrollbar.hide();

  .restoplus-logo {
    .icon {
      width: 45px;
    }
  }

  .link {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    place-items: center;
    padding: 0;

    .label {
      @include typography.p;
      font-size: 12px;
      text-align: center;
      color: #{default-theme.$secondary-fg};
    }
  }

  .main-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .offline.show {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 22px;
      height: 22px;
    }

    span {
      color: colors.$rose-500;
      margin-top: 8px;
    }
  }

  .offline.hide {
    visibility: hidden;
  }

  .not-available {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
