@use "../common/colors";

.employee-time-clock-start-end-shift-confirmation {
  display: grid;
  justify-items: center;
  padding: 48px 24px;
  width: 391px;
  background-color: colors.$teal-600;
  outline: 2px solid colors.$teal-600;
  border-radius: 20px;

  .label {
    margin-top: 24px;
    color: colors.$white;
    font-size: 18px;
    line-height: 27px;
  }

  .time {
    margin-top: 4px;
    color: colors.$white;
    font-size: 24px;
    line-height: 29px;
  }
}

/**
 * Modal is hidden under lock screen widget
 * Set modal z-index above 2000, 
 * If success confirmation is inside the modal
 */
#modal-container:has(.employee-time-clock-start-end-shift-confirmation) {
  z-index: 2001;
  position: absolute;
}

