@use "../common/colors";
@use "../common/elements";
@use "../common/typography";

.menu-items-widget {
  height: 100%;

  > .menu-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 12px;
    padding: 12px;
  }

  > .footer {
    @include typography.p;
    color: colors.$blue-gray-500;
    text-align: center;
    margin-bottom: 12px;
  }

  > .no-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    .icon {
      @include elements.icon($size: 28px);
    }

    .label {
      @include typography.h3;
      margin-top: 10px;
    }
  }
}
