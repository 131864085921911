@use "../common/constants"as constants;
@use "../common/viewport"as viewport;
@use "../common/switch"as switch;
@use "../common/default-theme"as default-theme;
@use "../common/typography"as typography;
$padding: 10px 14px;
@mixin form-input {
  background-color: #{default-theme.$form-element-bg};
  border-radius: constants.$border-radius;
  border: 1px solid #{default-theme.$form-element-border};
  box-sizing: border-box;
  color: #{default-theme.$form-element-fg};
  font-weight: 400;
  margin: 0;
  transition: all 0.3s;
  width: 100%;
  padding: $padding;
  resize: none;

  &::placeholder {
    color: #{default-theme.$gray};
  }
}

@mixin help-text {
  font-size: 12px;
  color: #6c6c6c;
}

form {
  //
  // padding-top: 20px;

  label {
    @include typography.h4;
    margin-left: 4px;
  }

  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  textarea {
    @include form-input();
  }

  input[type='file']::-webkit-file-upload-button {
    border: 1px solid #{default-theme.$form-element-border};
    background: #{default-theme.$form-element-bg};
    border-radius: 5px;
  }

  input[type='file'] {
    font-family: Poppins, sans-serif;
  }

  .preview {
    margin-bottom: 15px;

    img {
      border-radius: 20px;
      max-width: 100px;
      padding: 0;
      margin: 0;
      border: 3px solid #f1f1f5;
    }
  }

  .form-input {
    &.has-icon {
      @include form-input();
      padding: 0;
      display: flex;
      .icon {
        width: 30px;
        border-top-left-radius: constants.$border-radius;
        border-bottom-left-radius: constants.$border-radius;
        display: flex;
        padding-left: 12px;
        // background-color: white;
      }
      img {
        width: 100%;
        align-self: center;
      }
      input {
        margin: 0;
        border: 0;
      }
    }
  }

  .form-items {
    display: grid;
    grid-row-gap: 18px;
  }

  .form-item {
    position: relative;

    .form-input {
      > input {
        @include typography.p;
      }
    }

    .input-icon {
      opacity: 0.4;
      position: absolute;
      right: 10px;
      font-size: 22px;
      top: 0;
      height: 100%;
      display: grid;
      justify-content: center;
      align-items: center;
    }
  }

  .form-buttons {
    margin-top: 20px;
  }

  .select {
    @include form-input();
    padding: $padding;
  }

  .meta {
    margin-top: 5px;
    margin-left: 5px;
  }

  .error {
    font-size: 12px;
    color: #{default-theme.$form-element-error};
  }

  .help {
    @include help-text();
  }

  select {
    border: none;
    box-sizing: none;
    margin: 0;
    padding: none;
    width: 100%;
    background: transparent;
  }

  .checkbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@include viewport.tablet {
  form.responsive {
    display: grid;
    grid-template-columns: 180px 4fr 1fr;

    .form-items > .form-item > label {
      align-self: center;
      text-align: right;
      margin-right: 15px;
    }

    .form-items {
      row-gap: 25px;
      grid-row: 1;
      grid-column: 1 / -1;
    }

    .form-items > .form-item {
      display: grid;
      grid-template-columns: 180px 4fr 1fr;

      label {
        grid-row: 1;
        grid-column: 1 / 2;
      }

      .form-input {
        grid-row: 1;
        grid-column: 2 / 3;
      }

      .meta {
        grid-row: 2;
        grid-column: 2 / 3;
      }
    }
    .form-input {
      margin-top: 0px;
    }
    .form-buttons {
      padding-left: 4px;
      margin-top: 30px;
      grid-row: 2;
      grid-column: 2 / 3;
    }
  }

  form.horizontal {
    .form-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
      grid-column-gap: 20px;
    }
  }
}

@mixin inline {
  padding: 20px 15px;
  display: grid;
  grid-gap: 18px;

  // make switch display in same line
  .switch {
    @include switch.inline();
    label {
      text-align: left !important;
    }
  }
}

// invalid states

textarea,
input,
.input-box {
  @include typography.p;

  &.invalid {
    border-color: #{default-theme.$form-element-error} !important;
  }

  > span {
    color: #{default-theme.$form-element-fg}c4;
  }
}

input:disabled {
  background: #f9f9f9 !important;
}

.form-input.disabled {
  background: #f9f9f9 !important;
}

@mixin top-align-label {
  margin-top: 12px;
  align-self: flex-start !important;
}

.form-item.inline-title {
  background-color: #f9f9f9;
  padding: 8px 18px !important;
  font-weight: 400;
}
