@use "./viewport"as viewport;
@use "./default-theme" as default-theme;

@mixin h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0;
  color: #{default-theme.$fg};
  line-height: 26px;
  margin-bottom: 10px;
  font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}

@mixin h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0;
  color: #{default-theme.$fg};
  margin-bottom: 22px;
  line-height: 24px;
  font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}

@mixin h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}

@mixin h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0;
  color: #{default-theme.$fg};
  margin-bottom: 15px;
  line-height: 21px;
}

@mixin h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: #{default-theme.$fg};
  font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}

@mixin p {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}

@mixin bold {
  font-style: normal;
  font-weight: bold;
  font-family: 'Sailec-Bold', 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

p {
  @include p;
}

pre {
  @include p;
}

form input[type='text'],
form input[type='number'],
form input[type='password'],
form input[type='tel'],
form textarea {
  font-size: 15px;
}

/**
  * Typography
  */
@mixin h1-headline {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}

@mixin h2-headline {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

@mixin body-2-500 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

@mixin body-3-500 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

@mixin caption-1-400 {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

@mixin caption-2-400 {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@mixin button-1 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}

@mixin button-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: 'Sailec-Regular', 'Saira Condensed', Poppins, sans-serif;
}
