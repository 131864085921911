@use "../common/colors" as colors;
@use "../common/cta" as cta;
@use "../common/default-theme" as default-theme;
@use '../common/typography' as typography;

.item-print-priority-widget {
  display: grid;
  grid-template-rows: auto;

  .no-priority-items {
    padding-bottom: 20px;
  }

  .priority-items,
  .no-priority-items {
    .title {
      display: flex;
      font-weight: 600;
      font-size: 25px;
      line-height: 35px;
      padding: 10px 24px;
      margin-bottom: 15px;
      color: #1c1d3e;

      .number-of-priority-items {
        padding-left: 20px;
        font-weight: 400;
        color: #{colors.$blue-gray-500};
      }
    }

    .priority-item {
      @include typography.h4;

      font-weight: 600;
      margin-bottom: 0px;
      padding: 24px;
      color: #{colors.$blue-gray-900};
    }

    .items-per-category {
      .category-name {
        padding: 15px 24px;

        @include typography.h2;

        font-weight: 600;

        background-color: #{default-theme.$secondary-bg};

        color: #{colors.$blue-gray-900};
        margin-bottom: 0px;
      }
    }

    .items {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        border-bottom: 1px solid #f0f0f0;

        .set-priority {
          @include cta.link-cta;
          border: 1px solid #{default-theme.$primary};
          color: #{default-theme.$primary};
          max-width: 120px;
          padding: 10px;
          .label {
            font-weight: 600;
            font-size: 17px;
            line-height: 23px;
          }
        }
        .item-name {
          @include typography.h4;

          font-weight: 600;
          margin-bottom: 0px;
          color: #{colors.$blue-gray-900};
        }
      }
    }
  }

  .item-priority-drag-and-drop-widget {
    padding: 0px 24px;
    margin-bottom: 35px;

    .item {
      display: flex;
      align-items: center;
      padding: 18px;
      background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
      box-shadow: 0px 3px 10px -3px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      margin-bottom: 15px;
      border: 1px solid #f0f0f0;

      .label {
        flex: auto;
        @include typography.h4;
        margin-bottom: 0px;
        padding-left: 10px;
        color: #{colors.$blue-gray-900};
      }

      .drag-handle {
        cursor: move;
        display: flex;
        padding: 4px;
        align-items: center;
        flex: 0;
        touch-action: none;
      }

      .remove {
        display: flex;
        align-items: center;
        flex: 0;
      }
    }
  }
}
