@use "viewport" as viewport;
@use "constants" as constants;
@use "card" as card;
@use "typography" as typography;
@use "colors" as colors;
@use "default-theme" as default-theme;

@mixin primary {
  background-color: colors.$teal-600;
  color: colors.$white;
}

@mixin primary-transparent {
  color: #{default-theme.$button-primary-bg};
  background-color: transparent;
  border: 1px solid #{default-theme.$button-primary-bg};
}

@mixin success {
  background-color: #{default-theme.$button-success-bg};
  color: #{default-theme.$button-success-fg};
}

@mixin success-transparent {
  background-color: transparent;
  border: 1px solid #{default-theme.$button-success-bg};
  color: #0e9347;
}

@mixin failure {
  background-color: #{default-theme.$button-danger-bg};
  color: #{default-theme.$button-danger-fg};
}

@mixin failure-transparent {
  background-color: transparent;
  border: 1px solid #fc5a5a;
  color: #fc5a5a;
}

@mixin secondary {
  background-color: #{default-theme.$button-secondary-bg};
  color: #{default-theme.$button-secondary-fg};
}

@mixin secondary-transparent {
  background-color: transparent;
  color: #{default-theme.$secondary-fg};
  border: 1px solid #{default-theme.$secondary-fg};
}

@mixin badge-button {
  border-radius: 4px;
  padding: 0 10px;
  font-weight: 400;
  font-size: 12px;
  box-shadow: none;
}

@mixin place-order-button {
  @include typography.p;
  background-color: #{default-theme.$button-success-bg};
  color: #{default-theme.$button-success-fg};
  border-radius: constants.$border-radius;
  padding: 20px;
  white-space: nowrap;
}

@mixin add-to-cart-button {
  @include card.container();
  background-color: #{default-theme.$button-success-bg};
  color: #{default-theme.$button-success-fg};
  border-radius: constants.$border-radius;
  padding: 10px 0;
  white-space: nowrap;
}

@mixin footer-button {
  @include card.container();
  @include typography.p;
  background-color: #{default-theme.$button-success-bg};
  color: #{default-theme.$button-success-fg};
  border-radius: constants.$border-radius;
  padding: 20px;
  white-space: nowrap;
}

@mixin button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    margin-right: 10px;
  }
  a {
    @include typography.p;
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 6px;
  }

  a.cancel {
    color: #{default-theme.$message-error-fg};
  }
}

button {
  border-radius: constants.$border-radius;
  @include typography.button-1;
  text-align: center;
  padding: 10px 18px;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    font-size: 20px;
    margin-right: 7px;
  }
}

button.primary {
  @include primary();
}

button.success {
  @include success();
}

button.failure {
  @include failure();
}

button.disabled {
  background-color: #{default-theme.$primary};
  border-color: #{default-theme.$primary};
  color: #fff;
  opacity: 40%;
  cursor: not-allowed;
}

@mixin compact {
  button {
    background-color: white;
    padding: 7px 14px;
    .icon {
      color: #92929d;
      font-size: 20px;
    }
    .label {
      color: #{default-theme.$button-primary-bg};
    }
  }
}

/**
  * Contained buttons
  */
@mixin primary-large {
  @include typography.button-1;
  background-color: colors.$teal-600;
  border: 1px solid colors.$teal-600;
  border-radius: 4px;

  &:disabled {
    opacity: unset;
    background-color: colors.$teal-100;
    border: 1px solid colors.$teal-100;
  }
}

/**
  * Outlined buttons
  */
@mixin secondary-outlined-medium {
  @include typography.button-2;
  color: colors.$blue-gray-700;
  background-color: unset;
  border: 1px solid colors.$blue-gray-500;
  border-radius: 4px;
}

/**
  * Text buttons
  */
@mixin secondary-text-medium {
  @include typography.button-2;
  color: colors.$blue-gray-500;
  background-color: unset;
  text-decoration: underline;
}
