@use '../common/typography';
@use '../common/colors';
@use "../common/employee-time-clock" as employeeTimeClock;

.employee-time-clock-shift-list-edit-widget {
  @include employeeTimeClock.list;

  input[type='text'] {
    border: 1px solid colors.$blue-gray-200;
    max-width: 100px;
    font-size: 16px;

    &:disabled {
      border: none;
      background: transparent !important;
      color: colors.$blue-gray-900;
    }
  }

  .form-item {
    .time-picker {
      .time-input {
        .input-box {
          padding: 4px;
          min-width: min-content;
          border-radius: 0;
          border: none;

          input[type='text'] {
            border: none;
            outline: none;
            pointer-events: none;
          }

          i {
            display: none;
          }
        }
      }
    }

    &.edit {
      border: 1px solid colors.$blue-gray-200;
      border-color: colors.$blue-gray-200;
      .time-picker {
        .time-input {
          .input-box {
            input[type='text'] {
              pointer-events: auto;
            }
          }
        }
      }

      &.invalid {
        border-color: colors.$rose-500;
      }
    }
  }
}
