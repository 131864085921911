@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;
@use "../common/cta" as cta;
@use "../common/colors" as colors;

.cash-payment-collect-widget {
  width: 40vw;
  min-width: 800px;
  padding: 40px 32px;
  margin: 0 auto;

  display: grid;
  gap: 40px;

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .cash-tendered {
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;
    display: grid;
    padding: 24px;
    grid-template-columns: max-content 1fr max-content;

    .icon {
      background: url('../images/icon-cash.svg');
      height: 40px;
      width: 40px;
      background-repeat: no-repeat;
      background-position: center;
    }
    
    .payment-value {
      input {
        outline: none;
        border: none;
        background: none;
        
        text-align: right;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 38px;
        
        overflow: hidden;
        width: 100%;
      }

      input::placeholder {
        color: colors.$blue-gray-500;
      }


    }
  }

  .payment-presets {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;

    .preset {
      padding: 14px 0;
      text-align: center;
      
      background: linear-gradient(180deg, #F7F9FC 0%, #FFFFFF 100%);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      color: #{colors.$blue-gray-900};

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      
      cursor: pointer;

      &.selected {
        background: #{default-theme.$fg};
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
        color: #{default-theme.$bg};
      }
    }
  }

  .payment-info {
    border-radius: constants.$border-radius;
    background: linear-gradient(90deg, rgba(246, 248, 251, 0.549) 0%, rgba(233, 233, 233, 0.371) 100%);
    display: flex;
    flex-direction: column;
    padding: 0;
    
    .total, .change {
      padding: 16px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .label, .amount {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; 
      }
    }
    .total {
      border-bottom: 1px solid #DEE0E6;
    }

    .change .amount {
      color: #{colors.$rose-500};

      &::first-letter {
        color: #{colors.$rose-500}
      }

    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
    margin: 0 24px;

    button {
      width: 100%;
      &.done {
        @include cta.primary-cta;
        border-radius: 4px;
      }

      &.cancel {
        @include cta.link-cta;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
        border-radius: 4px;
      }
    }
  }
}