@use "../common/constants" as constants;
@use "../common/typography" as typography;
@use "../common/cta" as cta;
@use "../common/colors" as colors;

.table-bookings-page {
  display: grid;

  > .header {
    margin: 0 !important;
    .wrapper {
      border-bottom: none;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      .add-table-booking-button {
        @include cta.secondary-cta;
        margin-right: 20px;
        border-radius: 5px;
        border: 1px solid colors.$teal-600;
        color: colors.$teal-600 !important;
      }
      input {
        @include typography.p;
        border: none;
        padding: 8px 15px;
        border-radius: constants.$border-radius;
        outline: none;
        box-shadow: 0px 3px 15px -5px rgb(0 0 0 / 30%);
        text-indent: 30px;
        background-image: url('../images/icon-search.svg');
        background-repeat: no-repeat;
        background-position: 15px center;
        width: 380px;
      }
    }

    .table-booking-disabled {
      margin: 24px;
    }
  }
}
