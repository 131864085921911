@use "../common/colors";
@use "../common/typography";

.reorder-list-widget {
  > .orders {
    display: flex;
    flex-direction: column;
    padding: 12px;
  }

  > .no-orders {
    @include typography.p;
    text-align: center;
    margin-top: 60px;
    color: colors.$blue-gray-500;
  }

  > .spinner-container {
    width: auto;
  }
}
