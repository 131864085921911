@use "../common/colors" as colors;

.item-summary-description-view-widget {
  .modifiers {
    border-bottom: 1px solid #{colors.$blue-gray-100};
    margin-left: 35px;

    .title {
      font-weight: 600;
    }
  }

  .modifiers:last-child {
    border-bottom: none;
  }

  .modifier {
    display: grid;
    grid-template-columns: 150px 1fr;

    .name {
      color: #{colors.$blue-gray-500};
    }
  }
}
