@use '../common/typography' as typography;
@use "../common/constants" as constants;
@use '../common/colors' as colors;
@use "../common/cta" as cta;
@use "../common/default-theme" as default-theme;

.customer-notes-edit-widget {
  width: 65vw;
  .form-items {
    grid-row-gap: 10px;
  }
  .heading {
    padding: 30px 30px 0px 30px;
    display: grid;
    grid-template-areas:
      'title   icon'
      'title     icon';
    grid-template-columns: 1fr min-content;
    .title {
      grid-area: title;
      @include typography.h1;
      text-align: center;
    }
    .icon {
      grid-area: icon;
      cursor: pointer;
    }
  }
  .customer-notes-input {
    padding: 30px 30px 0px 30px;
    textarea {
      background-color: #{colors.$blue-gray-50};
      border: none;
      padding: 20px;
      &::placeholder {
        color: #{colors.$blue-gray-400};
      }
    }
  }
  .additional-text {
    padding: 0px 30px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    color: #{colors.$blue-gray-500};
  }
  .actions {
    max-height: calc(90vh - 236px);
    display: grid;
    align-self: self-end;
    padding: 30px;
    grid-template-columns: 50% 50%;
    gap: 10px;
    button.clear-button {
      @include cta.link-cta;
      border: 1px solid #{default-theme.$secondary-fg};
    }
    button.confirm-button {
      @include cta.primary-cta;
    }
  }
}
