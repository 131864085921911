@use "../common/typography";
@use "../common/cta";
@use "../common/constants";
@use "../common/colors";
@use "../common/elements";
@use "../common/scrollbar" as scroll;

.employee-time-clock-shift-break-presets-widget {
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 0 auto;
  border-radius: constants.$border-radius;
  color: colors.$blue-gray-900;
  background-color: colors.$white;
  height: min-content;
  max-width: 72vw;
  align-self: center;
  

  > .label {
    @include typography.h1;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 0;
    margin-bottom: 20px;
  }

  > .shift-break-presets {
    @include scroll.hide();
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
    max-width: 45vw;
    max-height: 60vh;
    overflow: scroll;

    > .shift-break-preset {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 60px 30px;
      margin: 24px 12px;

      text-align: center;
      @include typography.h2;
      margin-bottom: 0;

      box-shadow: colors.$card-shadow-light;
      background: colors.$white;
      border: 1px solid colors.$blue-gray-100;
      border-radius: constants.$border-radius;
      cursor: pointer;

      > .name {
        min-width: 200px;
        font-size: 18px;
        line-height: 27px;
      }

      > .duration {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  > .back-button {
    @include cta.link-cta;
    border: 1px solid colors.$blue-gray-500;
    border-radius: 4px;
    color: colors.$blue-gray-900;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 15px 24px 0px;
  }
}

#modal-container:has(.employee-time-clock-shift-break-presets-widget) {
  z-index: 2001;
  position: absolute;
}

