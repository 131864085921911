@use '../common/card';
@use '../common/colors';
@use '../common/typography';
@use '../common/buttons';

.deal-suggestion-card-widget {
  @include card.container;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: colors.$card-shadow;

  .pizzas-or-half-and-halves {
    display: grid;
    gap: 8px;
    padding: 24px 20px;
    background: colors.$modifier-gradient-bg;

    > .pizza-or-half-and-half {
      > .name {
        @include typography.h3;
        color: colors.$blue-gray-900;
        margin-bottom: 8px;
      }

      > .item-description-view-widget {
        margin-left: 20px;
        color: colors.$blue-gray-500;
      }
    }
  }

  .suggestion {
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
    background-color: #{colors.$blue-gray-50};
    border-left: 1px solid #{colors.$blue-gray-100};

    > .name {
      @include typography.h3;
      color: colors.$blue-gray-900;
      margin-bottom: 8px;
    }

    > .description {
      margin-bottom: 30px;
      color: colors.$blue-gray-500;

      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }

    .convert-button {
      @include buttons.primary-transparent;
      border-width: 2px;
      height: unset;
      padding: 10px;
      margin-top: auto;
    }
  }
}
