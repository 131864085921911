@use "../common/default-theme";
@use "../common/constants";
@use "../common/typography";
@use "../common/cta";
@use "../common/colors";
@use "../common/elements";

.card-payment-collect-widget {
  width: 40vw;
  min-width: 800px;
  padding: 40px 32px 32px;
  margin: 0 auto;
  color: colors.$blue-gray-900;

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .payment-amount {
    padding: 24px;
    border-radius: 8px;
    border: 1px solid colors.$blue-gray-100;
    background: colors.$light-gray-50;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    margin-top: 40px;

    .amount {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 38px;
      text-align: right;
      margin: 0;

      &::first-letter {
        color: colors.$blue-gray-500;
        margin-right: 5px;
      }
    }
  }

  .payment-options {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;

    .payment-option {
      .payment {
        padding: 40px;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
        border: 1px solid colors.$blue-gray-100;
        border-radius: 8px;

        display: grid;
        grid-auto-flow: row;
        gap: 12px;
        justify-items: center;

        cursor: pointer;

        .label {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0.09px;
        }
      }

      > .disclaimer {
        margin-top: 8px;
        display: flex;
        justify-content: center;

        > .icon {
          @include elements.icon(18px);
          margin-right: 4px;
        }
      }
    }
  }

  .actions {
    margin-top: 40px;
    display: flex;

    button {
      width: 100%;
      &.done {
        @include cta.primary-cta;
        margin-left: 14px;
      }

      &.cancel {
        @include cta.tertiary-cta;
        border-radius: 4px;
        color: colors.$blue-gray-700;
      }
    }
  }
}
