@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;
@use "../common/scrollbar" as scrollbar;

.terminal-settings-widget {
  .switch {
    padding-right: 20px;
    label {
      font-weight: 600;
      font-size: 15px;
      margin-left: 20px;
      margin-bottom: 0px;
      color: #{default-theme.$fg};
    }
    .help {
      font-weight: 400;
      font-size: 12px;
      margin: 0px 0px 5px 15px;
    }
  }
  .empty {
    margin: 40px auto;
    width: max-content;
    color: #{colors.$blue-gray-500};
  }

  .form-items {
    grid-row-gap: 0;

    .inline-title {
      background-color: #{default-theme.$secondary-bg};

      label {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-left: 2px;

        color: #{default-theme.$fg};
      }
    }
  }

  .items {
    margin-bottom: 10px;
    .item {
      padding: 10px 20px;
      border-bottom: 1px solid #f0f0f0;
    }
  }

  .advanced-options {
    display: grid;
    padding: 10px 20px 0px 20px;
    > .label {
      display: flex;
      font-weight: 900;
      font-size: 16px;
      line-height: 22px;
      color: #{colors.$teal-500};
      cursor: pointer;
      width: min-content;
      white-space: nowrap;

      .icon-chevron {
        cursor: pointer;
        margin-left: 5px;
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        &.open {
          background-image: url('../images/icon-up-chevron-alt.svg');
        }
        &.close {
          background-image: url('../images/icon-down-chevron-alt.svg');
        }
      }
    }

    .content {
      display: grid;
      grid-auto-flow: column;

      .number-of-copies-print-options {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        gap: 15px;
      }
    }
  }
}
