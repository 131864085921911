@use "default-theme"as default-theme;

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: grid;
  grid-column: 1 / -1; // overrides any grid template from parent container. E.g. the .pos-layout
  width: 100%;
  height: 100%;
  grid-template-rows: min-content min-content;
  align-content: center;
  text-align: center;
  flex-flow: column;
  justify-content: center;

  .spinner {
    font-size: 30px;
    color: #{default-theme.$primary};
    text-align: center;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  > p {
    margin: 0;
  }
}
