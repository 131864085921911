@use "../common/typography";
@use "../common/cta";
@use "../common/constants";
@use "../common/colors";
@use "../common/elements";

.docket-type-select-widget {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 900px;
  padding: 40px;
  margin: 0 auto;
  border-radius: constants.$border-radius;
  color: colors.$blue-gray-900;

  > .label {
    text-align: center;
    @include typography.h1;
  }

  > .docket-types {
    display: flex;
    padding: 20px 0;

    > .docket-type {
      width: 100%;
      padding: 40px;
      margin-right: 20px;

      text-align: center;
      @include typography.h2;
      margin-bottom: 0;

      box-shadow: colors.$card-shadow;
      background: colors.$modifier-gradient-bg;
      border-radius: constants.$border-radius;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      > .icon {
        @include elements.icon(40px);
        margin: auto;
        margin-bottom: 20px;
      }
    }
  }

  > .actions {
    margin-top: 20px;

    > .cancel-button {
      width: 100%;
      @include cta.link-cta;
      @include typography.h1;
      border: 2px solid #{colors.$blue-gray-100};
      color: #{colors.$blue-gray-900}70;
    }
  }
}
