@use "./colors";

$_default-size: large;

@mixin _base {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    border-radius: 100%;
  }
}

@mixin _small {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  &::before {
    height: 4px;
    width: 4px;
    margin-right: 6px;
  }
}

@mixin _medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  &::before {
    height: 6px;
    width: 6px;
    margin-right: 6px;
  }
}

@mixin _large {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  &::before {
    height: 8px;
    width: 8px;
    margin-right: 8px;
  }
}

@mixin _size($size: $_default-size) {
  @if $size == small {
    @include _small;
  } @else if $size == medium {
    @include _medium;
  } @else if $size == large {
    @include _large;
  } @else {
    @error "Invalid `$size` in `_ghost.scss`.";
  }
}

@mixin _color($color) {
  @if (not $color) {
    @error 'Invalid `$color` in `_ghost.scss`.';
  }

  color: $color;

  &::before {
    background-color: $color;
  }
}

@mixin gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$blue-gray-700);
}

@mixin light-gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$light-gray-200);
}

@mixin green($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$green-600);
}

@mixin red($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$rose-500);
}

@mixin yellow($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$yellow-600);
}

@mixin blue($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$blue-500);
}

@mixin violet($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$violet-500);
}

@mixin teal($size: $_default-size) {
  @include _base;
  @include _size($size);
  @include _color(colors.$teal-600);
}
