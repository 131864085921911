@use "../common/typography.scss";
@use "../common/default-theme";
@use "../common/elements";

%button {
  border-radius: 50%;
  cursor: pointer;
  padding: 4px;
  box-shadow: 0px 2px 4px rgba(34, 35, 51, 0.3);

  .icon {
    @include elements.icon($size: 20px);
  }
}

.popout-cart-item-quantity-widget {
  text-align: center;

  > .title {
    padding: 10px 48px;
    @include typography.h4;
    font-weight: 500;
    margin-bottom: 0;
  }

  > .counter {
    display: flex;

    > .quantity {
      flex-grow: 1;
      @include typography.h2;
      font-weight: 500;
      margin-bottom: 0;
    }

    > .increment {
      @extend %button;
      background-color: default-theme.$primary;
    }

    > .decrement {
      @extend %button;
      background-color: default-theme.$tertiary;
    }
  }
}
