@use "../common/colors";
@use "../common/elements";

.tyro-sales-report-widget {
  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 24px;
    border-bottom: solid 1px colors.$blue-gray-100;

    > .label {
      font-weight: 500;
      font-size: 24px;
      line-height: 33.77px;
    }

    > .print-button {
      width: fit-content;
      padding: 10px 24px;
      background-color: colors.$teal-600;
      border-radius: 4px;

      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: colors.$white;
      cursor: pointer;

      &.disabled {
        background-color: colors.$teal-300;
      }
    }
  }

  > .main {
    padding: 24px;

    > .select-date {
      display: flex;

      > .context {
        > .label {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        > .description {
          max-width: 174px;
          margin-top: 8px;

          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: colors.$blue-gray-500;
        }
      }

      > .date-picker-input {
        display: flex;
        height: fit-content;
        justify-content: space-between;
        min-width: 300px;

        margin-left: 40px;
        padding: 20px 12px;

        border: solid 1px colors.$blue-gray-100;
        border-radius: 8px;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        cursor: pointer;

        &.empty {
          color: colors.$blue-gray-400;
        }

        &.error {
          border-color: colors.$rose-500;
        }

        > .icon {
          @include elements.icon(24px);
          margin-left: 12px;
        }
      }
    }

    > .report-type {
      display: flex;
      margin-top: 32px;

      > .label {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      > .radio-group-input-widget {
        margin-left: 32px;
      }
    }

    > .actions {
      display: flex;
      margin-top: 40px;

      > .run-report-button {
        width: fit-content;
        padding: 10px 24px;
        background-color: colors.$teal-600;
        border-radius: 4px;

        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: colors.$white;
        cursor: pointer;
      }

      > .clear-button {
        margin-left: 24px;

        width: fit-content;
        padding: 10px 24px;
        background-color: colors.$blue-gray-100;
        border-radius: 4px;

        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-900;
        cursor: pointer;
      }
    }

    > .report {
      margin-top: 24px;

      > .label {
        width: fit-content;
        margin-bottom: 16px;
        margin-left: auto;

        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        > .date {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: colors.$teal-600;
        }
      }
    }
  }
}
