@use '../widgets/order-view-widget' as view-widget;
@use '../common/colors' as colors;

@mixin _button {
  width: 100%;
  padding: 14px 32px;
  border: solid 1px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.website-order-view-widget {
  @include view-widget.order-view-widget;

  &.loading {
    display: block;
  }

  .actions {
    button {
      &.print-order-button {
        @include _button;
        background-color: transparent;
        border-color: colors.$blue-gray-500;
        color: colors.$blue-gray-500;
      }
    }
  }
}


