@use "../common/typography" as typography;

.pac-container {
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0px 0px 16px rgba(49, 48, 62, 0.15) !important;
}

.pac-logo:after {
  display: none !important;
}

.pac-item {
  @include typography.p();
  line-height: 30px;
  padding: 7px 10px;

  .pac-icon {
    background-size: 50px !important;
  }

  .pac-icon-marker {
    background-position: -4px -268px !important;
  }
}
