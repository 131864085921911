@use "./default-theme" as default-theme;

/* The checkbox.container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 22px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.checkbox {
    /* Hide the browser's default checkbox */
    > input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* When the checkbox is checked, add a blue background */
    > input[type='checkbox']:checked ~ .checkmark {
      background-color: #{default-theme.$primary};
      border: 2px solid #{default-theme.$primary};
    }

    /* Show the checkmark when checked */
    > input[type='checkbox']:checked ~ .checkmark:after {
      display: block;
    }

    /* Create a custom checkbox */
    > .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      background-color: #{default-theme.$bg};
      border-radius: 5px;
      border: 2px solid #{default-theme.$border-light};

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid #{default-theme.$bg};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &.radio {
    /* Hide the browser's default checkbox */
    > input[type='radio'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* When the radio is checked, add a blue background */
    > input[type='radio']:checked ~ .checkmark {
      background-color: #{default-theme.$primary};
      border: 2px solid #{default-theme.$primary};
    }

    /* Show the checkmark when checked */
    > input[type='radio']:checked ~ .checkmark:after {
      display: block;
    }

    /* Create a custom radio */
    > .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 22px;
      width: 22px;
      background-color: #{default-theme.$bg};
      border-radius: 50%;
      border: 2px solid #{default-theme.$border-light};

      /* Create the checkmark/indicator (hidden when not checked) */
      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 3px;
        top: 3px;
        width: 4px;
        height: 4px;
        background: #{default-theme.$fg};
        border: solid #{default-theme.$fg};
        border-width: 4px;
        border-radius: 50%;
      }
    }
  }
}
