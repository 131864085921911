@use "../common/typography";
@use "../common/colors";

.employee-time-clock-header {
  display: grid;
  grid-area: header;
  justify-content: space-between;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, min-content);
  padding-bottom: 50px;
  grid-template-areas:
    'go-back-link  date-today'
    'header-title  date-today';

  .header-title {
    @include typography.h1;
    font-size: 24px;
    line-height: 29px;
    grid-area: header-title;
  }

  .go-back-link {
    grid-area: go-back-link;
    padding: 0 0 5px 10px;
    display: flex;
    cursor: pointer;

    > .arrow-left {
      margin-right: 12px;
      margin-top: 3px;
      background-image: url("../images/icon-chevron-left.svg");
      background-repeat: no-repeat;
      background-position: center;
      width: 10px;
      height: 12px;
    }

    span {
      @include typography.h5;
      color: colors.$blue-gray-500;
      align-self: center;
    }

    &.hide {
      visibility: hidden;
    }
  }

  .date-today {
    @include typography.h1;
    grid-area: date-today;
    text-align: right;

    span {
      @include typography.h5;
      color: colors.$blue-gray-500;
    }
  }
}
