@use "../common/colors";
@use "../common/scrollbar";
@use "../common/typography";
@use "../common/elements";

.saved-orders-widget {
  @include scrollbar.hide;
  overflow: hidden scroll;
  max-height: calc(90vh - 24px);
  padding: 24px;

  > .dine-in,
  > .pickup-and-delivery {
    margin-bottom: 38px;

    > .label {
      font-weight: 500;
      font-size: 25px;
      line-height: 35px;
      color: colors.$blue-gray-800;
    }

    .stashed-orders {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 14px;
      margin-top: 24px;

      > .stashed-order {
        display: flex;
        flex-direction: column;
        background: colors.$modifier-gradient-bg;
        box-shadow: colors.$card-shadow;
        border-radius: 6px;
        padding: 14px;
        @include typography.h4;
        font-weight: 500;
        color: colors.$blue-gray-900;
        cursor: pointer;

        > .body {
          margin-bottom: 14px;

          > .table-name {
            font-size: 18px;
            line-height: 24px;
          }

          > .new-item-count {
            @include typography.h5;
            font-weight: 400;
            color: colors.$rose-500;
          }

          > .collection-type {
            font-weight: 700;
          }

          > .requested-for {
            color: colors.$rose-500;
          }
        }

        > .footer {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .interval {
            @include typography.h5;
            font-weight: 400;
            color: colors.$blue-gray-500;
            margin-right: 30px;
          }

          > .delete-button {
            @include elements.icon($size: 24px);
          }
        }
      }

      .no-stashed-orders {
        margin-top: 24px;
      }
    }
  }
}
