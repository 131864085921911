.employee-time-clock-timesheet-page {
  height: 100%;

  > .header {
    display: none;
  }

  > .body {
    height: inherit;
  }
}
