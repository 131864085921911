@use "../common/colors";

.radio-group-input-widget {
  display: flex;

  > .option {
    display: flex;
    align-items: center;
    margin-left: 32px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }

    > input[type='radio'] {
      -webkit-appearance: none;
      appearance: none;

      width: 18px;
      height: 18px;

      border: 1.2px solid colors.$blue-gray-100;
      border-radius: 50%;
      cursor: pointer;

      &:checked {
        border: 5px solid colors.$teal-500;
      }
    }

    > .label {
      margin-left: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
