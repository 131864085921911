@use "../common/colors";

@mixin _highlighted {
  position: relative;
  color: colors.$blue-gray-900;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2em;
    height: 2em;
    border: solid 2px colors.$teal-500;
    border-radius: 25%;
  }
}

.date-picker-widget {
  padding: 24px;

  .DayPicker {
    &-wrapper {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0;
    }

    &-NavBar {
      display: contents;
    }

    &-NavButton {
      all: unset;
      position: absolute;
      top: 12px;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: center;
      cursor: pointer;

      &--prev {
        left: 4vw;
        background-image: url('../images/icon-chevron-down-grey.svg');
        transform: rotate(90deg);
      }

      &--next {
        right: 4vw;
        background-image: url('../images/icon-chevron-down-grey.svg');
        transform: rotate(270deg);
      }
    }

    &-Month {
      table-layout: fixed;
      width: 60.18vw;
      height: 59.841vh;
      border-spacing: 14px 12px;
      border-collapse: separate;
      margin: 0;

      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }

    &-Caption {
      text-align: center;
      padding: 12px 0;
      margin-bottom: 12px;

      > div {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &-WeekdaysRow {
      text-transform: uppercase;
    }

    &-Weekday {
      color: colors.$blue-gray-500;
      font-size: 16px;
      line-height: 22px;

      // Sunday and Monday
      &:first-child,
      &:nth-child(2) {
        &::after {
          content: 'N';
        }
      }
      // Tuesday
      &:nth-child(3) {
        &::after {
          content: 'E';
        }
      }
      // Wednesday
      &:nth-child(4) {
        &::after {
          content: 'D';
        }
      }
      // Thursday
      &:nth-child(5) {
        &::after {
          content: 'U';
        }
      }
      // Friday
      &:nth-child(6) {
        &::after {
          content: 'I';
        }
      }
      // Saturday
      &:nth-child(7) {
        &::after {
          content: 'T';
        }
      }
    }

    &-Day {
      color: colors.$blue-gray-900;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      &:hover {
        background-color: unset !important;
      }

      &--today {
        @include _highlighted;
      }

      &--disabled {
        color: colors.$blue-gray-100;
      }
    }
  }

  &.has-selected-date {
    .DayPicker-Day--today::before {
      content: unset;
    }

    .DayPicker-Day--highlighted {
      @include _highlighted;
    }
  }
}
