@use './notification-widget';
@use '../common/colors' as colors;

.new-order-notification-widget {
  @extend %notification-widget;
  // 'Approx width of the notification' - 'sidebar width'
  // 184px - 70px
  left: -123;
  background-color: #{colors.$rose-500};
  color: #{colors.$white};

  .link {
    padding: 0;
    margin: 0;
  }

  .count {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding-right: 12px;
  }
}
