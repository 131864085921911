@use "../common/card" as card;
@use "../common/cta" as cta;
@use "../common/default-theme" as default-theme;

.new-table-booking-list-item-widget {
  @include card.container;
  background: linear-gradient(180deg, #F7F9FC 0%, #FFFFFF 100%);
  box-shadow: 0px 3px 10px -3px rgba(0, 0, 0, 0.3);
  border-radius: 8px;

  position: relative;

  display: grid;
  grid-template-columns: 1.25fr repeat(3, 1fr) 2fr max-content;
  gap: 28px;
  align-items: center;

  padding: 24px;

  .booked-for {
    .time {
      font-weight: 700;
    }
  }

  .customer-info {
    .name {
      font-weight: 700;
    }
  }

  .actions {
    display: flex;

    .accept-button {
      @include cta.primary-cta;
      
      padding: 12.5px 32.5px;
      margin-right: 16px;
    }

    .reject-button {
      @include cta.danger-cta;
      
      padding: 12.5px 32.5px;
      margin-right: 16px;
    }
  }
}