@use "../common/typography" as typography;
@use "../common/cta"  as cta;
@use "../common/constants"  as constants;
@use "../common/colors"  as colors;
@use "../common/pill"  as pill;
@use "../common/ghost";

.pos-order-delivery-status-widget {
  .delivery-failed-info {
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    border-radius: constants.$border-radius;

    .header {
      display: flex;
      padding: 16px;
      align-items: center;
      background-color: #{colors.$rose-500};
      border-radius: 10px 10px 0px 0px;
      .title {
        @include typography.h1;
        padding: 10px 0px 0px 10px;
        color: #{colors.$white};
      }
    }
    .sub-title {
      @include typography.h3;
      padding: 16px;
      border-bottom: 1px solid #{colors.$blue-gray-100};
    }

    .actions {
      display: flex;
      .label-actions {
        margin-bottom: 10px;
      }
      .refund-customer {
        padding: 16px 50px 16px 16px;
        border-right: 1px solid #{colors.$blue-gray-100};
        .refund-button {
          @include typography.h4;
          text-align: center;
          background-color: #{colors.$rose-800};
          color: #{colors.$white};
          border: 1px solid #{colors.$rose-800};
          padding: 15px;
          border-radius: 10px;
          cursor: pointer;
          transition: ease-in-out all 0.2s;
        }
      }
      .mark-completed-order {
        padding: 16px;
        .completed-order-button {
          @include cta.secondary-cta;
        }
      }
    }
  }

  .cancelled-info {
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    padding: 24px;
    border-radius: constants.$border-radius;

    .courier-and-label-container {
      box-shadow: none;
      padding: 0;
      grid-auto-columns: unset;

      > .status-label {
        grid-column-start: 1; // reverse grid column order
        grid-row-start: 1; // reverse grid column order
        padding-right: 20px;
      }

      > .courier-info {
        border-left: 1px solid #{colors.$blue-gray-100};
        padding-left: 20px;
      }
    }
  }

  .delivered-info {
    display: grid;
    grid-template-areas:
      'status-label              status-label'
      'proof-of-delivery-label   courier-info';
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    border-radius: constants.$border-radius;

    > .status-label {
      grid-area: status-label;
      background-color: #{colors.$green-500};
      border-radius: constants.$border-radius constants.$border-radius 0 0;
      padding: 24px;
    }

    > .proof-of-delivery-label {
      grid-area: proof-of-delivery-label;
      display: grid;
      grid-template-rows: min-content min-content;
      align-self: center;
      gap: 10px;
      margin: 24px;
      margin-right: 0;
      padding-right: 20px;

      > .label {
        color: #{colors.$blue-gray-900};
        font-weight: 400;
        font-size: 16;
        line-height: 24px;
      }

      .view-link {
        color: #{colors.$blue-gray-700};
        text-decoration: underline;
        align-self: flex-end;
        cursor: pointer;
      }
    }

    > .courier-info {
      grid-area: courier-info;
      border-left: 1px solid #{colors.$blue-gray-100};
      margin: 24px;
      margin-left: 0;
      padding-left: 20px;
    }
  }

  .courier-pickup-date-and-time {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 16px;
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    padding: 16px;
    border-radius: constants.$border-radius;

    > img {
      width: 100px;
    }

    .title {
      color: #{colors.$blue-gray-900};
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .subtitle {
      color: #{colors.$blue-gray-900};
      font-weight: 500;
      font-size: 14px;
      display: grid;
      grid-auto-flow: column;
      gap: 4px;

      > .time-since-label-widget {
        color: #{colors.$teal-500} !important;
      }
    }
  }

  .tracking-map {
    margin-bottom: 16px;
    border-radius: constants.$border-radius;
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    overflow: hidden;

    .map-container {
      width: 100%;
      height: 240px;
      overflow: hidden;
      border-radius: constants.$border-radius constants.$border-radius 0 0;

      > iframe {
        overflow: hidden;
        width: 170%;
        height: 555px;
        position: relative;
        top: -45px; // remove header
        left: -1px; // remove black border on left
        transform: scale(0.6);
        transform-origin: 0 0;
      }
    }

    > .label {
      color: #{colors.$blue-gray-800};
      font-size: 14px;
      padding: 12px 14px;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: min-content 1fr;
      gap: 8px;
      align-items: center;
    }
  }

  .courier-and-label-container {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr max-content;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
    padding: 16px;
    border-radius: constants.$border-radius;
  }

  .courier-info {
    display: grid;
    grid-template-areas:
      'image   name'
      'image   vehicle'
      'image   contact';
    grid-auto-columns: min-content 1fr;
    gap: 0 12px;
    align-items: center;

    .courier-image {
      grid-area: image;
      width: 74px;
      border-radius: 50%;
    }

    .courier-name {
      grid-area: name;
      color: #{colors.$blue-gray-900};
      font-size: 16px;
      font-weight: 500;
    }

    .courier-vehicle {
      grid-area: vehicle;
      color: #{colors.$blue-gray-500};
      font-size: 14px;
      font-weight: 400;
    }

    .courier-contact-number {
      grid-area: contact;
      text-decoration: none;
      color: #{colors.$blue-gray-500};
      font-size: 14px;
      font-weight: 500;
    }
  }

  .status-label {
    @include typography.h4;
    font-weight: 400;
    line-height: 22px;
    margin: 0;

    &.ongoing {
      @include ghost.green;

      &::before {
        background-color: #{colors.$blue-900} !important;
      }
    }

    &.failed {
      color: #{colors.$rose-800};
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: min-content 1fr;
      gap: 12px;
    }

    &.success {
      font-size: 18px;
      font-weight: 500;
      color: #{colors.$white};
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: min-content 1fr;
      gap: 12px;
    }
  }
}
