@use '../common/typography' as typography;
@use "../common/constants" as constants;

.manage-sold-out-items-page {
  input {
    @include typography.p;
    border: none;
    padding: 8px 15px;
    border-radius: constants.$border-radius;
    outline: none;
    box-shadow: 0px 3px 15px -5px rgb(0 0 0 / 30%);
    text-indent: 30px;
    background-image: url('../images/icon-search.svg');
    background-repeat: no-repeat;
    background-position: 15px center;
    width: 380px;
  }
}
