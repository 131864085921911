@use '../common/card' as card;
@use '../common/default-theme' as default-theme;
@use '../common/typography' as typography;
@use '../common/colors' as colors;

.item-sales-info-table-view-widget {
  table {
    width: 100%;
    table-layout: fixed;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
  }
  .item-sales-table {
    * {
      // unset/customise some default styles from Google Charts library
      background: unset;
      @include typography.p;
      white-space: normal;

      td,
      th {
        &:not(:first-child),
        &:not(:last-child) {
          border-right: none;
          border-left: none;
        }
      }

      th {
        border-top: 1px solid #{default-theme.$border-light};
        border-bottom: none;
      }

      td:first-child,
      th:first-child {
        border-left: 1px solid #{default-theme.$border-light};
      }
      td:last-child,
      th:last-child {
        border-right: 1px solid #{default-theme.$border-light};
      }
    }

    .item-sales-header {
      background: #{colors.$blue-gray-50};
      font-weight: 500;

      .item-sales-header-cell {
        padding: 14px 12px;
        &.unsorted::after {
          content: '\25b2\25bc';
          color: #{colors.$blue-gray-200};
          writing-mode: vertical-rl;
          font-size: 10px;
          position: absolute;
        }
        &.sort-ascending > span::after,
        &.sort-descending > span::after {
          color: #{colors.$blue-500};
        }
        &:first-child {
          text-align: left;
          width: 45%;
        }
      }
    }

    // make table corners rounded
    .item-sales-header {
      &:first-child th:first-child {
        border-top-left-radius: 8px;
      }
      &:first-child th:last-child {
        border-top-right-radius: 8px;
      }
    }
    .item-sales-row {
      &:last-child td:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }

  .item-sales-cell {
    padding: 14px 12px;
    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:nth-child(3) {
      font-weight: 500;
    }
    &:last-child {
      color: #{colors.$blue-500};
    }
  }
}
