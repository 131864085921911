@use './notification-widget';
@use '../common/colors' as colors;

.new-table-booking-notification-widget {
  @extend %notification-widget;
  // 'Approx width of the notification' - 'sidebar width'
  // 297px - 70px
  left: -227px;
  background-color: #{colors.$yellow-400};
  color: #{colors.$white};

  img {
    padding-left: 24px;
  }

  .count {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    padding: 12px 24px 12px 16px;
  }
}
