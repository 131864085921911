@use "../common/viewport" as viewport;
@use "../common/default-theme" as default-theme;
@use "../common/scrollbar" as scrollbar;
@use "../common/constants" as constants;
@use "../common/cta" as cta;
@use '../common/typography' as typography;
@use '../common/card' as card;

@mixin top-check {
  &:before {
    content: '';
    position: absolute;
    background-color: #{default-theme.$primary};
    display: block;
    right: -8px;
    top: -8px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    right: 0px;
    top: -3px;
    width: 5px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@mixin options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  overflow: hidden scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .option {
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    background: #{default-theme.$modifier-gradient-bg};
    color: #{default-theme.$fg};
    box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
    transition: unset;
    margin: 0;
    position: relative;
    padding: 20px 10px;
    cursor: pointer;
    border-radius: 6px;

    &.selected {
      @include top-check;
      background: #{default-theme.$modifier-gradient-active-bg};
      box-shadow: none;
      color: #{default-theme.$bg};

      .charge {
        color: #{default-theme.$bg};
      }
    }

    .charge {
      color: #{default-theme.$fg}90;
      margin: 0;
      margin-top: 7px;
    }
  }
}

@mixin pizza-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 15px;

  .option {
    display: flex;
    flex-flow: column;
    text-align: center;
    background: #{default-theme.$modifier-gradient-bg};
    color: #{default-theme.$fg};
    box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
    transition: unset;
    margin: 0;
    position: relative;

    &.selected {
      @include top-check;
      background: #{default-theme.$modifier-gradient-active-bg};
      box-shadow: none;
      color: #{default-theme.$bg};

      .extra-charge {
        padding: 20px 10px;
        padding-top: 0;
        color: #{default-theme.$bg};
      }
    }

    .clickable {
      display: unset;
      margin: 0;
      padding: 20px 10px;
      padding-bottom: 5px;

      .radio,
      .checkbox {
        display: none;
      }

      .name {
        white-space: unset;
      }
    }

    .extra-charge {
      padding: 20px 10px;
      padding-top: 0;
      color: #{default-theme.$fg}90;
    }
  }
}

@mixin modifiers {
  .modifier {
    margin-bottom: 24px;

    .header {
      margin: 0px 0px 29px 0px;
      padding: 0px;

      .wrapper {
        display: flex;

        .badge {
          margin-left: 6px;
        }
      }
    }

    .options {
      display: grid;
      grid-template-columns: repeat(4, minmax(100px, 1fr));
      grid-gap: 15px;
      padding: 0px;

      &::-webkit-scrollbar {
        display: none;
      }

      .option {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        text-align: center;
        background: #{default-theme.$modifier-gradient-bg};
        color: #{default-theme.$fg};
        box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
        transition: unset;
        margin: 0px;
        cursor: pointer;
        border-radius: 6px;

        &.single-quantity {
          &.selected {
            @include top-check;

            background: #{default-theme.$modifier-gradient-active-bg};
            box-shadow: none;
            color: #{default-theme.$bg};

            .charge {
              color: #{default-theme.$bg};
            }
          }
        }

        &.single-select,
        &.multi-select {
          padding-top: 12px;
        }

        .name {
          margin-bottom: 4px;
          padding: 0px 20px;
        }

        .extra-charge {
          margin-bottom: 14.5px;
          color: #{default-theme.$secondary-fg};
          font-weight: 400;
          font-size: 14px;
          line-height: 18.2px;
        }

        .quantity {
          justify-content: space-between;
          border-top: 1px solid #{default-theme.$border-light};
          width: 100%;
          margin-right: 0px;
          padding: 8px 10px;
        }

        .clickable {
          padding: 0px;
          margin: 0px;

          .name {
            white-space: unset;
          }

          .container {
            display: none;
          }
        }
      }
    }
  }
}
