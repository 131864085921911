@use '../common/colors';
@use '../common/typography';
@use '../common/elements';

@mixin item {
  background: linear-gradient(180deg, #f7f9fc 0%, #fff 100%);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  > .header {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid colors.$blue-gray-100;

    > .collection-type {
      display: flex;
      align-items: center;

      > .icon {
        @include elements.icon($size: 20px);
      }

      > .label {
        font-size: 10px;
        letter-spacing: 0.05em;
        line-height: 14px;
        color: colors.$blue-gray-500;
        margin-left: 8px;
      }
    }

    > .item-count {
      text-align: right;
      color: colors.$blue-gray-500;
      font-size: 13px;
      line-height: 19px;
      font-weight: 400;
    }

    div:nth-child(3) {
      display: flex;

      > .placed-time {
        @include typography.h3;
        font-weight: 500;
        color: colors.$blue-gray-900;
      }

      > .order-type {
        font-size: 10px;
        line-height: 14px;
        height: fit-content;
        color: colors.$blue-gray-700;
        background-color: colors.$light-gray-500;
        padding: 4px;
        border-radius: 3px;
        margin-left: 8px;
      }
    }

    > .add-all-to-order-button {
      width: fit-content;
      margin-left: auto;

      > .label {
        @include typography.h5;
        display: inline-block;
        color: colors.$teal-500;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
