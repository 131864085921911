@use "../common/card" as card;
@use "../common/typography" as typography;
@use "../common/default-theme"as default-theme;
@use "../common/constants"as constants;
@use "../common/cta" as cta;
@use "../common/colors" as colors;

.start-saved-orders-widget {
  background-color: #F4F8FE;
  color: #{default-theme.$fg};

  display: grid;
  place-items: center;

  .container {
    @include card.container;
    
    max-width: 90vw;
    min-width: 800px;
    padding: 48px;
    margin-bottom: 0;

    box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
    border-radius: 20px;

    .header {
      font-weight: 600;
      font-size: 32px;
      line-height: 45px;
      text-align: center;

      margin-bottom: 24px;
    }

    .saved-orders-widget {
      max-height: 60vh;
    }

    .actions {
      margin-top: 10px;
      button {
        width: 100%;
        @include cta.link-cta;
        border: 1px solid #{default-theme.$secondary-fg};
      }
    }
  }
}