@use "../common/colors";

// 'TIME' 'CARD TYPE' 'TRANSACTION REF' 'PURCHASE' 'CASH OUT' 'REFUNDS' 'TIPS' 'TOTAL'
$_grid-template-columns: 0.5fr 1fr 1.5fr repeat(4, 1fr) 1.5fr;
$_gap: 8px;

.tyro-reconciliation-report-detail-widget {
  overflow: auto;

  > .labels {
    display: grid;
    grid-template-columns: $_grid-template-columns;
    gap: $_gap;
    padding: 14px 24px;

    color: colors.$blue-gray-500;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
  }

  > .list {
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    > .transaction {
      display: grid;
      grid-template-columns: $_grid-template-columns;
      gap: $_gap;
      padding: 14px 24px;

      border-top: 1px solid colors.$blue-gray-100;

      &:first-child {
        border-top: none;
      }

      > .transaction-time {
        color: colors.$blue-gray-500;
      }

      > .transaction-card-type {
        font-weight: 500;
      }
    }

    > .total {
      display: grid;
      grid-template-columns: $_grid-template-columns;
      gap: $_gap;
      padding: 14px 24px;

      border-top: 1px solid colors.$blue-gray-100;

      > .amount {
        grid-column-end: -1;
      }
    }
  }
}
