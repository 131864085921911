@use "../common/typography";
@use "../common/default-theme";
@use "../common/colors";
@use "../common/scrollbar";
@use "../common/elements";

%new-items-label-content {
  content: '';
  display: inline-block;
  position: relative;
  vertical-align: middle;
  height: 1px;
  background-color: colors.$teal-500;
  width: 50%;
}

.cart-items-widget {
  &.is-empty {
    height: 100%;
  }

  > .no-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    > .icon {
      @include elements.icon($size: 73px);
    }

    > .label {
      @include typography.h5;
      font-weight: 500;
      color: #{colors.$white}99;
      margin-top: 16px;
    }
  }

  > .printed-items,
  > .unprinted-items {
    @include scrollbar.hide;
    padding: 6px 0;
    overflow: hidden scroll;
  }

  > .new-items-label {
    color: colors.$teal-500;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    text-align: center;

    &:before {
      @extend %new-items-label-content;
      right: 0.5em;
      margin-left: -50%;
    }

    &:after {
      @extend %new-items-label-content;
      left: 0.5em;
      margin-right: -50%;
    }

    &:first-child {
      margin-top: 12px;
    }
  }

  .convert-pizzas-to-deal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    color: #{colors.$white};
    background-color: #{colors.$white}20;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    .pizza-icon {
      margin-right: 14px;
    }
  }
}
