@use '../common/colors';
@use '../common/scrollbar';

// Common styles for staff records and timesheet
@mixin container {
  height: 100vh;
  display: grid;
  grid-template-areas:
    'header        header'
    'date-picker   actions'
    'labels        labels'
    'list          list';
  grid-template-rows: repeat(3, min-content) 1fr;
  grid-template-columns: 1fr;
  overflow: hidden;
  padding: 15.5px 24px 0 24px;
}

@mixin timesheet-button($color, $bg-color, $border:1px solid $bg-color) {
  border-radius: 4px;
  padding: 10px 24px !important;
  font-size: 14px;
  color: $color;
  background-color: $bg-color;
  border: $border;
}

@mixin labels {
  display: grid;
  grid-area: labels;
  padding: 22px 24px 8px 24px;
  font-size: 11px;
  color: colors.$blue-gray-500;
  justify-items: center;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.05em;

  > :first-child {
    justify-self: start;
  }

  > :last-child {
    justify-self: end;
  }
}

@mixin list {
  grid-area: list;
  display: flex;
  flex-direction: column;
  color: colors.$blue-gray-900;
  padding: 0 3px;
  overflow: scroll;
  @include scrollbar.hide;

  &::-webkit-scrollbar {
    width: 0px;
  }

  > .no-records {
    border: 1px solid colors.$blue-gray-100;
    border-radius: 8px;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    margin-bottom: 69px;
    display: flex;

    > .label {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: colors.$blue-gray-700;
      margin-top: 8px;
    }
  }
}

@mixin list-item {
  border: 1px solid colors.$blue-gray-100;
  border-bottom: none;
  display: grid;
  padding: 24px 12px 24px 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  justify-items: center;
  white-space: nowrap;

  &:first-of-type {
    margin-top: 0;
    border-radius: 8px 8px 0 0;
  }

  > :first-child {
    justify-self: start;
  }

  > :last-child {
    justify-self: end;
    padding-right: 30px;
  }
}

@mixin swal {
  padding: 0 !important;
  width: 391px !important;

  .swal2-header {
    padding: 24px 24px 0 24px;

    .swal2-title {
      margin: 0;
      padding: 0 60px;
      color: colors.$blue-gray-900;
      line-height: 24px;
      font-weight: 500;
    }
  }

  .swal2-content {
    padding-top: 10px;
  }

  .swal2-actions {
    padding: 24px;

    button {
      text-transform: capitalize;
      border-radius: 4px;
      padding: 14px 12px;
      font-size: 16px;
    }

    .confirm-button {
      background-color: colors.$teal-600;
      border: 1px solid colors.$teal-600;
    }

    .cancel-button {
      border: 1px solid #{colors.$blue-gray-100};
      color: colors.$blue-gray-900;
    }
  }
}

.swal2-container {
  .confirm-clock-out-question {
    @include swal();
  }

  .confirm-quit-editing-question {
    @include swal();

    .swal2-content {
      padding-top: 12px;
      font-size: 15px;
    }
  }

  .invalid-warning {
    @include swal();
  }
}
