@use "../common/colors";
@use "../common/typography";
@use "../common/scrollbar" as scroll;

@mixin option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: colors.$white;
  border-radius: 8px;
  min-height: 125px;
  cursor: pointer;

  .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 10px;
  }
}

.employee-time-clock-actions-widget {
  max-height: 95vh;
  padding: 24px;
  grid-template-columns: minmax(100%, 414px);
  grid-template-rows: min-content min-content 1fr;
  background-color: colors.$blue-gray-50;
  border-radius: 20px;
  gap: 4px;
  display: grid;

  .employee-name {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    justify-self: center;
  }

  .current-date-and-time-info-widget {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 10px 0 20px;
    
    
    .date {
      color: colors.$blue-gray-500;
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      padding-bottom: 4px;
    }
    
    .time {
      color: colors.$blue-gray-900;
      font-size: 24px;
      line-height: 29px;
      font-weight: 500;
    }
  }

  .options {
    @include scroll.hide();
    display: grid;
    gap: 10px;
    overflow-y: scroll;

    .clock-in-option {
      @include option();
      color: colors.$teal-600;
    }
  
    .clock-out-option {
      @include option();
      color: colors.$rose-800;
    }
  
    .break-option {
      @include option();
      color: colors.$yellow-600;
    }
  
    .timesheets-option {
      @include option();
      color: colors.$blue-600;
    }
  
    .staff-records-option {
      @include option();
      color: colors.$violet-500;
    }
  }

  &.hide {
    display: none;
  }
}
