@use '../common/typography';
@use "../common/cta";
@use "../common/constants";
@use "../common/colors";

.dine-in-customer-info-edit-widget {
  width: 75vw;
  z-index: 100;

  > .header {
    @include typography.h1;
    padding: 22px 0;
    text-align: center;
  }

  > .form {
    padding: 0 24px 40px 24px;

    .form-items {
      display: flex;

      .form-input {
        margin-top: 6px;
      }

      > .mobile-number-input {
        width: 100%;
      }

      > .name-input {
        width: 100%;
        margin-left: 16px;
      }
    }

    > .customer-algolia-index-record-list-widget {
      margin-top: 18px;
      max-height: 39vh;

      .suggested-customer-list {
        max-height: 30vh;
      }
    }
  }

  > .actions {
    display: flex;
    width: 100%;
    padding: 18px;

    > .cancel-button {
      @include cta.link-cta;
      width: 100%;
      border: 1px solid colors.$blue-gray-500;
    }

    > .done-button {
      @include cta.primary-cta;
      width: 100%;
      margin-left: 18px;
    }
  }
}
