@use '../common/colors' as colors;
@use '../common/default-theme' as default-theme;
@use '../common/scrollbar' as scrollbar;

.time-picker {
  .input-box {
    display: grid;
    grid-template-columns: repeat(6, min-content) 1fr;
    min-width: 165px;
    align-items: center;
    border: 1px solid #{colors.$blue-gray-100};
    border-radius: 8px;
    padding: 14px 16px;
    input {
      border: none;
      width: 30px;
      padding: 0;
      text-align: center;
    }
    .la-clock {
      text-align: right;
      color: #{default-theme.$gray};
      font-size: 20px;
      cursor: pointer;
    }
  }

  .options-wrapper {
    .options {
      z-index: 100;
      margin-top: 2px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: 260px;
      padding: 16px 18px 0;
      gap: 10px;
      border: 1px solid #{colors.$blue-gray-100};
      border-radius: 8px;
      position: absolute;
      background-color: #{colors.$white};
      .option-list {
        overflow-y: scroll;
        @include scrollbar.hide;
      }

      .option {
        padding: 10px;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        &.selected {
          color: #{colors.$teal-500};
        }
      }
    }
  }
}
