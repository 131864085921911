@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;
@use "../common/scrollbar" as scrollbar;

.sales-report-page {
  height: 100vh;
  .header {
    margin-bottom: unset;
  }
}
