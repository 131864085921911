@use "../common/card" as card;
@use "../common/typography" as typography;
@use "../common/default-theme"as default-theme;
@use "../common/constants"as constants;
@use "../common/cta" as cta;
@use "../common/colors" as colors;

.start-make-payment-widget {
  background-color: #f4f8fe;
  color: #{default-theme.$fg};

  display: grid;
  place-items: center;

  .container {
    @include card.container;

    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;

    width: 85vw;
    padding: 48px;
    margin-bottom: 0;

    box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
    border-radius: 20px;

    .title {
      font-weight: 600;
      font-size: 32px;
      line-height: 45px;
      text-align: center;

      margin-bottom: 24px;
    }

    input {
      @include typography.p;
      border: none;
      padding: 8px 15px;
      margin-inline: 10px;
      border-radius: constants.$border-radius;
      outline: none;
      box-shadow: 0px 3px 15px -5px rgb(0 0 0 / 30%);
      text-indent: 30px;
      background-image: url('../images/icon-search.svg');
      background-repeat: no-repeat;
      background-position: 15px center;
      width: 380px;
    }

    .tabs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content;
      align-items: center;
      border: 1px solid #{default-theme.$fg};
      border-radius: constants.$border-radius;
      margin: 20px 0;

      li {
        list-style: none;
        text-align: center;
        cursor: pointer;
        padding: 10px;
        border-bottom-left-radius: constants.$border-radius;
        border-top-left-radius: constants.$border-radius;
      }

      li:nth-child(2) {
        border-radius: 0;
        border-bottom-right-radius: constants.$border-radius;
        border-top-right-radius: constants.$border-radius;
      }

      li.active {
        background-color: #{default-theme.$fg};
        color: #{default-theme.$bg};
      }
    }

    .no-orders {
      font-size: 22px;
      margin: 10vh 0;
    }

    .pos-order-list-widget {
      .orders {
        overflow: hidden auto; // let child container handle scroll
        max-height: 45vh;
        margin: 0;
      }
    }

    .online-order-list-widget {
      .orders {
        overflow: hidden auto; // let child container handle scroll
        max-height: 45vh;
        margin: 0;
      }
    }

    .actions {
      margin-top: 10px;
      button {
        width: 100%;
        @include cta.link-cta;
        border: 1px solid #{default-theme.$secondary-fg};
      }
    }
  }
}
