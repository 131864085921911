@use "../common/colors";

@mixin input {
  display: grid;
  grid-auto-flow: column;
}

.duration-input {
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  border: 1px solid colors.$blue-gray-200;
  padding: 0 8px;

  .hours-input,
  .minutes-input {
    @include input();

    input[type="text"] {
      width: 22px;
      border: none;
      outline: none;
    }

    .label {
      font-size: 17px;
    }
  }

  &.invalid {
    border: 1px solid colors.$rose-500;
  }
}

