@use '../common/colors' as colors;
@use '../common/cta' as cta;

.uber-delivery-request-for-pos-order-success-widget {
  width: 750px;
  height: 500px;
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 80px 36px;

  > img {
    width: 140px;
  }

  .title {
    color: #{colors.$blue-gray-900};
    font-size: 25px;
    font-weight: 500;
    margin-top: 40px;
  }

  .subtitle {
    color: #{colors.$blue-gray-900};
    font-weight: 500;
    font-size: 17px;
    display: grid;
    grid-auto-flow: column;
    gap: 6px;
    margin-top: 16px;

    .time-since-label-widget {
      color: #{colors.$teal-500} !important;
    }
  }

  .actions {
    margin-top: 18px;
    display: grid;
    gap: 15px;
    width: 100%;

    .done-button {
      padding: 14px;
    }

    .cancel-uber-delivery-button {
      @include cta.tertiary-cta;
      padding: 14px;
    }
  }
}
