.time-period-edit-widget {
  width: 100%;
  max-width: 555px;

  > .form-input {
    display: flex;
  }

  .start-time {
    grid-area: start-time;
    width: 100%;
    max-width: 200px;
    margin-right: 16px;
  }

  .duration {
    grid-area: duration;
    width: 100%;
    min-width: 100px;
    max-width: 200px;
    margin-right: 16px;
    input {
      padding: 14px;
    }
  }

  .end-time-of-day {
    grid-area: end-time;
    width: 100%;
    min-width: max-content;
    max-width: 120px;
    align-self: center;
    text-align: center;
  }
}
