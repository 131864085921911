@use "../common/card" as card;
@use "../common/typography" as typography;
@use "../common/constants"as constants;
@use "../common/cta" as cta;
@use "../common/colors" as colors;
@use '../common/scrollbar' as scrollbar;
@use "../common/start-order-iso-date-picker" as iso-date-picker;
@use "../common/start-order-time-of-day-picker" as time-of-day-picker;

@keyframes highlight-green {
  0%,
  100% {
    background-color: #{colors.$teal-300}00;
  }

  50% {
    background-color: #{colors.$teal-300}80;
  }
}

.customer-info-edit-widget {
  .pickup-form,
  .delivery-form {
    overflow-y: scroll;
    @include scrollbar.hide;

    .form-items {
      display: grid;
      gap: 0 16px;
      grid-template-columns: 300px 1fr 200px;

      .requested-for-selector {
        grid-column: 1 / 4;
        margin-top: 6px;
        margin-bottom: 16px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        .requested-for-asap,
        .request-another-time {
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 12px 16px;
          background: colors.$modifier-gradient-bg;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.1);
          border-radius: 6px;

          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          cursor: pointer;

          &.selected {
            background: colors.$blue-gray-900;

            > .label {
              color: colors.$white;
            }
          }
        }

        /**
         * Checkbox icon
         */
        .requested-for-asap,
        .request-another-time {
          position: relative;

          .selected-icon {
            position: absolute;
            top: -6px;
            right: -8px;
            z-index: 2;
          }
        }

        .request-another-time {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          z-index: 999;

          > .label {
            text-align: center;
            flex-grow: 1;
          }

          > .request-another-time-options {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: colors.$white;
            border: solid 1px colors.$blue-gray-100;
            border-radius: 4px;

            > .option {
              padding: 10px 16px;
              border-bottom: 1px solid colors.$blue-gray-100;

              display: flex;
              align-items: center;

              img {
                margin-right: 14px;
              }

              // Remove bottom margin if last option
              &:last-child {
                border-bottom: 0;
              }

              &.selected {
                background-color: colors.$light-gray-100;
                font-weight: 500;
              }
            }
          }

          .drop-down-icon {
            position: absolute;
            right: 16px;
          }
        }
      }

      .delivery-channel-selector {
        grid-column: 1 / 4;
        margin-bottom: 16px;

        > .label {
          margin-bottom: 10px;
        }

        .delivery-channels {
          display: flex;

          .delivery-channel {
            display: flex;
            align-items: center;
            padding: 8px 14px;
            border: 2px solid colors.$blue-gray-100;
            border-radius: 8px;
            margin-right: 18px;

            img {
              margin-right: 16px;
            }

            > .label {
              font-weight: 500;
              font-size: 16px;
              line-height: 22px;
              color: #{colors.$blue-gray-900};
            }

            &:last-child {
              margin-right: 0;
            }

            &.selected {
              background: colors.$teal-50;
              border: 2px solid colors.$teal-500;
              border-radius: 8px;
            }
          }
        }
      }

      .mobile-input {
        grid-column: 1;
        margin-bottom: 16px;
      }

      .name-input {
        grid-column: 2 / 4;
        margin-bottom: 16px;
      }

      > .customer-algolia-index-record-list-widget {
        grid-area: unset;
        grid-column: 1 / 4;
        margin: 0;
        margin-bottom: 16px;

        .spinner-container {
          margin-top: 20px;
          width: 100%;
        }

        .suggested-customer-list {
          max-height: 18vh;
        }
      }

      .address-input {
        grid-column: 1 / 3;
        margin-bottom: 16px;
      }

      .delivery-charge-input {
        grid-column: 3 / 4;
        margin-bottom: 16px;

        &.uber-delivery-fee-applied {
          animation-name: highlight-green;
          animation-duration: 2s;
        }
      }

      .calculating-delivery-fee-label {
        grid-column: 3 / 4;
        position: relative;
        bottom: 14px;
        font-size: 12px;
        color: colors.$blue-gray-500;
      }

      .dropoff-notes-input {
        grid-column: 1 / 4;
        margin-bottom: 16px;
      }
    }

    .form-item {
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      padding: 8px 12px;

      > label,
      > .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: colors.$blue-gray-500;
        margin: 0;
      }

      > input,
      > .value,
      > .form-input > div > input,
      textarea {
        border: none;
        border-radius: 0;
        padding: 0;

        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: colors.$blue-gray-900;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: colors.$blue-gray-600;
        }
      }

      &.delivery-charge-input {
        display: grid;
        grid-template-areas:
          'label   spinner'
          'value   spinner';

        .label {
          grid-area: label;
        }

        .value {
          grid-area: value;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.0025em;
        }

        .spinner-container {
          grid-area: spinner;

          .spinner,
          .spinner > img {
            width: 15px;
            height: 15px;
            font-size: unset;
          }
        }
      }
    }
  }
}
