@use "../common/viewport" as viewport;
@use "../common/default-theme" as default-theme;
@use "../common/scrollbar" as scrollbar;
@use "../common/constants" as constants;
@use "../common/cta" as cta;
@use '../common/typography' as typography;
@use '../common/card' as card;
@use '../common/item-option-styles' as item-option-styles;
@use '../common/actions' as actions;
@use '../common/colors';

$option-padding: 15px;

@mixin item-widget {
  display: grid;
  grid-template-areas: 'main-panel   info-panel';
  grid-template-columns: 70% 30%;
  height: 90vh;
  width: 90vw;
  max-width: 1500px;
}

@mixin main-panel {
  @include scrollbar.hide;
  grid-area: main-panel;
  overflow-y: auto;
  padding: 28px constants.$modal-horizontal-gap;

  .modifier {
    .header {
      @include card.header;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .badge {
        color: #{default-theme.$gray};

        &.optional {
          color: #{default-theme.$highlight};
        }

        &.required {
          color: #{default-theme.$secondary};
        }

        &.is-selected {
          color: #{default-theme.$secondary-fg};
        }
      }
    }

    .options {
      @include card.content;

      .option:last-child {
        border-bottom: 0px;
      }

      .option {
        @include typography.h4;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr min-content min-content;
        grid-template-areas: 'input qty charge';
        color: #{default-theme.$fg}90;
        background: #{default-theme.$modifier-gradient-bg};
        align-items: center;
        border-radius: constants.$border-radius;
        transition: all 0.2s ease-out;
        cursor: pointer;
        font-size: 15px;
        line-height: 19.5px;
        font-weight: 400;
        margin-bottom: 8px;

        &.selected {
          color: #{default-theme.$bg};
          background-color: #{default-theme.$fg};
          box-shadow: none;
        }

        .clickable {
          grid-area: input;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 10px;
          cursor: pointer;
          padding: $option-padding;
          padding-right: 0;

          > .container {
            > input {
              margin-right: 12px;
              cursor: pointer;
            }
          }

          .name {
            max-width: 20vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .quantity {
          @include actions.quantity;
          grid-area: qty;
          display: flex;
          flex-direction: row;
          place-self: end;
          align-items: center;
          margin-right: 25px;
          padding: $option-padding 0;

          .icon {
            padding-top: 2px;
          }

          .number {
            margin: 0 20px;
          }
        }

        .extra-charge {
          grid-area: charge;
          white-space: nowrap;
          padding-right: $option-padding;
        }
      }
    }
  }
}

@mixin info-panel {
  @include scrollbar.hide;
  grid-area: info-panel;
  background-color: #{default-theme.$secondary-bg};
  border-radius: 0 constants.$border-radius-heavy constants.$border-radius-heavy 0;
  padding: 24px 24px 14px 24px;
  padding-bottom: 19px;
  display: grid;
  overflow: hidden scroll;
  grid-template-rows: min-content 1fr min-content;

  .spec-info {
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
      letter-spacing: 0.26px;
      color: colors.$blue-gray-900;
    }

    .description {
      color: #{default-theme.$secondary-fg};
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
    }
  }

  .summary {
    @include scrollbar.hide;
    overflow-y: hidden scroll;
    border-top: 1px solid #{default-theme.$border-light};
    margin: 20px 0;
    padding-top: 5px;

    &.hide {
      opacity: 0;
      transition: all 0.3s ease-out;
    }

    &.show {
      opacity: 1;
      transition: all 0.3s ease-in;
    }
  }

  .actions {
    > .quantity {
      @include actions.quantity;
      display: grid;
      grid-template-columns: 1fr repeat(3, min-content);
      background-color: white;
      padding: 8px 15px 8px 14px;
      margin-bottom: 15px;
      border-radius: constants.$border-radius;
      justify-content: space-between;
      align-items: center;

      > h4 {
        margin: 0;
        color: #{default-theme.$secondary-fg};
      }

      .btn {
        width: 28px;
        height: 28px;

        i {
          display: flex;
          flex-flow: column;
          justify-content: center;
        }
      }

      .number {
        font-size: 15px;
        line-height: 21px;
        color: colors.$blue-gray-900;
      }
    }

    .add-to-cart {
      @include cta.primary-cta;
      margin-bottom: 10px;
      padding: 14px;
      letter-spacing: 0;
      width: 100%;
      font-size: 17px;
      line-height: 23px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.1px;
    }

    .cancel {
      @include cta.link-cta;
      width: 100%;
      padding: 11px;
    }
  }
}

@mixin remove-toppings {
  .header {
    padding-top: 20px;
  }

  .options {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    grid-gap: 20px;

    .option {
      display: flex;
      flex-flow: column;
      text-align: center;
      background: #{default-theme.$modifier-gradient-bg};
      color: #{default-theme.$fg};
      box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
      border-radius: 30px;
      transition: unset;
      width: fit-content;
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      font-style: normal;
      line-height: 21px;
      color: #{default-theme.$restoplus-gray-dark};

      &.selected {
        background: #{default-theme.$modifier-gradient-active-bg};
        box-shadow: none;
        color: #{default-theme.$bg};
        text-decoration: line-through;

        .extra-charge {
          padding: 20px 10px;
          padding-top: 0;
          color: #{default-theme.$bg};
        }
      }

      .clickable {
        display: unset;
        margin: 0;
        padding: 10px 20px;

        .radio,
        .checkbox {
          display: none;
        }

        .name {
          word-break: break-all;
          white-space: unset;
          display: block;
        }
      }

      .extra-charge {
        display: none;
      }
    }
  }
}

@mixin added-toppings {
  .options {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 15px;

    .option {
      grid-template-columns: 1fr;
      grid-template-areas:
        'details'
        'actions';
      box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
      cursor: unset;
      margin: 0;
      background: #{default-theme.$modifier-gradient-bg};

      &.add-new-topping {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-bottom: 0px;
        padding: 32px 0;
        font-size: 30px;
        color: #{default-theme.$gray};
      }

      .topping-details {
        grid-area: details;
        text-align: center;
        border-bottom: 1px solid #{default-theme.$border-light};
        padding: 10px;

        .extra-charge {
          padding: 0;
          opacity: 0.7;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          line-height: 20px;
          color: #{default-theme.$secondary-fg};
        }
      }

      .actions {
        grid-area: actions;
        padding: 10px;

        .quantity {
          margin: 0;
          padding: 0;
          display: flex;
          place-content: space-between;
        }
      }
    }
  }
}

@mixin add-toppings-list {
  .add-toppings {
    height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: min-content 1fr min-content;

    .done-btn {
      @include cta.primary-cta;
      padding: 10px;
      width: 120px;
      font-weight: 400;
      border-radius: 8px;
    }

    input {
      @include typography.p;
      border: none;
      padding: 8px 15px;
      border-radius: constants.$border-radius;
      outline: none;
      box-shadow: 0px 3px 15px -5px rgb(0 0 0 / 30%);
      text-indent: 30px;
      background-image: url('../images/icon-search.svg');
      background-repeat: no-repeat;
      background-position: 15px center;
      width: 330px;
    }

    .header {
      padding-top: 0;
      > .title {
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        line-height: 26px;
        margin: auto 0;
      }
    }

    .empty-options {
      margin: auto;
      margin-top: 100px;
    }

    .options {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 100px;
      grid-gap: 15px;
      overflow-y: auto;
      @include scrollbar.hide();

      .option {
        background: #{default-theme.$modifier-gradient-bg};
        color: #{default-theme.$fg};
        box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
        transition: unset;
        margin: 0;
        position: relative;
        padding: 10px;

        &.selected {
          @include item-option-styles.top-check();
          background: #{default-theme.$modifier-gradient-active-bg};
          box-shadow: none;
          color: #{default-theme.$bg};

          .extra-charge {
            color: #{default-theme.$bg};
          }
        }

        .clickable {
          display: unset;
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

          .radio,
          .checkbox {
            display: none;
          }

          .name {
            white-space: unset;
            text-align: center;
          }
        }

        .quantity {
          display: none;
        }

        .extra-charge {
          padding: 0;
          color: #{default-theme.$fg}90;
        }
      }
    }

    .pagination-widget {
      padding: 0 18px;
      padding-top: 25px;
    }
  }
}

@mixin pizza-list {
  overflow-y: unset;

  .pizza-list-widget {
    height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: min-content 1fr min-content;

    .done-btn {
      @include cta.primary-cta;
      padding: 10px;
      width: 120px;
      font-weight: 400;
      border-radius: 8px;
    }

    input {
      @include typography.p;
      border: none;
      padding: 8px 15px;
      border-radius: constants.$border-radius;
      outline: none;
      box-shadow: 0px 3px 15px -5px rgb(0 0 0 / 30%);
      text-indent: 30px;
      background-image: url('../images/icon-search.svg');
      background-repeat: no-repeat;
      background-position: 15px center;
      width: 330px;
    }

    .header {
      padding-top: 0;
    }

    .empty-options {
      margin: auto;
      margin-top: 100px;
    }

    .options {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 100px;
      grid-gap: 15px;
      overflow-y: auto;
      @include scrollbar.hide();

      .option {
        background: #{default-theme.$modifier-gradient-bg};
        color: #{default-theme.$fg};
        box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
        transition: unset;
        margin: 0;
        position: relative;
        padding: 10px;

        &.selected {
          @include item-option-styles.top-check();
          background: #{default-theme.$modifier-gradient-active-bg};
          box-shadow: none;
          color: #{default-theme.$bg};

          .extra-charge {
            color: #{default-theme.$bg};
          }
        }

        .clickable {
          display: unset;
          margin: 0;
          padding: 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;

          .radio,
          .checkbox {
            display: none;
          }

          .name {
            white-space: unset;
            text-align: center;
          }
        }

        .quantity {
          display: none;
        }

        .extra-charge {
          padding: 0;
          color: #{default-theme.$fg}90;
        }
      }
    }

    .pagination-widget {
      padding: 0 18px;
      padding-top: 25px;
    }
  }
}

@mixin half-selector {
  background-color: #{default-theme.$primary-light};
  border-radius: constants.$border-radius;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  margin-bottom: 20px;

  .half {
    color: #{default-theme.$primary};
    padding: 12px;
    border-radius: constants.$border-radius;
    cursor: pointer;
    transition: all 0.2s ease-out;

    &.disabled {
      cursor: not-allowed;
    }

    &.selected {
      background-color: #{default-theme.$primary};
      color: #{default-theme.$bg};
    }
  }
}

@mixin selected-pizza {
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  padding: 0 18px;

  .header {
    display: flex;
    gap: 14px;

    .requiredness {
      color: #{default-theme.$secondary-fg};

      &.red {
        color: #{default-theme.$form-element-error};
      }
    }
  }

  .pizza {
    border-radius: constants.$border-radius;
    background: #{default-theme.$modifier-gradient-bg};
    box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
    padding: 20px;
    cursor: pointer;
    display: grid;
    align-items: center;

    .selected {
      text-align: center;

      .extra-charge {
        margin-top: 4px;
        opacity: 0.7;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 20px;
        color: #{default-theme.$secondary-fg};
      }
    }

    > i {
      font-size: 30px;
      display: block;
      text-align: center;
      color: #{default-theme.$gray};
      padding: 8px 0;
    }
  }
}

@mixin spec-info {
  .description {
    &.hide {
      position: absolute;
      bottom: 1000vh;
      opacity: 0;
      max-height: 1px;
      transition: all 0.3s ease-out;
    }

    &.show {
      position: relative;
      opacity: 1;
      max-height: 100vh;
      transition: all 0.3s ease-in;
    }
  }

  .description-toggle {
    cursor: pointer;
    width: fit-content;
    color: #{default-theme.$secondary-fg};
    border: 1px solid #{default-theme.$secondary-fg};
    border-radius: constants.$border-radius-heavy;
    margin-top: 15px;
    padding: 4px 14px;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    line-height: 21px;
  }
}

@mixin empty-info-panel-summary {
  display: grid;
  justify-content: center;
  margin: 15px 0;

  .icon {
    margin: 15px auto;
  }

  > p {
    color: #{default-theme.$secondary-fg};
    max-width: 60%;
    margin: 17px auto;
    margin-top: 4px;
    text-align: center;
    line-height: 21px;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
  }
}

@mixin toggle {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;

  .label {
    white-space: nowrap;
    color: #{default-theme.$secondary-fg};
  }

  .toggle {
    width: 45px;
    height: 27px;
    border: 2px solid #{default-theme.$border-light};
    background-color: #{default-theme.$border-light};
    border-radius: constants.$border-radius-heavy;
    display: grid;
    transition: background-color 500ms ease, border-color 500ms ease;

    &.on {
      border: 2px solid #{default-theme.$highlight};
      background-color: #{default-theme.$highlight};
    }

    .switch {
      width: 23px;
      height: 23px;
      background-color: #{default-theme.$bg};
      border-radius: constants.$border-radius-heavy;
      transition: all 0.15s ease;
      transform: rotate(45deg);
      transform-origin: 50% 50%;

      &.on {
        transform-origin: 90% 145%;
      }
    }
  }
}
