@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;

.manage-item-routing-widget {
  .empty {
    margin: 40px auto;
    width: max-content;
    color: #{colors.$blue-gray-500};
  }

  .form-items {
    grid-row-gap: 0;

    .inline-title {
      background-color: #{colors.$blue-gray-50};

      label {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-left: 2px;

        color: #{default-theme.$fg};
      }
    }
  }

  .items {
    margin-bottom: 10px;
    .item {
      padding: 10px 20px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
