@use "./colors";

$_default-size: medium;

@mixin _base {
  padding: 4px 12px;
  border-radius: 100px;
}

@mixin _small {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

@mixin _medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

@mixin _large {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@mixin _size($size: $_default-size) {
  @if $size == small {
    @include _small;
  } @else if $size == medium {
    @include _medium;
  } @else if $size == large {
    @include _large;
  } @else {
    @error "Invalid `$size` in `_pill.scss`.";
  }
}

@mixin primary-gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$blue-gray-700;
  color: colors.$white;
}

@mixin primary-light-gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$light-gray-900;
  color: colors.$white;
}

@mixin primary-green($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$green-600;
  color: colors.$white;
}

@mixin primary-red($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$rose-500;
  color: colors.$white;
}

@mixin primary-yellow($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$yellow-500;
  color: colors.$blue-gray-900;
}

@mixin primary-blue($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$blue-500;
  color: colors.$white;
}

@mixin primary-violet($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$violet-500;
  color: colors.$white;
}

@mixin primary-teal($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$teal-600;
  color: colors.$white;
}

@mixin secondary-gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$light-gray-500;
  color: colors.$blue-gray-700;
}

@mixin secondary-light-gray($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$light-gray-100;
  color: colors.$blue-gray-700;
}

@mixin secondary-green($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$green-50;
  color: colors.$green-700;
}

@mixin secondary-red($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$rose-100;
  color: colors.$rose-800;
}

@mixin secondary-yellow($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$yellow-100;
  color: colors.$yellow-700;
}

@mixin secondary-blue($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$blue-100;
  color: colors.$blue-800;
}

@mixin secondary-violet($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$violet-50;
  color: colors.$violet-800;
}

@mixin secondary-teal($size: $_default-size) {
  @include _base;
  @include _size($size);
  background-color: colors.$teal-50;
  color: colors.$teal-700;
}
