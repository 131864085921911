@use '../common/colors' as colors;
@use "../common/typography.scss";
@use "../common/elements";

%button {
  border-radius: 50%;
  cursor: pointer;
  padding: 4px 6px 0px 6px;
  box-shadow: 0px 2px 4px rgba(34, 35, 51, 0.3);

  .icon {
    @include elements.icon($size: 20px);
  }
}

.form-select-pax {
  padding: 10px 10px;
  border-radius: 8px;
  border: 1px solid #{colors.$blue-gray-100};
  label {
    margin-left: 0px;
    margin-bottom: 10px;
    color: #{colors.$blue-gray-500};
  }
  .increment-and-decrement-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    color: #{colors.$blue-gray-600};
    > .increment {
      @extend %button;
      background-color: #{colors.$teal-500};
    }

    > .decrement {
      @extend %button;
      background-color: #535a64;
    }
  }
}
