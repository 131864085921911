@use '../common/colors';
@use '../common/constants';
@use '../common/typography';

.loading-widget {
  display: grid;
  gap: 16px;
  padding: 24px 24px 32px;
  border-radius: constants.$border-radius;
  box-shadow: 0px 0px 3px rgba(104, 122, 155, 0.25);
  text-align: center;
  width: 573px;
  box-sizing: border-box;

  > .title {
    color: colors.$blue-gray-900;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  > .message {
    color: colors.$blue-gray-700;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
