@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use '../common/typography' as typography;

.pagination-widget {
  display: grid;
  grid-template-columns: min-content 1fr min-content;

  .button {
    transition: all 0.2s ease-out;
    cursor: pointer;
  }

  .decrement,
  .increment {
    background-color: #{default-theme.$primary};
    color: #{default-theme.$bg};
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 25px;
    display: grid;
    place-items: center;
  }

  .pages {
    display: flex;
    place-content: center;
    grid-gap: 10px;

    .page {
      background-color: #{default-theme.$secondary-bg};
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 25px;
      display: grid;
      place-items: center;

      &.active {
        background-color: #{default-theme.$fg};

        > h5 {
          color: #{default-theme.$bg};
        }
      }

      > h5 {
        color: #{default-theme.$fg}90;
      }
    }
  }
}
