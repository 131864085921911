@use "../common/constants";
@use "../common/typography";
@use "../common/colors";

.order-history-page {
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100vh;

  > .header {
    > .wrapper {
      border-bottom: 0;
    }

    input {
      width: 400px;
      padding: 12px 14px;
      border: 1px solid colors.$blue-gray-100;
      border-radius: 6px;

      text-indent: 32px;
      color: colors.$blue-gray-900;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      background-image: url('../images/icon-search.svg');
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: 16px center;

      outline: none;
    }
  }

  > .body {
    overflow: hidden; // let the child component handle scroll
  }
}
