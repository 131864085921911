@use "../common/default-theme";
@use '../common/typography';
@use "../common/item-styles";
@use "../common/item-option-styles";
@use "../common/colors";

.pizza-widget {
  @include item-styles.item-widget;

  .main-panel {
    @include item-styles.main-panel;

    // List of available pizza sizes
    .sizes {
      .header {
        padding-top: 0;
        .wrapper {
          display: inline-flex;
          grid-gap: 8px;
        }
      }

      .options {
        @include item-option-styles.pizza-options;
      }
    }

    // List of remove pizza toppings
    .remove-toppings {
      @include item-styles.remove-toppings;
      .badge {
        display: none;
      }
    }

    // List of added pizza toppings
    .added-toppings {
      @include item-styles.added-toppings;
    }

    // List of available add toppings
    .add-toppings-list {
      @include item-styles.add-toppings-list;
    }
  }

  .info-panel {
    @include item-styles.info-panel;

    .spec-info {
      @include item-styles.spec-info;
    }

    .summary {
      margin: 12px 0;
      padding-top: 12px;

      &:first-child {
        margin: 20px 0;
      }

      .item-description-view-widget {
        color: colors.$blue-gray-500;
      }

      .empty-summary {
        @include item-styles.empty-info-panel-summary;
      }
    }

    .convert-link {
      display: flex;
      align-items: center;

      &:first-child {
        margin-top: 20px;
      }

      margin-top: 12px;

      cursor: pointer;

      &.deal {
        padding-top: 12px;
        border-top: 1px solid colors.$blue-gray-100;
      }

      img {
        margin-right: 8px;
      }

      color: colors.$teal-500;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
    }
  }
}
