@use "../common/item-option-styles" as item-option-styles;
@use "../common/constants"  as constants;
@use "../common/scrollbar" as scrollbar;
@use "../common/colors" as colors;

.time-of-day-list-widget {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: min-content minmax(100px, max-content);
  overflow: hidden;

  .header {
    display: grid;
    grid-template-columns: 1fr max-content min-content;
    align-items: center;

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: colors.$blue-gray-900;
      margin-bottom: 30px;
    }
  }

  .no-times-of-day {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 21px;

    > button {
      min-width: 20%;
      padding: 13px;
      border-radius: 8px;
      font-size: 17px;
      letter-spacing: 0.5px;
      margin-top: 27px;
    }
  }

  .times-of-day {
    @include item-option-styles.options;
    grid-template-columns: repeat(auto-fit, minmax(136px, 1fr));
    gap: 12px;
    padding: 10px 12px 24px;
    padding-top: 10px;
    max-height: calc(100vh - 300px);

    .option {
      padding: 22px;
      font-weight: 600;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.5px;
    }
  }
}
