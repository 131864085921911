@use "../common/default-theme";
@use '../common/typography';
@use "../common/colors";

.refund-widget {
  padding: 40px 32px 32px;
  border-radius: 20px;
  min-width: 620px;

  .header {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 40px;

    .title {
      @include typography.h1;
    }

    .amount {
      @include typography.h2;
      color: colors.$teal-600;
      margin: 0;

      span {
        color: colors.$blue-gray-500;
        line-height: 24px;
      }
    }
  }
}
