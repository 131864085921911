@use "../common/card" as card;
@use "../common/typography" as typography;
@use "../common/cta" as cta;
@use "../common/colors" as colors;

@mixin start-order-iso-date-picker {
  display: flex;
  flex-direction: column;
  width: 900px;
  @include card.container;
  padding: 30px 25px;
  box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
  border-radius: 20px;
}
