@use '../common/default-theme' as default-theme;

.requesting-uber-delivery-widget {
  width: 750px;
  height: 500px;
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 80px 0;

  >img {
    width: 140px;
  }

  .title {
    color: #{default-theme.$fg};
    font-size: 25px;
    font-weight: 500;
    margin-top: 45px;
  }

  .subtitle {
    color: #{default-theme.$secondary-fg};
    font-weight: 400;
    font-size: 17px;
  }
}