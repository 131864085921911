@use '../common/constants' as constants;
@use '../common/colors' as colors;

.collection-time-schedule-edit-widget {
  > .heading {
    margin: 30px 0;
  }

  .pos-date {
    background-color: #{colors.$white};
    border-radius: constants.$border-radius;
    border: 1px solid #{colors.$blue-gray-100};
    padding: 10px 14px;
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    color: #{colors.$blue-gray-700};
    cursor: pointer;

    > i {
      justify-self: end;
      font-size: 24px;
      opacity: 0.6;
    }

    .name {
      color: #{colors.$blue-gray-800};
    }
  }

  .pos-time {
    background-color: #{colors.$white};
    border-radius: constants.$border-radius;
    border: 1px solid #{colors.$blue-gray-100};
    padding: 10px 14px;
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr;
    color: #{colors.$blue-gray-700};
    cursor: pointer;

    > i {
      justify-self: end;
      font-size: 24px;
      opacity: 0.6;
    }

    .name {
      color: #{colors.$blue-gray-800};
    }
  }
}
