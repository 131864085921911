@use "../common/uber-delivery-styles" as uber-delivery-styles;
@use "../common/cta" as cta;
@use "../common/colors" as colors;

.uber-delivery-request-for-pos-order-failed-widget {
  @include uber-delivery-styles.request-failed-widget;

  .actions {
    .cancel-request-button {
      @include cta.secondary-cta;
      color: #{colors.$rose-500} !important;
      border: 1px solid #{colors.$rose-500};
    }
  }
}
