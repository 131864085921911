@use "../common/cta" as cta;
@use "../common/default-theme" as default-theme;
@use '../common/typography' as typography;

.custom-delivery-charge-widget {
  padding: 80px 170px;

  .header {
    @include typography.h2;
    text-align: center;
  }

  .custom-delivery-fee-box {
    border-radius: 8px;
    border: 1px solid #{default-theme.$secondary-fg};
    display: grid;
    padding: 13px 20px;
    margin-bottom: 22px;
    grid-template-columns: min-content 1fr;

    .sign {
      @include typography.h1;
      padding-top: 10px;
    }

    .custom-delivery-charge-input {
      display: grid;
      align-items: center;

      input {
        outline: none;
        border: none;
        background: none;
        text-align: right;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        line-height: 35px;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .actions {
    display: grid;
    grid-template-areas:
      'skip-clear    confirm'
      'cancel        cancel';
    gap: 10px;
    grid-template-columns: 1fr 1fr;

    .skip-clear {
      grid-area: skip-clear;
      @include cta.link-cta;
      border: 2px solid #{default-theme.$secondary-fg};
    }

    .confirm {
      grid-area: confirm;
    }

    .cancel {
      grid-area: cancel;
      @include cta.link-cta;
    }
  }
}
