@use '../common/colors';
@use '../common/typography';
@use "../common/buttons";

@mixin item {
  @include buttons.secondary;
  @include typography.h4;
  width: 100%;
  border-radius: 8px;
  padding: 12px 14px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0;
  text-align: left;
  background-color: colors.$white;
  box-shadow: 0px 0px 8px -5px rgb(0 0 0 / 30%);
  transition: ease-in-out all 0.3s;
  background-image: url('../images/icon-category.svg');
  background-repeat: no-repeat;
  background-position: 94% center;
  background-size: 8px;
  color: colors.$blue-gray-900;

  &.selected {
    margin-left: 10px;
  }

  .label {
    max-width: 93%;
  }
}
