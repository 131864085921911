@use "../common/payment-summary";
@use "../common/colors";
@use "../common/elements";

.split-equally-payment-summary-widget {
  @include payment-summary.base;

  > .split-equally-payments {
    > .label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: colors.$blue-gray-700;
    }

    > .items {
      @include payment-summary.items;
      background: colors.$blue-gray-50;

      > .split-equally-payment {
        @include payment-summary.payment;
      }
    }
  }
}
