@use "../common/default-theme";
@use "../common/colors";

.weather-info-widget {
  display: grid;
  grid-template-areas:
    'icon temperature'
    'icon location';
  column-gap: 24px;
  row-gap: 4px;
  height: fit-content;

  img {
    grid-area: icon;
  }

  .temperature {
    grid-area: temperature;
    font-size: 25px;
    line-height: 35px;
    font-weight: 500;
    letter-spacing: 0.05em;
    align-self: end;

    span {
      font-weight: 600;
    }
  }

  .location {
    grid-area: location;
    color: colors.$blue-gray-500;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
