@use '../common/typography';
@use '../common/colors';
@use '../common/scrollbar';
@use '../common/default-theme';

@mixin container {
  &.vertical {
    display: grid;
    grid-template-columns: 23vw 1fr;
    height: 100vh;

    .navigation-panel {
      background-color: #{colors.$blue-gray-50};
      overflow-y: auto;
      @include scrollbar.hide;

      > .title {
        @include typography.h1;
        padding: 0 24px;
        display: flex;
        align-items: center;
        height: 72px;
      }

      .tabbed-navigation-tabs-widget {
        padding: 10px;
        display: grid;
        gap: 10px;
        .navigation-tab {
          @include typography.h4;
          margin: 0;
          border-radius: 8px;
          padding: 12px 14px;
          border: 1px solid #{colors.$blue-gray-50};
          cursor: pointer;
          color: #{default-theme.$fg};
          background-color: #{default-theme.$bg};
          box-shadow: 0px 0px 8px -5px rgb(0 0 0 / 30%);
          transition: ease-in-out all 0.3s;
          background-image: url('../images/icon-category.svg');
          background-repeat: no-repeat;
          background-position: 94% center;
          background-size: 8px;
        }
        .navigation-tab.selected {
          background-image: url('../images/icon-category-active.svg');
          color: #{default-theme.$bg};
          background-color: #{default-theme.$fg};
        }
      }
    }

    .tabbed-navigation-view-panel-widget {
      overflow-y: auto;
      @include scrollbar.hide;

      > .header {
        @include typography.h1;
        font-size: 24px;
        margin-bottom: 0;
        padding: 20px 24px;
        border-bottom: 1px solid colors.$blue-gray-100;
      }

      > .content {
        padding: 24px;
      }
    }
  }
}
