@use "../common/item-option-styles" as item-option-styles;
@use "../common/constants" as constants;
@use "../forms/form" as form;
@use "../common/colors" as colors;
@use "../common/typography" as typography;

@mixin selected-day {
  content: '';
  width: 35%;
  height: 52%;
  color: colors.$teal-500;
  border-radius: 8px;
  position: absolute;
  top: 24%;
  left: 32%;
  z-index: -1;
}

.iso-date-range-picker-widget {
  height: 90vh;
  width: 70vw;
  display: grid;
  grid-template-rows: min-content 1fr min-content;

  .header {
    display: grid;
    align-items: center;
    text-align: center;

    .title {
      @include typography.h1;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 0;
      padding: 25px 0 0;
    }
  }

  .default {
    display: block;

    .form-items {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .form-iso-date-range-picker {
    height: inherit;
    width: inherit;

    // DayPicker is the name of the component library
    .DayPicker {
      display: flex;
      padding: 24px;
      height: inherit;
      width: inherit;
      padding-bottom: 18px;

      .DayPicker-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 2px solid #{colors.$blue-gray-100};
        border-radius: 8px;

        .DayPicker-NavButton {
          // NOTE: Nav buttons are absolute-positioned as recommended for this component
          position: absolute;
          content: '';
          height: 24px;
          width: 24px;
          background-size: 100%;
        }

        .DayPicker-NavButton--prev {
          background-image: url('../images/icon-chevron-down-grey.svg');
          transform: rotate(90deg);
          left: 1.5em;
          margin-left: 5%;
        }

        .DayPicker-NavButton--next {
          background-image: url('../images/icon-chevron-down-grey.svg');
          transform: rotate(270deg);
          margin-right: 5%;
        }

        .DayPicker-NavButton--interactionDisabled {
          display: block;
        }

        .DayPicker-Months {
          width: inherit;

          .DayPicker-Month {
            width: inherit;
            margin: 16px 24px;

            .DayPicker-Caption {
              margin-bottom: 1.5em;

              div {
                @include typography.h1;
                text-align: center;
                font-weight: 500;
              }
            }

            .DayPicker-Weekday abbr[title] {
              color: colors.$blue-gray-500;
              font-size: 16px;
            }

            .DayPicker-Day--today {
              color: inherit;
            }

            .DayPicker-Day {
              position: relative;
              z-index: 1;
              cursor: pointer;
            }

            .DayPicker-Day:hover:not(.DayPicker-Day--disabled):not(.DayPicker-Day--from):not(.DayPicker-Day--to) {
              background-color: transparent;

              &::after {
                @include selected-day;
                background-color: colors.$teal-100;
              }
            }

            .DayPicker-Day--selected {
              background-color: transparent;
              color: inherit;

              &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 65%;
                background-color: colors.$teal-50;
                top: 15%;
                left: 0;
                border-top: 2px solid colors.$teal-500;
                border-bottom: 2px solid colors.$teal-500;
                z-index: -1;
              }

              &:first-of-type,
              &.DayPicker-Day--from {
                &::before {
                  border-left: 2px solid colors.$teal-500;
                  border-top-left-radius: 8px;
                  border-bottom-left-radius: 8px;
                }
              }

              &:last-of-type,
              &.DayPicker-Day--to {
                &::before {
                  border-right: 2px solid colors.$teal-500;
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                }
              }
            }

            .DayPicker-Day--from,
            .DayPicker-Day--to {
              color: colors.$white;

              &::after {
                @include selected-day;
                background-color: colors.$teal-600;
              }
            }

            .DayPicker-Day.DayPicker-Day--disabled {
              color: #dce0e0;
            }
          }
        }
      }
    }
  }

  .back-button {
    margin: 0 18px 18px;
    background-color: transparent;
    border-radius: 8px;
    border: 2px solid colors.$blue-gray-300;
    padding: 14px 18px;
  }
}

