@use '../common/scrollbar';
@use '../common/colors';

.uber-delivery-info-list-widget {
  @include scrollbar.hide;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > div {
    border-radius: 8px;
    border: 1px solid colors.$blue-gray-100;
  }

  .no-orders {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 69px;

    .label {
      margin-top: 8px;

      color: colors.$blue-gray-700;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
    }
  }

  .list > div:last-child {
    border-bottom: none;
  }
}
