@use "../common/cta";
@use '../common/typography';
@use "../common/colors";

.partial-refund-widget {
  padding: 32px;

  .header {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 40px;
  }

  .input-container {
    border-radius: 8px;
    border: 1px solid colors.$blue-gray-100;
    display: grid;
    padding: 13px 20px;
    grid-template-columns: min-content 1fr;
    width: 780px;

    &.error {
      border-color: colors.$rose-800;
    }

    .sign {
      @include typography.h1;
      padding-top: 10px;
    }

    .partial-refund-input {
      display: grid;
      align-items: center;

      input {
        outline: none;
        border: none;
        background: none;
        text-align: right;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        line-height: 35px;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .invalid-amount-label {
    text-align: right;
    margin-top: 10px;
    color: colors.$rose-800;
  }

  .actions {
    display: grid;
    grid-auto-flow: column;
    padding: 0 20px;
    margin-top: 40px;
    gap: 15px;

    .cancel-button {
      background-color: transparent;
      border: 1px solid var(--blue-grey-500-secondary, #7D7E92);
    }
  }
}
