@use "../common/typography" as typography;
@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;
@use '../common/constants' as constants;
@use '../common/item-option-styles' as item-option-styles;
@use '../common/pill' as pill;

.estimated-prep-complete-iso-date-time-selector-widget {
  display: grid;
  grid-template-areas:
    'header       header      header'
    'body         body        body'
    'actions      actions     actions';
  grid-template-rows: min-content 1fr min-content;
  gap: 15px 0;
  padding: 24px 10px;
  width: 750px;
  height: 500px;

  .header {
    grid-area: header;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr min-content;
    margin: 0 15px;
    align-items: center;

    .requested-for-and-date-time-container {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-left: 36px; // this is to account for the close button on the right

      .requested-for-label {
        width: fit-content;
        display: grid;
        align-items: center;
        justify-self: center;
        font-weight: 500;
        font-size: 16px;

        &.asap {
          @include pill.secondary-red(large);
        }

        &.today {
          @include pill.secondary-red(large);
        }

        &.future-order {
          @include pill.secondary-green(large);
        }
      }

      .date-and-time-label {
        @include typography.h1;
        display: grid;
        grid-auto-flow: column;
        gap: 6px;
        font-weight: 400;
        text-align: center;
        margin: 0;
        margin-top: 10px;
        font-weight: 700;

        > span {
          font-weight: 100;
        }
      }
    }
  }

  .body {
    grid-area: body;
    display: grid;
    height: 100%;
    align-self: center;
    padding: 0 15px;
    overflow: hidden auto;
  }

  .subtitle {
    @include typography.h1;
    font-weight: 500;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    border-top: 1px solid #{default-theme.$border-light};
  }

  .empty-label {
    padding: 60px 0;
    text-align: center;
  }

  .initial-options-for-non-asap-order {
    align-self: center;
    display: grid;
    gap: 30px;

    > .option {
      display: grid;
      gap: 10px;
      align-items: center;
      grid-template-columns: 1fr 100px;
      padding: 25px 13px;
      color: #{default-theme.$fg};
      border-radius: constants.$border-radius;
      border: 1px solid #{default-theme.$border-light};
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);

      .label {
        @include typography.h3;
        font-weight: 500;
        display: grid;
        align-items: center;
        gap: 5px;
      }

      > button {
        > span {
          font-size: 14px !important;
        }
      }
    }

    .recommended-time-of-day {
      border: 1px solid #{default-theme.$primary};

      > .label {
        grid-auto-flow: column;
        grid-template-columns: repeat(3, max-content) 1fr;

        .icon {
          margin-right: 3px;
        }

        .recommended-pick-up-time-label {
          font-weight: 700;
        }

        .recommended-label {
          text-align: end;
          font-size: 14px;
          color: #{default-theme.$primary};
          margin-left: 10px;
        }
      }
    }
  }

  .prep-time-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    padding: 10px 5px;
    overflow: auto;

    .prep-time {
      @include typography.h3;
      color: #{default-theme.$fg};
      position: relative;
      background: #{default-theme.$modifier-gradient-bg};
      box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
      padding: 25px 35px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      -webkit-tap-highlight-color: transparent;

      &.selected {
        @include item-option-styles.top-check();
        background: #{default-theme.$modifier-gradient-active-bg};
        color: #{default-theme.$bg};
      }
    }
  }

  .actions {
    grid-area: actions;
    margin: 0 14px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 20px;
    height: 50px;

    .back-button {
      @include typography.h3;
      flex: 1;
      background-color: #{colors.$white};
      color: #{colors.$blue-gray-500};
      border: 1px solid #{colors.$blue-gray-300};
      border-radius: 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .confirm-button {
      @include typography.h3;
      flex: 1;
      background-color: #{colors.$teal-600};
      border-radius: 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
