@use "./type-select-widget" as type-select;

.split-type-select-widget {
  @include type-select.type-select-widget;

  .split-types {
    @include type-select.types;
    grid-auto-flow: column;
  }

  .split-type {
    @include type-select.type;
    justify-content: center;
    white-space: nowrap;

    > .icon {
      height: 48px;
    }
  }
}