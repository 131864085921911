@use '../common/colors' as colors;

.pos-terminal-sales-report-widget {
  .filters {
    display: grid;
    grid-template-columns: 280px max-content;
    gap: 0 64px;
    grid-template-areas:
      'date-range-toggle   pos-terminal-filter'
      'date-range-picker   date-range-picker';
    .pos-report-date-range-filter-widget {
      .toggle {
        grid-area: date-range-toggle;
      }
      .date-range-picker-widget {
        grid-area: date-range-picker;
      }
    }

    .form-item {
      grid-area: pos-terminal-filter;
      display: grid;
      grid-template-rows: min-content min-content;
      height: min-content;
      label {
        color: #{colors.$blue-gray-600};
        margin-bottom: 8px;
        font-size: 13px;
      }
      .pos-terminal-filter {
        width: 250px;
      }
      .options {
        width: 250px;
      }
    }
  }
}
