@use "../common/default-theme";
@use "../common/cta";
@use '../common/typography';
@use "../common/item-styles";
@use "../common/colors";

@mixin deal-pizza-details {
  cursor: pointer;

  .header {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr min-content 50%;
    align-items: center;
    grid-gap: 15px;

    .name {
      margin: 0;
    }

    > .extra-charge {
      white-space: nowrap;
      color: #{default-theme.$primary};
    }

    > .edit-icon {
      place-self: end;
      height: 100%;
      color: #{default-theme.$secondary-fg};
    }
  }

  .toppings {
    position: relative;
    margin-top: 10px;

    &::before {
      content: ' ';
      display: block;
      background: #{default-theme.$border-light};
      height: 1px;
      width: calc(50% - 15px);
      position: absolute;
      top: -5px;
    }

    .remove {
      display: grid;
      grid-auto-flow: column;
      width: calc(50% - 30px);
      grid-template-columns: 40% 60%;
      grid-gap: 15px;

      .title {
        color: #{default-theme.$secondary-fg};
      }
    }

    .add {
      display: grid;
      grid-auto-flow: column;
      width: calc(50% - 30px);
      grid-template-columns: 40% 60%;
      grid-gap: 15px;
      margin-top: 10px;

      .title {
        color: #{default-theme.$secondary-fg};
      }

      .value {
        .topping {
          display: grid;
          grid-template-columns: 1fr min-content;
          grid-gap: 15px;

          > .extra-charge {
            white-space: nowrap;
            color: #{default-theme.$primary};
          }
        }
      }
    }
  }
}

.deal-widget {
  @include item-styles.item-widget;

  .main-panel {
    @include item-styles.main-panel;

    .deal {
      .deal-pizzas {
        > .deal-pizza {
          border-bottom: 1px solid #{default-theme.$border-light};
          padding-bottom: 10px;
          margin-bottom: 20px;

          .header {
            display: grid;
            grid-template-columns: max-content 1fr max-content;
            gap: 10px;
            align-items: center;

            > .title {
              @include typography.h3;
            }

            > .badge {
              color: #{default-theme.$form-element-error};

              &.selected {
                color: #{default-theme.$secondary-fg};
              }
            }

            .half-and-half-toggle {
              @include item-styles.toggle;
            }
          }

          > .body {
            .pizza {
              .add-pizza-styles {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 40px;

                .selected-pizza {
                  @include item-styles.selected-pizza;
                  margin-bottom: 15px;
                  padding: 0;
                  .header > .half-number {
                    margin-top: 22.07px;
                  }

                  .pizza {
                    > .selected {
                      .name {
                        @include typography.h3;
                      }
                    }
                  }

                  .pizza-customization {
                    .remove-toppings {
                      margin-bottom: 20px;

                      .header {
                        padding: 0;

                        .badge {
                          display: none;
                        }
                      }

                      .options {
                        padding: 10px 0;
                      }
                    }

                    .added-toppings {
                      .header {
                        padding: 0;

                        .badge {
                          display: none;
                        }
                      }

                      .options {
                        padding: 0;
                        padding-top: 10px;
                      }
                    }
                  }
                }
              }

              .add-pizza {
                @extend .add-pizza-styles;
                grid-template-columns: 1fr;

                .selected-pizza {
                  .pizza {
                    width: calc(50% - 20px);
                  }
                }
              }

              .add-half-and-half-pizza {
                @extend .add-pizza-styles;
              }
            }
          }
        }
      }

      .modifiers > .modifier .header .wrapper {
        display: inline-flex;
        grid-gap: 8px;
        * {
          font-weight: 400;
        }
        .title {
          color: #{default-theme.$fg};
        }
      }

      .deal-items {
        > .deal-item {
          border-bottom: 1px solid #{default-theme.$border-light};
          padding-bottom: 10px;
          margin-bottom: 20px;

          > .header {
            padding-bottom: 12px;
          }
          > .modifiers {
            .modifier {
              .header {
                padding: 12px 0;

                > .title {
                  @include typography.h5;
                  color: #{default-theme.$secondary-fg};
                  text-transform: uppercase;
                }
              }

              > .options {
                padding: 12px 0;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                grid-gap: 15px;

                .option {
                  margin: 0;
                  grid-template-columns: unset;
                  grid-template-areas: unset;
                  display: flex;
                  flex-flow: column;
                  justify-content: center;
                  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
                  min-height: 80px;
                  color: #{default-theme.$fg};

                  &.selected {
                    background: #{default-theme.$modifier-gradient-active-bg};
                    box-shadow: none;
                    color: #{default-theme.$secondary-bg};

                    .extra-charge {
                      color: #{default-theme.$restoplus-white-transluscent};
                    }
                  }

                  .clickable {
                    margin-right: 0;
                    padding-right: 15px;
                    padding-bottom: 0;
                    display: block;
                    text-align: center;

                    .container {
                      display: none;
                    }

                    .name {
                      white-space: unset;
                    }
                  }

                  .extra-charge {
                    padding: 15px;
                    padding-top: 4px;
                    display: block;
                    text-align: center;
                    color: #{default-theme.$secondary-fg};
                  }

                  .quantity {
                    padding: 10px 0;
                    border-top: 1px solid #{default-theme.$border-light};
                    justify-content: center;
                    width: 100%;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      .deal-modifiers {
        > .modifiers {
          border-bottom: 1px solid #{default-theme.$border-light};
          padding-bottom: 10px;
          margin-bottom: 20px;

          .modifier {
            .header {
              padding: 0;
              padding-bottom: 12px;
            }

            .options {
              padding: 20px 0;
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
              grid-gap: 15px;

              .option {
                margin: 0;
                grid-template-columns: unset;
                grid-template-areas: unset;
                display: flex;
                flex-flow: column;
                justify-content: center;
                box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
                min-height: 80px;
                color: #{default-theme.$fg};

                &.selected {
                  background: #{default-theme.$modifier-gradient-active-bg};
                  box-shadow: none;
                  color: #{default-theme.$secondary-bg};
                  .extra-charge {
                    color: #{default-theme.$restoplus-white-transluscent};
                  }
                }

                .clickable {
                  margin-right: 0;
                  padding-right: 15px;
                  padding-bottom: 0;
                  display: block;
                  text-align: center;

                  .container {
                    display: none;
                  }

                  .name {
                    white-space: unset;
                  }
                }

                .extra-charge {
                  padding: 15px;
                  padding-top: 4px;
                  display: block;
                  text-align: center;
                  color: #{default-theme.$secondary-fg};
                }

                .quantity {
                  padding: 10px 0;
                  border-top: 1px solid #{default-theme.$border-light};
                  justify-content: center;
                  width: 100%;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }

    .deal-pizza-details {
      > .header {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr repeat(2, min-content);
        grid-gap: 10px;
        align-items: center;
        margin-bottom: 30px;

        .half-and-half-toggle {
          @include item-styles.toggle;
        }

        .done-btn {
          @include cta.primary-cta;
          padding: 10px;
          width: 120px;
          margin-left: 30px;
          font-weight: 400;
          border-radius: 8px;
        }
      }

      .body {
        .half-selector {
          @include item-styles.half-selector;
        }

        .selected-deal-pizza {
          > .selected-pizza {
            @include item-styles.selected-pizza;
          }
        }
      }
    }

    .pizza-list {
      @include item-styles.pizza-list;
    }

    .modifier {
      .header {
        align-items: center;
      }
    }

    .remove-toppings {
      @include item-styles.remove-toppings;
    }

    .added-toppings {
      @include item-styles.added-toppings;
    }

    .add-toppings-list {
      @include item-styles.add-toppings-list;
    }
  }

  .info-panel {
    @include item-styles.info-panel;

    .spec-info {
      @include item-styles.spec-info;
    }

    .item-description-view-widget {
      color: colors.$blue-gray-500;
      padding: 12px 0;

      .title {
        &:first-child {
          border-top: none;
          padding-top: 0;
        }

        border-top: 1px solid colors.$blue-gray-100;
        padding-top: 12px;
      }
    }

    .summary {
      padding-top: 0;
      .empty-summary {
        @include item-styles.empty-info-panel-summary;
      }
    }
  }
}
