@use "../common/typography" as typography;
@use "../common/default-theme"  as default-theme;
@use "../common/cta"  as cta;
@use "../common/constants"  as constants;
@use "../common/colors"  as colors;
@use "../common/pill"  as pill;

.uber-delivery-failed-view-widget {
  box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.15);
  border-radius: constants.$border-radius;

  .header {
    display: flex;
    padding: 28px 24px;
    align-items: center;
    background-color: #{colors.$rose-500};
    border-radius: 10px 10px 0px 0px;
    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      padding-left: 12px;
      color: #{colors.$white};
    }
  }
  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    padding: 16px;
    border-bottom: 1px solid #{colors.$blue-gray-100};
  }

  .actions {
    display: flex;
    .label-actions {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.0025em;
    }
    .refund-order {
      padding: 16px 50px 16px 16px;
      border-right: 1px solid #{colors.$blue-gray-100};
      .refund-order-button {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        background-color: #{colors.$rose-800};
        color: #{colors.$white};
        border: 1px solid #{colors.$rose-800};
        padding: 9.5px 24px;
        border-radius: 4px;
        cursor: pointer;
        transition: ease-in-out all 0.2s;
      }
    }
    .complete-order {
      padding: 16px;
      .complete-order-button {
        @include cta.secondary-cta;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 9.5px 24px;
        border-radius: 4px;
      }
    }
  }
}

.confirm-refund {
  .swal2-header {
    padding: 9px 24px 12px !important;
  }

  .swal2-content {
    padding: 0 24px !important;
  }

  .swal2-title {
    margin: 0 !important;
    color: colors.$blue-gray-900 !important;
  }

  .swal2-html-container {
    color: colors.$blue-gray-700 !important;
  }

  .swal2-footer {
    padding: 24px 24px 9px !important;
  }

  .info {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 6px;
    color: colors.$blue-gray-800 !important;

    .text {
      text-align: center;
    }
  }

  .swal2-actions {
    padding: 24px 24px 0 !important;

    button {
      text-transform: none !important;
    }

    .confirm-button {
      border-radius: 4px !important;
      background: #{colors.$rose-800} !important;
      color: #{colors.$white};
    }

    .cancel-button {
      border-radius: 4px;
      border: 1px solid #{colors.$blue-gray-500} !important;
      color: #{colors.$blue-gray-500} !important;
    }
  }
}

.mark-completed {
  .swal2-title {
    margin: 0 !important;
    color: colors.$blue-gray-900 !important;
  }

  .swal2-header {
    padding: 24px !important;
    padding-top: 9px !important;
  }

  .swal2-actions {
    padding: 24px !important;
    padding-top: 0 !important;
    padding-bottom: 9px !important;

    button {
      text-transform: none !important;
      border-radius: 4px !important;
    }

    .confirm-button {
      background-color: #{colors.$teal-600} !important;
    }

    .cancel-button {
      border: 1px solid #{colors.$blue-gray-500} !important;
      color: #{colors.$blue-gray-500} !important;
    }
  }
}
