.item-sales-report-widget {
  .filters {
    display: grid;
    grid-template-columns: 336px max-content;
    gap: 0 64px;
    grid-template-areas:
      'date-range-toggle   order-source-toggle'
      'date-range-picker   date-range-picker';
    .pos-report-date-range-filter-widget {
      .toggle {
        grid-area: date-range-toggle;
      }
      .date-range-picker-widget {
        grid-area: date-range-picker;
      }
    }

    .order-source-filter-selector-widget {
      grid-area: order-source-toggle;
    }
  }

  .item-sales-overview {
    display: grid;
    gap: 24px 0;
  }
}
