@use '../common/colors' as colors;

.loyalty-card-rewards-count-widget {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 !important;

  .count {
    background-color: colors.$blue-500;
    position: absolute;
    height: inherit;
    z-index: 99;
    border-radius: 8px 0 0 8px;
    transition: width 750ms linear;

    &.full {
      border-radius: 8px;
      animation: emphasize 2s infinite ease-in-out;
    }

    @keyframes emphasize {
      0% {
        background-color: colors.$blue-500;
      }

      50% {
        background-color: colors.$teal-600;
        border: 2px solid colors.$white;
      }

      0% {
        background-color: colors.$blue-500;
      }
    }
  }

  .info {
    position: relative;
    display: flex;
    color: colors.$white;
    align-items: center;
    padding: 10px 15px;
    z-index: 100;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;

    .label {
      margin-left: 10px;
    }
  }
}