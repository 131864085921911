@use '../common/typography' as typography;

.pos-category-colors-list-widget {
  form {
    .form-items {
      grid-template-columns: repeat(auto-fill, minmax(251px, 1fr));
      gap: 14px;
    }
  }

  > .no-categories {
    @include typography.h3;
    text-align: center;
  }
}
