@use '../common/order-history-list-item.scss';
@use '../common/colors';
@use '../common/elements';
@use '../common/ghost';

// 'CUSTOMER' 'COLLECTION TYPE' 'TOTAL' 'ORDER STATUS' 'PAYMENT STATUS' 'DOCKET #' 'TIME' 'ACTION'
$grid-template-columns: 1fr 1fr 0.5fr 1.25fr 1.5fr 0.5fr 1fr 0.35fr;

.google.order-history-list-item-widget {
  @include order-history-list-item.container;
  @include order-history-list-item.content;

  .orders-list-item-order-status-widget {
    &.to-be-confirmed {
      @include ghost.yellow;
    }

    &.accepted,
    &.in-preparation,
    &.ready-for-pickup,
    &.in-transit,
    &.completed {
      @include ghost.green;
    }

    &.cancelled,
    &.rejected {
      @include ghost.red;
    }
  }
}
