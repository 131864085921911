@use "../common/default-theme";
@use "../common/typography";
@use "../common/cta";
@use "../common/constants";
@use '../common/colors';

.refund-payment-type-select-widget {
  width: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    @include typography.h1;
  }

  .payment-types {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    gap: 20px;

    .payment-type {
      @include typography.h2;
      text-align: center;
      margin: auto;
      border-radius: constants.$border-radius;
      cursor: pointer;
      display: grid;
      grid-gap: 30px;
      padding: 42px 0;
      min-width: 265px;
      min-height: 177px;
      color: colors.$blue-gray-900;
      font-weight: 400;
      line-height: 27px;
      box-shadow: 0px 0px 24px 0px #00000014;
      border: 1px solid colors.$blue-gray-100;

      &:hover {
        border: 2px solid #{default-theme.$border};
        background: colors.$modifier-gradient-bg;
      }

      > .icon {
        max-width: 38px;
        place-self: center;
      }
    }
  }

  .max-refund-amount {
    @include typography.h5;
    margin-top: 8px;
    font-weight: 400;
    line-height: 21px;
    color: colors.$blue-gray-700;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    text-align: center;

    span {
      font-weight: 600;

      &.invalid {
        color: colors.$rose-800;
      }
    }
  }

  .actions {
    margin-top: 40px;
    display: grid;
    gap: 30px;
    padding: 0 24px;

    button {
      &.back-button {
        @include cta.link-cta;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
        border-radius: constants.$border-radius-small;
      }
    }
  }
}
