@use '../common/default-theme' as default-theme;
@use "../common/cta" as cta;
@use '../common/buttons' as buttons;
@use '../common/typography' as typography;
@use '../common/actions' as actions;
@use "../common/constants" as constants;
@use "../common/colors" as colors;


.custom-item-widget {
  width: 430px;
  margin: 60px 160px;

  .title {
    @include typography.h1;
    font-weight: bold;
    text-align: center;
  }

  .custom-item-price-input {
    .label {
      @include typography.h4;
      margin: 0 4px;
    }
  
    .custom-item-price-input-box {
      display: grid;
      grid-template-columns: min-content 1fr;
      border-radius: 8px;
      border: 1px solid #{default-theme.$form-element-border};
      padding: 12.75px 20px;
  
      .custom-item-price-icon {
        @include typography.h1;
        padding-top: 10px;
      }
  
      .custom-item-price-value {
        display: grid;
        align-items: center;
  
        input {
          outline: none;
          border: none;
          text-align: right;
          font-size: 25px;
          overflow: hidden;
          padding: 0;
          width: 100%;
        }
      }

      &.invalid {
        border: 1px solid #{default-theme.$form-element-error};
      }
    }
  }

  .actions {
    padding-top: 20px;

    > .quantity {
      @include actions.quantity;
      display: grid;
      grid-template-columns: 1fr repeat(3, min-content);
      background-color: #{colors.$white};
      padding: 8px 15px 8px 14px;
      margin-bottom: 15px;
      border-radius: constants.$border-radius;
      align-items: center;

      > h4 {
        margin: 0;
        color: #{default-theme.$secondary-fg};
      }

      .btn {
        width: 28px;
        height: 28px;

        i {
          display: flex;
          flex-flow: column;
          justify-content: center;
        }
      }

      .number {
        font-size: 15px;
        line-height: 21px;
      }
    }

    .add-to-cart {
      @include cta.primary-cta;
      margin-bottom: 10px;
      padding: 14px;
      letter-spacing: 0;
      width: 100%;
      font-size: 17px;
      line-height: 23px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.1px;
    }
  }

  .cancel-btn {
    @include cta.link-cta;
    width: 100%;
    padding: 11px;
  }

  /*--- Disable error message on invalid form ---*/
  .error {
    display: none;
  }

  /*--- Override form button margin ---*/
  .form-buttons {
    margin-top: 0;
  }

  /*--- Hide number-type input spin box ---*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  /*--------------------------------------*/
}
