@use '../common/colors' as colors;
@use '../common/cta' as cta;

.uber-delivery-cancel-payment-confirmation-widget {
  width: 750px;
  height: 500px;
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 35px;

  > img {
    width: 82px;
    margin-top: 95px;
  }

  .title {
    color: #{colors.$blue-gray-900};
    font-size: 24px;
    font-weight: 500;
    margin-top: 40px;
  }

  .subtitle {
    color: #{colors.$blue-gray-500};
    font-weight: 400;
    font-size: 18px;
    display: grid;
    grid-auto-flow: column;
    gap: 6px;
    margin-top: 20px;
  }

  .actions {
    margin-top: 20px;
    display: grid;
    gap: 15px;
    width: 100%;

    > button {
      padding: 14px !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      border-radius: 5px !important;

      &.go-back-button {
        @include cta.primary-cta;
      }

      &.cancel-payment-button {
        @include cta.tertiary-cta;
        color: #{colors.$rose-500};
        border-color: #{colors.$rose-500};
      }
    }
  }
}
