@use '../common/card' as card;
@use '../common/colors' as colors;
@use '../common/typography' as typography;
@use '../common/constants' as constants;
@use '../common/default-theme' as default-theme;
@use '../common/scrollbar' as scrollbar;
@use '../common/buttons' as buttons;

.sales-report-widget {
  display: grid;
  height: 100vh;
  grid-template-columns: 23vw 1fr;
  color: #{default-theme.$fg};

  .navigation-panel {
    padding: 20px 10px;
    padding-right: 25px;
    background-color: #{colors.$blue-gray-50};
    overflow-y: auto;
    @include scrollbar.hide;
    .header {
      @include typography.h1;
      margin-bottom: 20px;
    }
    .link {
      margin: unset;
      padding: unset;
      display: grid;
      .icon {
        display: none;
      }
      .label {
        all: unset;
        @include typography.h4;
        border-radius: 8px;
        padding: 12px 14px;
        margin-bottom: 10px;
        border: 1px solid #{colors.$blue-gray-50};
        cursor: pointer;
        color: #{default-theme.$fg};
        background-color: #{default-theme.$bg};
        box-shadow: 0px 0px 8px -5px rgb(0 0 0 / 30%);
        transition: ease-in-out all 0.3s;
        background-image: url('../images/icon-category.svg');
        background-repeat: no-repeat;
        background-position: 94% center;
        background-size: 8px;

        &.selected {
          background-image: url('../images/icon-category-active.svg');
          color: #{default-theme.$bg};
          background-color: #{default-theme.$fg};
        }
      }
    }
  }

  .main-panel {
    overflow-y: auto;
    @include scrollbar.hide;
  }
}
