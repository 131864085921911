.pos-layout {
  height: 100vh;
  width: 100vw;

  > main {
    margin-left: 70px;
    height: inherit;
  }

  .cannot-access {
    margin-top: 0;
    padding: 20px;
    margin-left: 84px;
  }
}
