@use '../common/modal' as modal;
@use '../common/typography' as typography;
@use '../common/colors' as colors;
@use '../common/scrollbar' as scrollbar;
@use '../common/switch' as switch;
@use '../common/drop-down-input' as drop-down-input;
@use '../common/text-input' as text-input;
@use '../forms/form' as form;

.print-station-edit-widget {
  width: auto;
  max-height: calc(100vh - 56px);
  padding: 40px 32px;
  overflow: auto;
  @include scrollbar.hide;

  .header {
    @include modal.header;
  }

  form {
    .form-group-container {
      @include form.form-group-container;
    }

    .form-item-container {
      @include form.form-item-container;
    }

    .text-input {
      @include text-input.form-text-input;
    }

    .drop-down-input {
      @include drop-down-input.form-drop-down-input;

      .empty {
        color: colors.$blue-gray-400;
      }
    }

    .switch {
      @include switch.form-switch;
    }

    .form-buttons {
      @include form.form-buttons;
    }
  }
}
