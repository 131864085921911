@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;
@use "../common/cta" as cta;

.pos-customers.list-widget {
  .pos-customers.list {
    margin: 20px 30px;

    .item {
      border-radius: constants.$border-radius;
      background: #{default-theme.$modifier-gradient-bg};
      box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
      padding: 10px 0;
      margin-bottom: 15px;
    }
  }

  button.more {
    @include cta.secondary-cta;
    margin: 20px auto;
    padding: 15px 70px;

    &:focus {
      border: 1px solid #{default-theme.$primary};
    }
  }
}
