@use '../common/scrollbar' as scrollbar;

.pos-more-page {
  height: inherit;

  > .header {
    margin: 0 !important;
  }

  > .body {
    height: inherit;
    padding: 24px;
    @include scrollbar.hide;
    overflow: scroll;
  }
}
