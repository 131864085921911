@use '../common/card' as card;
@use '../common/colors' as colors;
@use '../common/typography' as typography;
@use '../common/buttons' as buttons;

.store-sales-report-breakdown-widget {
  > .header {
    @include typography.h1;
    border-bottom: 1px solid #{colors.$light-gray-100};
    display: grid;
    grid-template-columns: 1fr min-content;
    padding: 15px 24px;
    align-items: center;

    button {
      @include buttons.primary;
    }
  }

  .body {
    padding: 24px;

    .filters {
      padding-bottom: 24px;
    }

    .no-report {
      margin: 56px auto;
      text-align: center;
      color: #{colors.$blue-gray-700};
      opacity: 0.5;
    }

    .report {
      .total-sales,
      .total-sales ~ div {
        @include card.container;
        display: grid;
        gap: 24px;
        padding: 24px;
        background: #{colors.$modifier-gradient-bg};
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
        margin-bottom: 24px;

        .label {
          color: #{colors.$blue-gray-600};
        }
      }

      .total-sales .value {
        @include typography.h1;
        font-size: 32px;
      }

      .total-sales ~ div {
        .key-value-pair {
          grid-template-columns: 200px min-content;
          gap: 32px;
        }
        .header {
          @include typography.h2;
          border-bottom: #{colors.$blue-gray-100} 1px solid;
          padding-bottom: 24px;
          margin-bottom: 0;
        }
      }
    }
  }
}
