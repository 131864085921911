@use '../common/colors' as colors;
@use '../common/cta' as cta;

.loyalty-credits-redeemable-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .header {
    display: grid;
    justify-items: center;
    gap: 8px;
    line-height: 24px;
    margin-top: 24px;
    
    .title {
      font-weight: 500;
      font-size: 20px;
      color: colors.$blue-gray-900;
    }

    .subtitle {
      font-weight: 400;
      font-size: 16px;
      color: colors.$blue-gray-500;
    }
  }

  .card {
    margin: 24px 60px 40px;

    .loyalty-card-widget {
      box-shadow: 0px 0px 12px 3px #00000052;
    }

    .loyalty-card-widget::before {
      content: '';
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      // background-image: linear-gradient(60deg, #fff0 30%, #fffc, #fff0 70%);
      animation: shine 3s infinite ease-in-out;
    }
    
    @keyframes shine {
      0% {
        background-image: linear-gradient(60deg, #fff0 50%, #ffffffd1, #ffffff5e 150%);
        opacity: 5%;
      }
      10% {
        background-image: linear-gradient(60deg, #fff0 50%, #ffffffd1, #ffffff5e 150%);
        opacity: 30%;
      }
      20% {
        background-image: linear-gradient(60deg, #fff0 50%, #ffffffd1, #ffffff5e 150%);
        opacity: 5%;
      }
      30% {
        background-image: linear-gradient(60deg, #fff0 30%, #fffc, #fff0 70%);
        opacity: 5%;
      }
      40% {
        background-image: linear-gradient(60deg, #fff0 30%, #fffc, #fff0 70%);
        opacity: 30%;
      }
      50% {
        background-image: linear-gradient(60deg, #fff0 30%, #fffc, #fff0 70%);
        opacity: 5%;
      }
      60% {
        background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #ffffff00 80%);
        opacity: 5%;
      }
      70% {
        background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #ffffff00 80%);
        opacity: 30%;
      }
      80% {
        background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #ffffff00 80%);
        opacity: 5%;
      }
      100% {
        background-image: linear-gradient(60deg, #fffffff2 10%, #fff0, #ffffff00 80%);
        opacity: 5%;
      }
    }
  }

  .actions {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding: 0 24px;

    .redeem-now-button {
      @include cta.primary-cta;
      border-radius: 4px;
      padding: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .maybe-later-button {
      @include cta.link-cta;
      border-radius: 4px;
      border: 1px solid colors.$blue-gray-500;
      color: colors.$blue-gray-900;
      padding: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

  }
}