@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;

$popout-arrow-size: 12px;

%arrow {
  content: '';
  position: absolute;
}

%arrow-up {
  @extend %arrow;
  top: -#{$popout-arrow-size};
  border-left: #{$popout-arrow-size} solid transparent;
  border-right: #{$popout-arrow-size} solid transparent;

  border-bottom: #{$popout-arrow-size} solid default-theme.$bg;
}

%arrow-left {
  @extend %arrow;
  left: -#{$popout-arrow-size};
  border-top: #{$popout-arrow-size} solid transparent;
  border-bottom: #{$popout-arrow-size} solid transparent;

  border-right: #{$popout-arrow-size} solid default-theme.$bg;
}

%arrow-down {
  @extend %arrow;
  bottom: -#{$popout-arrow-size};
  border-left: #{$popout-arrow-size} solid transparent;
  border-right: #{$popout-arrow-size} solid transparent;

  border-top: #{$popout-arrow-size} solid default-theme.$bg;
}

%arrow-right {
  @extend %arrow;
  right: -#{$popout-arrow-size};
  border-top: #{$popout-arrow-size} solid transparent;
  border-bottom: #{$popout-arrow-size} solid transparent;

  border-left: #{$popout-arrow-size} solid default-theme.$bg;
}

%position-vertically-centered {
  top: 50%;
  transform: translateY(-50%);
}

%position-horizontally-centered {
  left: 50%;
  transform: translateX(-50%);
}

%position-top-most {
  top: #{$popout-arrow-size};
}

%position-bottom-most {
  bottom: #{$popout-arrow-size};
}

%position-left-most {
  left: #{$popout-arrow-size};
}

%position-right-most {
  right: #{$popout-arrow-size};
}

.popout {
  position: absolute;
  z-index: 99;
  padding: 16px;
  border-radius: constants.$border-radius;
  background-color: default-theme.$bg;
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);

  &.arrow-down {
    &-left {
      margin-top: -#{$popout-arrow-size};

      &::before {
        @extend %arrow-down;
        @extend %position-left-most;
      }
    }
    &-center {
      margin-top: -#{$popout-arrow-size};

      &::before {
        @extend %arrow-down;
        @extend %position-horizontally-centered;
      }
    }

    &-right {
      margin-top: -#{$popout-arrow-size};

      &::before {
        @extend %arrow-down;
        @extend %position-right-most;
      }
    }
  }

  &.arrow-left {
    &-top {
      margin-left: #{$popout-arrow-size};

      &::before {
        @extend %arrow-left;
        @extend %position-top-most;
      }
    }

    &-center {
      margin-left: #{$popout-arrow-size};

      &::before {
        @extend %arrow-left;
        @extend %position-vertically-centered;
      }
    }

    &-bottom {
      margin-left: #{$popout-arrow-size};

      &::before {
        @extend %arrow-left;
        @extend %position-bottom-most;
      }
    }
  }

  &.arrow-up {
    &-left {
      margin-top: #{$popout-arrow-size};

      &::before {
        @extend %arrow-up;
        @extend %position-left-most;
      }
    }

    &-center {
      margin-top: #{$popout-arrow-size};

      &::before {
        @extend %arrow-up;
        @extend %position-horizontally-centered;
      }
    }

    &-right {
      margin-top: #{$popout-arrow-size};

      &::before {
        @extend %arrow-up;
        @extend %position-right-most;
      }
    }
  }

  &.arrow-right {
    &-top {
      margin-left: -#{$popout-arrow-size};

      &::before {
        @extend %arrow-right;
        @extend %position-top-most;
      }
    }

    &-center {
      margin-left: -#{$popout-arrow-size};

      &::before {
        @extend %arrow-right;
        @extend %position-vertically-centered;
      }
    }

    &-bottom {
      margin-left: -#{$popout-arrow-size};

      &::before {
        @extend %arrow-right;
        @extend %position-bottom-most;
      }
    }
  }
}
