@use "../common/typography" as typography;
@use "../common/default-theme"as default-theme;
@use "../common/colors" as colors;
@use "../common/constants"as constants;

.customer-algolia-index-record-list-widget {
  grid-area: suggestions;
  display: grid;
  grid-template-areas:
    'label       show-suggestions-button'
    'list        list'
    'no-results  no-results';
  background-color: #{default-theme.$form-element-bg};
  border-radius: constants.$border-radius;
  border: 1px solid #{default-theme.$form-element-border};
  padding: 12px;
  margin: 5px 0;

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: colors.$blue-gray-500;
    font-weight: 400;
    margin-bottom: 0;
    margin-left: 4px;
    grid-area: label;
  }

  .show-suggestions-button {
    grid-area: show-suggestions-button;
    @include typography.h5;
    font-weight: 500;
    text-align: end;
    margin-right: 4px;
    cursor: pointer;
    color: colors.$teal-500;
    -webkit-tap-highlight-color: transparent;
  }

  .list {
    grid-area: list;

    .ais-SearchBox,
    .ais-Pagination {
      display: none;
    }

    .ais-Hits-list {
      list-style-type: none;
    }

    .spinner-container {
      height: unset;
    }

    .no-results {
      text-align: center;
      padding: 20px 0;
    }
  }

  .suggested-customer-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 10px;
    overflow-y: scroll;
    padding: 4px;

    .suggested-customer {
      background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
      box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
      border-radius: 6px;
      padding: 12px;
      height: 100%;
      cursor: pointer;
      text-align: center;
      display: grid;
      align-items: center;

      .name {
        @include typography.h5;
        font-weight: 500;
        color: #{colors.$blue-gray-900};
        margin: 0;
        align-self: end;
      }

      .mobile {
        @include typography.h5;
        font-weight: 400;
        align-self: center;
      }

      .address {
        @include typography.h5;
        color: #{colors.$blue-gray-500};
        font-weight: 400;
        align-self: start;
      }
    }
  }
}