@use '../common/colors' as colors;
@use '../common/constants' as constants;
@use '../common/default-theme' as default-theme;

.order-source-filter-selector-widget {
  height: min-content;

  .toggle-label {
    color: #{colors.$blue-gray-600};
    margin-bottom: 8px;
    font-size: 13px;
  }

  .toggle {
    display: grid;
    grid-template-columns: repeat(4, min-content);
    border-radius: constants.$border-radius;
    background: #{colors.$blue-gray-50};
    transition: ease-in-out all 0.3s;
    width: min-content;
    height: min-content;
    cursor: pointer;

    .disabled {
      opacity: unset;
    }

    >.selected {
      background: #{default-theme.$fg};
      color: #{default-theme.$bg};
      border-radius: constants.$border-radius;
    }

    >div {
      padding: 10px 24px;
    }
  }

  button {
    background-color: unset;
  }
}