@use "../common/colors";

.cart-widget {
  grid-area: cart-widget;
  display: flex;
  flex-direction: column;
  background-color: colors.$blue-gray-900;
  width: 26vw;
  min-width: 288px;
  height: 100vh;
  max-height: 100vh;
}