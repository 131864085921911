@use '../common/buttons';
@use '../common/typography';
@use '../common/colors';
@use '../common/constants';

.reorder-item-widget {
  padding: 12px 0;
  @include typography.h5;
  font-weight: 500;

  > .header {
    display: flex;
    margin-bottom: 4px;

    > .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;

      // First row
      > div:first-child {
        display: flex;
        justify-content: space-between;
      }

      > .original-price,
      > .discount,
      > .extra-charge {
        display: flex;
        justify-content: space-between;
        color: colors.$blue-gray-400;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    > .add-to-order-button {
      @include buttons.primary-transparent;
      height: fit-content;
      padding: 7px 12px;
      margin-left: 16px;
      border-radius: 8px;
      white-space: nowrap;
      cursor: pointer;
    }
  }

  > .unavailability-status {
    font-size: 10px;
    line-height: 12px;
    width: fit-content;
    padding: 4px;
    color: colors.$light-gray-900;
    background-color: colors.$light-gray-200;
    border-radius: 3px;
    margin-block: 4px;
  }

  > .item-description-view-widget {
    color: colors.$blue-gray-500;
    margin-top: 0;

    > .label {
      color: colors.$blue-gray-500;
    }
  }

  > .comments {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    padding: 4px 8px;
    font-size: 13px;
    line-height: 19px;
    border-radius: constants.$border-radius-small;
    background-color: #{colors.$teal-100}80;
    white-space: pre-wrap;

    > .extra-charge {
      margin-left: 8px;
    }
  }

  &.unavailable {
    color: colors.$light-gray-900;

    > .header {
      > .info {
        > .original-price,
        > .discount,
        > .extra-charge {
          color: colors.$light-gray-900;
        }
      }

      > .add-to-order-button {
        color: colors.$light-gray-600;
        background-color: colors.$light-gray-100;
        border: 1px solid colors.$light-gray-600;
        cursor: default;
      }
    }

    > .item-description-view-widget {
      color: colors.$light-gray-900;

      > .label {
        color: colors.$light-gray-900;
      }
    }

    > .comments {
      background-color: colors.$light-gray-100;
    }
  }
}
