@use '../common/typography';
@use '../common/colors';
@use "../common/employee-time-clock" as employeeTimeClock;

.employee-time-clock-shift-list-widget {
  @include employeeTimeClock.list;

  > .employee-time-clock-shift-list-item {
    @include employeeTimeClock.list-item;
    grid-template-columns: 1fr .9fr .7fr .2fr .7fr .9fr repeat(3, 1fr);

    &:first-child {
      &.active {
        border: 1px solid colors.$teal-500;
      }

      &.active + div {
        border-top: none;
      }
    }

    &:only-child {
      border-bottom: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
    }

    &:last-child:not(:only-child) {
      border-bottom: 1px solid colors.$blue-gray-100;
      border-radius: 0 0 8px 8px;
    }
  }
}
