@use '../common/default-theme' as default-theme;
@use '../common/typography' as typography;
@use "../common/cta" as cta;
@use '../common/buttons' as buttons;

.pos-surcharge-presets {
  width: 500px;
  margin: 60px 160px;
  display: grid;

  .title {
    @include typography.h1;
    font-weight: bold;
    text-align: center;
    margin-bottom: 38px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    grid-gap: 12px;
    margin-bottom: 24px;

    .add-custom-surcharge {
      font-size: 30px;
      align-items: center;
    }

    .item {
      display: grid;
      grid-gap: 8px;
      background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
      box-shadow: 0px 0px 2px rgb(0 0 0 / 10%), 0px 2px 3px rgb(0 0 0 / 20%);
      border-radius: 6px;
      padding: 14px;
      height: 100px;
      cursor: pointer;
      text-align: center;

      .name {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        color: #{default-theme.$restoplus-gray-dark};
      }

      .value {
        font-size: 14px;
        line-height: 19px;
        color: #{default-theme.$primary};
      }
    }

    .item.selected {
      color: #fff;
      background: #1c1d3e;
      position: relative;

      .check {
        background-image: url('../images/icon-check.svg');
        background-repeat: no-repeat;
        background-size: 30px;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 130px;
        top: -10px;
      }

      .name {
        color: #fff;
      }

      .value {
        color: #fff;
      }
    }
  }

  > .clear-button {
    @include buttons.primary-transparent;
    padding: 15px;
    font-size: 17px;
    margin-bottom: 10px;
  }

  > .cancel-button {
    @include cta.link-cta;
  }
}

.pos-surcharge-user-defined {
  width: 430px;
  margin: 60px 160px;

  .title {
    @include typography.h1;
    font-weight: bold;
    text-align: center;
  }

  /**** USER INPUT ****/
  .form {
    margin-bottom: 24px;
    // --- TOGGLE --- //
    .toggle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-radius: 7px;
      margin: 24px 0;
      box-sizing: border-box;
      background: #{default-theme.$secondary-bg};
      .knob {
        @include typography.h3;
        font-weight: 400;
        cursor: pointer;
        display: grid;
        align-items: center;
        padding: 10px;
        text-align: center;
        color: #{default-theme.$fg};
        &.selected {
          border-radius: 7px;
          background: #{default-theme.$fg};
          color: #{default-theme.$bg};
        }
      }
    }
    // --- SURCHARGE INPUT BOX --- //
    .surcharge-input-box {
      border-radius: 8px;
      border: 1px solid #{default-theme.$form-element-border};
      display: grid;
      padding: 12.75px 20px;
      margin-bottom: 10px;
      grid-template-columns: min-content 1fr;
      .surcharge-type-icon {
        @include typography.h1;
        padding-top: 10px;
      }
      .surcharge-name,
      .surcharge-value {
        display: grid;
        align-items: center;
        input {
          outline: none;
          border: none;
          text-align: right;
          font-size: 25px;
          overflow: hidden;
          width: 100%;
        }
      }
    }
  }

  /**** CUSTOM SURCHARGE ACTIONS ****/
  .add-surcharge.actions {
    display: grid;
    grid-template-areas:
      'confirm'
      'back'
      'cancel';
    grid-gap: 10px;
  }

  .edit-surcharge.actions {
    display: grid;
    grid-template-areas:
      'clear confirm'
      'back back'
      'cancel cancel';
    grid-gap: 10px;
  }

  .confirm-button {
    grid-area: confirm;
    @include cta.primary-cta;
    font-size: 17px;
  }

  .back-to-presets-button {
    grid-area: back;
    @include buttons.secondary-transparent;
    font-size: 17px;
    padding: 14px 0px;
  }

  .clear-button {
    grid-area: clear;
    @include buttons.primary-transparent;
    font-size: 17px;
  }

  .cancel-button {
    grid-area: cancel;
    @include cta.link-cta;
  }

  /* Hide number-type input spin box */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
