@use '../common/colors';
@use '../common/scrollbar';
@use '../common/lozenge';
@use '../common/pill';
@use '../common/elements';
@use '../common/ghost';
@use '../common/typography';

@mixin container {
  display: flex;
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  color: colors.$blue-gray-900;
}

@mixin content {
  .order-view-main-panel-widget {
    flex: 1;
    overflow-y: auto;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    @include scrollbar.hide;

    > * {
      margin-top: 16px;
      &:first-child {
        margin-top: 0;
      }
    }

    .order-view-main-panel-header-widget {
      @include _main-panel-header();
    }

    .order-view-main-panel-body-widget {
      @include _main-panel-body();
    }
  }

  .order-view-info-panel-widget {
    @include scrollbar.hide;
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    background-color: colors.$blue-gray-50;
    border-radius: 0 24px 24px 0;
    overflow: hidden scroll;
    padding: 24px;

    > * {
      margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
    }

    @include _info-panel-content();
  }
}

@mixin _main-panel-header {
  display: flex;
  align-items: center;

  .collection-type {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .order-source {
    @include lozenge.secondary-light-gray;
    margin-left: 10px;
  }

  .requested-for {
    margin-left: auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    .schedule > .label {
      margin: auto;
    }

    .date-time.schedule > .value {
      @include pill.secondary-green(large);
      margin-left: 10px;
    }
    .asap.schedule > .value {
      @include pill.secondary-red(large);
      margin-left: 10px;
    }
  }

  .time-since-label-widget {
    margin-top: 4px;
    color: colors.$blue-gray-700;
    text-align: right;
    &::first-letter {
      text-transform: uppercase;
    }
  }
}

@mixin _main-panel-body {
  .order-view-item-list-widget {
    > .items {
      > .header {
        display: flex;
        align-items: center;

        > .icon {
          @include elements.icon(24px);
        }

        > .label {
          margin-left: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
        }
      }

      > * {
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px solid colors.$blue-gray-100;

        &:first-child {
          padding-top: 0;
          margin-top: 0;
          border-top: 0;
        }
      }
    }
  }

  .order-view-amount-list-widget {
    margin-top: 16px;
    border-top: 1px solid colors.$blue-gray-100;
    padding-top: 12px;
    color: colors.$blue-gray-700;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    .amount {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
    }

    .grand-total {
      border-top: 1px solid colors.$blue-gray-100;
      padding-top: 12px;
      margin-top: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      .value {
        color: colors.$teal-600;
      }
    }
  }

  .tyro-payment-summary-widget {
    margin-top: 4px;
  }
}

@mixin _info-panel-content {
  .order-view-payment-info-widget {
    .header {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-areas: 'title close-button';
      border-bottom: 1px solid colors.$blue-gray-100;

      .title {
        grid-area: title;
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-template-areas:
          'revoked-status-tag   revoked-status-tag'
          'currency-icon        title-text';

        .rejected-tag,
        .cancelled-tag {
          grid-area: revoked-status-tag;
          @include ghost.red;
          margin-bottom: 8px;
        }
        .currency-icon {
          grid-area: currency-icon;
          width: 16px;
          height: 16px;
          background-color: colors.$blue-gray-500;
          color: colors.$white;
          border-radius: 16px;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          margin-top: 2px;
        }
        .text {
          grid-area: title-text;
          margin-left: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }
      .close-button {
        grid-area: close-button;
      }
    }

    .grand-total {
      margin-top: 8px;
      font-weight: 500;
      font-size: 31px;
      line-height: 38px;
    }

    .payment-info {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .paid {
        @include pill.primary-green;
      }
      .not-paid {
        @include pill.primary-red;
      }
      .refunded {
        @include pill.primary-yellow;
      }
      .bullet {
        margin: 0 4px;
      }
    }
  }
  .order-view-customer-info-widget {    
    padding: 10px 12px;
    background-color: colors.$white;
    border: 1px solid colors.$blue-gray-100;

    .header {
      display: flex;
     
      > .user-icon {
        @include elements.icon(20px);
      }
  
      > .title {
        margin-left: 8px;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
      }
  
      > .chevron {
        @include elements.icon(24px);
        margin-left: auto;
      }
    }

    .customer-info {
      display: none;
      
      &.visible {
        display: block;
      }
    }

    .customer-info > .key-value-pair {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
      gap: 12px;

      line-height: 24px;
      font-weight: 400;
      font-size: 16px;
      
      margin-top: 4px;
      
      &:first-child {
        margin-top: 10px;
      }

      .text {
        color: colors.$blue-gray-500;
      }

      .value {
        word-break: break-word;
        color: colors.$blue-gray-900;
      }
    }

    .dropoff-notes {
      margin-top: 8px;
      display: block;
      background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%), #f6f8fb;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      padding: 10px;
      @include typography.h5;
      font-weight: 400;
      line-height: 21px;
      color: colors.$blue-gray-800;

      > div {
        display: inline;
        font-weight: 600;
      }
    }
  }
}
