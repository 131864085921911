@use "./type-select-widget" as type-select;

.payment-type-select-widget {
  width: 80vw;
  max-width: 900px;
  @include type-select.type-select-widget;

  .payment-types {
    @include type-select.types;
  }

  .payment-type {
    @include type-select.type;
  }
}
