@use "../common/colors";
@use "../common/typography";

.cart-customer-info-widget {
  margin: 8px 12px 12px 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'customer-name customer-number'
    'delivery-address delivery-address'
    'delivery-drop-notes delivery-drop-notes'
    'customer-notes customer-notes';

  > .cart-header-info-widget {
    &.add {
      > .label {
        color: #{colors.$white}80;
      }
    }

    &.span {
      grid-column: 1/-1;
    }
  }

  .customer-name {
    grid-area: customer-name;
  }
  .customer-number {
    grid-area: customer-number;
  }
  .delivery-address {
    margin-top: 8px;
    grid-area: delivery-address;
  }
  .delivery-dropoff-notes {
    margin-top: 8px;
    grid-area: delivery-drop-notes;
  }
  .customer-notes {
    grid-area: customer-notes;
    margin-top: 5px;

    > .cart-header-info-widget {

      &.with-customer-notes {
        background-color: #{colors.$teal-500}50;
        padding: 3px 7px;
        border-radius: 3px;
        .label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: colors.$white;
        }
      }

      &.without-customer-notes {
        padding-left: 6px;
        > .label {
          color: #{colors.$white}80;
        }
      }

      > .icon {
        padding: 0 7px;
      }
    }
  }
}
