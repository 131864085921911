@use "../common/cta" as cta;
@use "../common/colors" as colors;
@use "../common/default-theme" as default-themes;

@mixin page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    height: 96px;
    aspect-ratio: 1/1;
  }

  .title {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    color: colors.$blue-gray-900;
    padding-top: 24px;
  }

  .body {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: default-themes.$secondary-fg;
    padding-top: 16px;
    width: 60vh;
    text-align: center;
  }

  button {
    @include cta.secondary-cta;
      border: 2px solid colors.$teal-500;
      margin-top: 24px;
  }
}