@use '../common/colors';
@use '../common/scrollbar';

@mixin _list-item {
  border: solid 1px colors.$blue-gray-100;
  margin-top: -1px;

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  // Select the second to the last child
  // because the last child is always the
  // label element: 'You've reached the end of the list.'
  &:nth-last-child(2) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

@mixin _no-orders {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: 69px;

  border: solid 1px colors.$blue-gray-100;
  border-radius: 8px;

  > .label {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: colors.$blue-gray-700;

    margin-top: 8px;
  }
}

.order-history-list-widget {
  @include scrollbar.hide;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  > .orders {
    @include scrollbar.hide;
    display: flex;
    flex-direction: column;
    overflow: auto;

    > .pos-order-history-list-item-widget {
      @include _list-item;
    }

    > .website-order-history-list-item-widget {
      @include _list-item;
    }

    > .google.order-history-list-item-widget {
      @include _list-item;
    }

    > .end-of-list-label {
      padding: 24px 0;

      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: colors.$blue-gray-500;
    }
  }

  > .no-orders {
    @include _no-orders;
  }

  > .no-order-search-results {
    @include _no-orders;

    > .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: colors.$blue-gray-900;

      margin-top: 8px;

      > .subtext {
        font-weight: 500;
      }

      > .customer-page-link {
        color: colors.$teal-500;
        cursor: pointer;
      }
    }
  }
}
