@use "../common/typography" as typography;

.employee-time-clock-date-range-picker {
  @include typography.h2;
  grid-area: date-picker;
  font-size: 20px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  .previous-date-range-button {
    padding-right: 20px;
  }

  .next-date-range-button {
    padding-left: 20px;

    &.disabled {
      pointer-events: none;
    }
  }
}
