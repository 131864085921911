@use '../common/actions' as actions;
@use '../common/colors' as colors;
@use '../common/constants' as constants;

.quantity-edit-widget {
  @include actions.quantity;
  width: 96px;
  color: #{colors.$blue-gray-600};
  display: grid;
  grid-template-areas: 'dec number inc';
  align-items: center;

  .icon {
    padding-top: 2px;
    cursor: pointer;
  }

  .dec {
    grid-area: dec;
  }

  .number {
    grid-area: number;
  }

  .inc {
    grid-area: inc;
  }
}
