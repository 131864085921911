@use "../common/typography";
@use "../common/constants";
@use "../common/scrollbar";
@use "../common/colors";

.cart-main-widget {
  @include scrollbar.hide;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden scroll;

  .spinner-container {
    display: flex;
    flex-flow: row;
    place-content: unset;

    > .message {
      color: #{colors.$white}80;
      margin-left: 7px;
      align-self: center;
    }
  }

  .order-comments {
    padding: 12px;
    border-top: solid 1px colors.$blue-gray-800;

    > div {
      @include typography.p;
      padding: 8px;
      background-color: #{colors.$teal-500}40;
      border-radius: constants.$border-radius-small;
      color: colors.$white;
      font-size: 13px;
      line-height: 19px;
      cursor: pointer;
      white-space: pre-wrap;
    }
  }

  .convert-to-deal-button {
    display: flex;
    border-radius: 8px;
    margin: 7px 12px 14px;
    padding: 9px;
    justify-content: center;
    color: #{colors.$teal-300};
    cursor: pointer;
    background-color: #{colors.$white}10;
    font-size: 14px;
  }

  .partial-payments-amount {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    justify-content: end;

    > .container {
      border-top: solid 1px colors.$blue-gray-800;
      padding: 12px 12px 0 12px;
      margin-bottom: 0;

      > .amount {
        @include typography.h5;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        color: #{colors.$white}99;
        padding: 0 8px;
        margin-bottom: 8px;
    
        > .value {
          color: colors.$white;
        }
      }
    }
  }
}