@use "form" as form;
@use "../common/card" as card;
@use "../common/viewport" as viewport;
@use "../common/modal" as modal;
@use "../common/colors" as colors;

@mixin disabled {
  background-color: #f5f5f5;
  border-color: #e2e2ea;
  color: #696974;
}

.form-select {
  .input-box {
    @include form.form-input();
    padding: 8px 14px;
    cursor: pointer;
  }

  &.disabled {
    .input-box {
      @include disabled();
      cursor: auto;
    }
  }

  .options {
    @include card.container();
    @include card.content();
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 25px;
    display: grid;
    grid-gap: 18px;
    font-weight: 400;
    @include modal.container();
    cursor: pointer;
    max-height: 400px;
    overflow-y: scroll;
  }

  @include viewport.mobile {
    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 998;
    }
  }

  @include viewport.tablet {
    .options-wrapper {
      position: relative;
    }
    .options {
      position: absolute;
      top: 100%;
      margin-top: 10px;
      z-index: 999;
    }
  }

  .icon {
    margin-right: 12px;
    font-size: 30px;
    color: #777777;
  }

  .selected {
    .icon {
      color: #{colors.$green-800};
    }
  }

  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
