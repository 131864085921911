@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;

.pos-order-summary-widget {
  background-color: #{default-theme.$secondary-bg};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  padding: 30px;

  .statistic {
    background-color: #{default-theme.$bg};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 16px 24px;

    .title {
      color: #{default-theme.$secondary-fg};
    }

    .value {
      @include typography.h1;
      margin: 0;
      margin-top: 15px;
      font-size: 32px;
    }
  }
}
