@use "../common/typography" as typography;
@use "../common/default-theme" as default-theme;

.eta-selector-widget {
  display: grid;
  grid-template-areas:
    'title          title         title'
    'etas           etas          etas'
    'back-btn       back-btn      back-btn';
  grid-template-columns: 20px 1fr 20px;
  padding: 50px;

  .title {
    grid-area: title;
    @include typography.h1;
    font-size: 25px;
    text-align: center;
    margin-bottom: 40px;
  }

  .etas {
    grid-area: etas;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 15px;

    .eta {
      @include typography.h3;
      background: #{default-theme.$modifier-gradient-bg};
      box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
      padding: 25px 35px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .cancel-btn {
    grid-area: back-btn;
    @include typography.h3;
    color: #{default-theme.$fg};
    text-align: center;
    margin-top: 40px;
  }
}
