@use "../common/default-theme" as default-theme;

.link {
  $bleed-width: 4px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  padding: 0 10px;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 32px;

    img {
      width: 100%;
    }
  }

  .label {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    color: #{default-theme.$secondary-fg};

    &.selected {
      color: #{default-theme.$primary};
    }
  }
}
