@use "../common/typography";
@use "../common/colors";
@use '../common/elements';

.cart-header-widget {
  background: linear-gradient(180deg, colors.$blue-gray-900 0%, #{colors.$white}06 100%);

  .spinner-container {
    display: flex;
    flex-flow: row;
    place-content: unset;

    > .message {
      color: #{colors.$white}80;
      margin-left: 7px;
      align-self: center;
    }
  }

  .editing-strip {
    display: flex;
    justify-content: space-between;
    color: colors.$white;
    background-color: colors.$rose-500;
    padding: 12px;

    .edit-text {
      @include typography.h3();
    }

    .edit-cancel {
      @include typography.p();
      cursor: pointer;
    }
  }

  .header-actions {
    padding: 12px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      align-items: center;

      > .collection-type-selector {
        display: flex;
        background-color: #{colors.$white}1a;
        padding: 10px 12px;
        border-radius: 8px;
        cursor: pointer;

        > .icon {
          @include elements.icon($size: 20px);
        }

        > .title {
          @include typography.h3;
          font-weight: 500;
          color: colors.$white;
          margin-left: 8px;
        }
      }

      > .count {
        @include typography.p;
        margin-left: 8px;
        background-color: colors.$blue-500;
        color: colors.$white;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        text-align: center;
        line-height: 28px;
      }
    }

    > .actions {
      display: flex;
      align-items: center;

      > div {
        background-color: #{colors.$white}1a;
        padding: 10px 12px;
        margin-left: 8px;
        border-radius: 8px;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        > .icon {
          @include elements.icon($size: 24px);
        }
      }
    }
  }
}