@use "../common/colors" as colors;

@mixin quantity {
  .icon {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .btn {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    color: #{colors.$white};
  }

  .dec {
    @extend .btn;
    background: #{colors.$blue-gray-700};
  }

  .number {
    margin: 0 8px;
    align-self: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.8px;
    min-width: 34px;
    text-align: center;
  }

  .inc {
    @extend .btn;
    background: #{colors.$teal-500};
  }
}
