@use "../common/scrollbar";
@use "../common/elements";
@use "../common/colors";
@use "../common/ghost";
@use "../common/pill";
@use "../common/lozenge";
@use "../common/typography";

@mixin _amount {
  display: flex;
  place-content: space-between;

  color: colors.$blue-gray-700;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

@mixin _order-status {
  @include ghost.red;
  margin-bottom: 8px;
}

@mixin _item-price-customization {
  display: flex;
  justify-content: space-between;
  color: colors.$blue-gray-500;
}

@mixin _info {
  padding: 10px 12px;
  border-left: 1px solid colors.$blue-gray-100;
  border-right: 1px solid colors.$blue-gray-100;
  border-bottom: 1px solid colors.$blue-gray-100;

  &:first-child {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-top: 1px solid colors.$blue-gray-100;
  }

  &:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  > *:not(.header) {
    display: none;
    grid-template-columns: 0.5fr 1fr;
    gap: 12px;
    margin-top: 4px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    // element after `.header`
    &:nth-child(2) {
      margin-top: 10px;
    }

    &.visible {
      display: grid;
    }

    > .label {
      color: colors.$blue-gray-500;
    }

    > .value {
      word-break: break-word;
      color: colors.$blue-gray-900;
    }
  }

  > .header {
    display: flex;

    &:first-child {
      margin-top: 0;
    }

    > .icon {
      @include elements.icon(20px);
    }

    > .label {
      margin-left: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }

    > .chevron {
      @include elements.icon(24px);
      margin-left: auto;
    }
  }
}

@mixin _button {
  width: 100%;
  padding: 14px 32px;
  border: solid 1px;
  border-radius: 4px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 14px;
}

@mixin order-view-widget {
  display: flex;
  height: 90vh;
  width: 90vw;
  max-width: 1200px;
  color: colors.$blue-gray-900;

  > .main-panel {
    flex: 1;
    overflow-y: auto;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;

    @include scrollbar.hide;

    > * {
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    > .header {
      display: flex;
      align-items: center;

      > .collection-type {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      > .order-type {
        @include lozenge.secondary-light-gray;

        margin-left: 10px;
      }

      > .requested-for {
        display: flex;
        flex-direction: column;
        margin-left: auto;

        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        > .label {
          text-align: right;

          span {
            margin-left: 10px;

            &.asap {
              @include pill.secondary-red(large);
            }

            &.schedule {
              margin-left: 10px;
              @include pill.secondary-green(large);
            }
          }
        }

        > .time-since-label-widget {
          margin-top: 4px;
          color: colors.$blue-gray-700;
          text-align: right;

          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
    }

    > .collection-type {
      grid-area: collection-type;

      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
    }

    > .requested-for {
      grid-area: requested-for;
      align-self: center;
      text-align: right;
      font-weight: 700;

      > span {
        @include pill.secondary-green(large);
        margin-left: 5px;

        &.asap {
          @include pill.secondary-red(large);
        }
      }
    }

    > .time-since-label-widget {
      grid-area: time-since-label;
      text-align: right;
      color: #{colors.$blue-gray-700};
      margin-top: 8px;
      font-weight: 700;
    }

    > .items {
      > * {
        padding-top: 12px;
        margin-top: 12px;
        border-top: 1px solid colors.$blue-gray-100;

        &:first-child {
          padding-top: 0;
          margin-top: 0;
          border-top: 0;
        }
      }

      > .header {
        display: flex;
        align-items: center;

        > .icon {
          @include elements.icon(24px);
        }

        > .label {
          margin-left: 10px;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
        }
      }
    }

    > .amounts {
      display: grid;
      grid-template-columns: 1fr auto;

      border-top: 1px solid colors.$blue-gray-100;
      border-bottom: 1px solid colors.$blue-gray-100;
      padding: 12px 0;

      color: colors.$blue-gray-700;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      > * {
        display: contents;
        margin-top: 2px;

        &:first-child {
          margin-top: 0;
        }

        > .amount {
          text-align: right;
        }
      }
    }

    > .grand-total {
      display: flex;
      place-content: space-between;
      margin-top: 12px;

      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      > .amount {
        color: colors.$teal-600;
      }
    }

    > .uber-delivery-fee,
    .description {
      display: flex;
      place-content: space-between;
      font-weight: 500;
      font-size: 16px;
    }

    > .description {
      margin: 0;
      color: colors.$blue-gray-500;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  > .info-panel {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    background-color: colors.$blue-gray-50;
    border-radius: 0 24px 24px 0;

    overflow: hidden scroll;
    @include scrollbar.hide;

    padding: 24px;

    > * {
      margin-top: 24px;

      &:first-child {
        margin-top: 0;
      }
    }

    .payment-info {
      > .header {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas:
          'order-status  close-icon'
          'label         close-icon';
        padding-bottom: 8px;
        border-bottom: 1px solid colors.$blue-gray-100;

        > .cancelled-order-status {
          grid-area: order-status;
          @include _order-status;
        }

        > .rejected-order-status {
          grid-area: order-status;
          @include _order-status;
        }

        > .label {
          grid-area: label;
          display: flex;
          align-items: center;

          > .icon {
            width: 16px;
            height: 16px;
            background-color: colors.$blue-gray-500;
            color: colors.$white;
            border-radius: 16px;
            text-align: center;

            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
          }

          > .label {
            margin-left: 10px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
          }
        }

        > .close-icon {
          grid-area: close-icon;
          @include elements.icon(32px);
        }
      }

      > .amount {
        margin-top: 8px;
        font-weight: 500;
        font-size: 31px;
        line-height: 38px;
      }

      > .payment {
        display: flex;
        align-items: center;
        margin-top: 8px;

        > .paid {
          @include pill.primary-green;
        }

        > .not-paid {
          @include pill.primary-red;
        }

        > .refunded {
          @include pill.primary-yellow;
        }

        > .bullet {
          margin: 0 4px;
        }
      }
    }

    > .info-container {
      background-color: colors.$white;

      > .pickup-info {
        @include _info;
      }

      > .delivery-info {
        @include _info;

        .dropoff-notes {
          margin-top: 8px;
          display: none;
          background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%), #f6f8fb;
          box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          padding: 10px;
          @include typography.h5;
          font-weight: 400;
          line-height: 21px;
          color: colors.$blue-gray-800;

          &.visible {
            display: block;
          }

          > div {
            display: inline;
            font-weight: 600;
          }
        }
      }

      > .table-info {
        @include _info;

        > .header .icon {
          margin-top: 2px;
        }
      }

      > .customer-info {
        @include _info;

        .dropoff-notes {
          margin-top: 8px;
          display: none;
          background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%), #f6f8fb;
          box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          padding: 10px;
          @include typography.h5;
          font-weight: 400;
          line-height: 21px;
          color: colors.$blue-gray-800;

          &.visible {
            display: block;
          }

          > div {
            display: inline;
            font-weight: 600;
          }
        }
      }

      > .employee-info {
        @include _info;
      }

      .uber-courier-info {
        @include _info;

        > .header .icon {
          margin-top: 2px;
        }

        .courier-details {
          align-items: center;
        }

        .courier-image {
          width: 100%;
          border-radius: 50%;
        }

        .courier-name {
          color: colors.$blue-gray-900;
          font-size: 16px;
          font-weight: 500;
        }

        .courier-vehicle {
          color: colors.$blue-gray-700;
          font-size: 14px;
          font-weight: 400;
        }

        .courier-contact-number {
          text-decoration: none;
          color: colors.$blue-gray-700;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    > .actions {
      display: grid;
      margin-top: auto;
      grid-template-columns: 1fr 1fr;
      column-gap: 16px;

      &.refundable:not(.cancelled) {
        > .print-order-button {
          grid-column: 1 / 2;
        }

        > .refund-order-button {
          grid-column: 2 / 2;
        }
      }

      &.cancelled {
        > .refund-order-button {
          grid-column-start: 1;
          grid-row-start: 1;
        }
      }

      > * {
        grid-column: 1 / -1;
        @include _button;
        margin-top: 16px;
      }

      > .accept-order-button {
        border-color: colors.$teal-600;
        background-color: colors.$teal-600;
        color: colors.$white;
      }

      > .make-payment-button {
        border-color: colors.$teal-600;
        background-color: colors.$teal-600;
        color: colors.$white;
      }

      > .edit-order-button {
        border-color: colors.$blue-gray-100;
        background-color: colors.$blue-gray-100;
        color: colors.$blue-gray-900;
      }

      > .print-order-button {
        background-color: transparent;
        border-color: colors.$blue-gray-500;
        color: colors.$blue-gray-500;
      }

      > .refund-order-button {
        background-color: transparent;
        border-color: colors.$blue-gray-500;
        color: colors.$blue-gray-500;
      }

      > .reject-order-button {
        background-color: transparent;
        border-color: transparent;
        color: colors.$rose-800;
        text-decoration: underline;
      }

      > .cancel-order-button {
        background-color: transparent;
        border-color: transparent;
        color: colors.$rose-800;
        text-decoration: underline;
      }
    }
  }
}

.swal2-container {
  .order-view-cancel-reject-dialog {
    .swal2-header {
      .swal2-title {
        margin-top: 0 !important;
      }
    }

    .swal2-content {
      .swal2-html-container {
        color: #{colors.$blue-gray-700};
      }
    }

    .swal2-actions {
      .confirm-button {
        background: #{colors.$rose-800} !important;
        border: 1px solid #{colors.$rose-800} !important;
        border-radius: 4px !important;
        text-transform: inherit !important;
      }
      .cancel-button {
        border: 1px solid #{colors.$blue-gray-500} !important;
        border-radius: 4px !important;
        text-transform: inherit !important;
      }
    }
  }
}
