@use '../common/order-view';
@use '../common/colors';

.google.order-view-widget {
  @include order-view.container;
  @include order-view.content;

  .google-order-view-actions-widget {
    display: grid;
    gap: 16px;
    margin-top: auto;
    padding-top: 24px;

    .button {
      width: 100%;
      padding: 14px 32px;
      border: solid 1px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      &.accept-order,
      &.make-payment {
        border-color: colors.$teal-600;
        background-color: colors.$teal-600;
        color: colors.$white;
      }
      &.print-order {
        background-color: transparent;
        color: colors.$blue-gray-500;
      }
      &.reject-order {
        background-color: transparent;
        color: colors.$rose-800;
        text-decoration: underline;
        border: none;
      }
    }
  }
}
