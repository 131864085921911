@use "../common/default-theme";
@use "../common/item-styles";
@use '../common/colors';

.half-and-half-widget {
  @include item-styles.item-widget;

  .main-panel {
    @include item-styles.main-panel;
    padding-left: 6px;
    padding-right: 6px;

    .half-and-half {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, 1fr);

      .half {
        .selected-pizza {
          @include item-styles.selected-pizza;
          margin-bottom: 15px;
        }

        .remove-toppings {
          @include item-styles.remove-toppings;
          .badge {
            display: none;
          }
        }

        .added-toppings {
          @include item-styles.added-toppings;
        }
      }
    }

    .add-toppings-list {
      @include item-styles.add-toppings-list;
    }

    .pizza-list {
      @include item-styles.pizza-list;
    }

    .modifier {
      .header {
        align-items: center;
      }
    }
  }

  .info-panel {
    @include item-styles.info-panel;

    .spec-info {
      @include item-styles.spec-info;
    }

    .summary {
      .item-description-view-widget {
        color: colors.$blue-gray-500;
      }

      .convert-link {
        display: flex;
        align-items: center;

        padding: 12px 0;

        cursor: pointer;

        &.deal {
          padding-top: 12px;
          margin-bottom: 12px;
          border-bottom: 1px solid #{default-theme.$border-light};
        }

        img {
          margin-right: 8px;
        }

        color: #{default-theme.$primary};
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
      }

      .empty-summary {
        @include item-styles.empty-info-panel-summary;
      }

      .half {
        padding-bottom: 2px;
        &.second {
          border-top: 1px solid #{default-theme.$border-light};
        }
        .header {
          padding-top: 12px;
          display: grid;
          grid-template-columns: 1fr min-content;
          align-items: center;
          cursor: pointer;

          &.disable {
            cursor: not-allowed;

            > svg {
              opacity: 0.4;
            }
          }

          .pizza {
            > h4 {
              margin-bottom: unset;
            }
          }

          > .edit-icon > svg {
            color: #{default-theme.$bg};
            background-color: #{default-theme.$secondary-fg};
            font-size: 25px;
            border-radius: 50%;
            padding: 6px;
          }
        }

        .summary-item {
          display: grid;
          grid-auto-flow: column;
          grid-template-columns: 40% 60%;
          grid-gap: 4px;
          margin: 10px 0;
          font-style: normal;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          .name {
            color: #{default-theme.$secondary-fg};
          }

          .value {
            > li {
              list-style: none;
            }
          }
        }
      }
    }
  }
}

.confirm-convert {
  #swal2-title {
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 26px !important;
  }

  #swal2-content {
    font-weight: 400 !important;
    font-size: 15px !important;
    line-height: 21px !important;

    color: #{colors.$blue-gray-400};
  }
}
