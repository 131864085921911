@use '../common/colors' as colors;

.print-docket-settings-widget {
  padding: 20px;

  .switch {
    background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    padding: 24px;

    label {
      margin-bottom: 8px;

      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }

    .help {
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
    }
  }

  .reset-docket-numeric-counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #f7f9fc 0%, #ffffff 100%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    padding: 25px;

    .label {
      color: #{colors.$blue-gray-900};
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
  }
}
