@use "../common/typography" as typography;
@use "../common/default-theme" as default-theme;
@use "../common/cta"as cta;
@use "../common/colors" as colors;

.split-by-amount-input-widget{
  width: 60vw;
  max-width: 900px;
  padding: 60px 48px;
  display: grid;
  place-items: center;

  .title {
    @include typography.h1;
    text-align: center;
  }

  .amount-to-pay-input-box {
    border-radius: 8px;
    display: grid;
    padding: 12.75px 20px;
    grid-template-columns: min-content 1fr;
    border: 1px solid #{default-theme.$form-element-border};
    width: 100%;

    .dollar-sign-icon {
      @include typography.h1;
      padding-top: 10px;
      color: colors.$blue-gray-500;
    }

    .amount-to-pay-value {
      display: grid;
      align-items: center;

      input {
        outline: none;
        border: none;
        background: none;
        text-align: right;
        font-size: 25px;
        font-weight: normal;
        font-style: normal;
        line-height: 35px;
        overflow: hidden;
        width: 100%;
      }
    }
  }

  .payment-info {
    @include typography.h2;
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(246, 248, 251, 0.549) 0%, rgba(233, 233, 233, 0.371) 100%);
    margin-top: 20px;
    width: 100%;
    
    .total, .amount-paid, .balance {
      padding: 16px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total, .amount-paid {
      border-bottom: 1px solid #{colors.$blue-gray-100};
    }

    .balance .amount {
      color: #{colors.$rose-500};

      &::first-letter {
        color: #{colors.$rose-500}
      }
    }
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 8px;
    width: 100%;

    &.first-payment {
      grid-template-columns: repeat(2, 1fr);
    }
    
    button {
      width: 100%;

      &.confirm {
        @include cta.primary-cta;
        border-radius: 4px;
      }

      &.back {
        @include cta.link-cta;
        color: colors.$blue-gray-700;
        border: 1px solid colors.$blue-gray-500;
        border-radius: 4px;
      }

      &.pay-balance{
        @include cta.link-cta;
        color: colors.$blue-gray-900;
        background-color: colors.$blue-gray-100;
        border-radius: 4px;
      }
    }
  }

  /*--- Hide number-type input spin box ---*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  /*--------------------------------------*/
}