@use "../common/typography"as typography;
@use "../common/default-theme"as default-theme;
@use "../common/constants"as constants;
@use "../common/cta" as cta;

.table-booking-reject-reason-selector-widget {
  padding: 80px 120px;
  max-width: 650px;
  width: 70vw;

  .header {
    @include typography.h1;
    text-align: center;
    margin-bottom: 40px;
  }

  .reject-reasons-presets {
    .reject-reasons {
      padding: 4px;
      gap: 12px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-auto-rows: 1fr;
    
      .reject-reason {
        border-radius: constants.$border-radius;
        background: #{default-theme.$modifier-gradient-bg};
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        text-align: center;
        padding: 30px 0;
        @include typography.p;
  
        display: grid;
        grid-auto-flow: row;
        place-items: center;
      }
    }

    .actions {
      margin-top: 24px;
      display: grid;
      place-items: center;

      button {
        @include cta.link-cta;
      }
    }
  }

  .custom-reject-reason {
    .form-buttons {
      margin-top: 24px;
      display: grid;
      gap: 10px;
      width: 100%;

      .submit-btn {
        @include cta.primary-cta;
      }

      .cancel-btn {
        @include cta.link-cta;
      }
    }
  }
}