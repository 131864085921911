@use "../common/default-theme";
@use "../common/constants";
@use "../common/typography";
@use "../common/cta";
@use "../common/colors";

.card-refund-payment-widget {
  width: 40vw;
  min-width: 800px;
  padding: 40px 32px 32px;
  margin: 0 auto;
  color: colors.$blue-gray-900;

  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.36px;
    text-align: center;
  }

  .refund-payment-amount {
    padding: 24px;
    border-radius: 8px;
    border: 1px solid colors.$blue-gray-100;
    background: colors.$light-gray-50;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    margin-top: 40px;
    
    .amount {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 38px;
      text-align: right;
      margin: 0;

      &::first-letter {
        color: colors.$blue-gray-500;
        margin-right: 5px;
      }
    }
  }

  .refund-payment-options {
    margin-top: 40px;
    display: grid;
    grid-auto-flow: column;
    gap: 24px;

    .option {
      padding: 40px;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid colors.$blue-gray-100;
      border-radius: 8px;
      cursor: pointer;

      display: grid;
      grid-auto-flow: row;
      gap: 12px;
      justify-items: center;

      .label {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0.09px;
      }
    }
  }

  .max-refund-payment-amount {
    @include typography.h5;
      margin-top: 8px;
      font-weight: 400;
      line-height: 21px;
      color: colors.$blue-gray-700;
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 1fr));
      text-align: center;

      span {
        font-weight: 600;

        &.invalid {
          color: colors.$rose-800;
        }
      }
   }


  .actions {
    margin-top: 40px;
    display: flex;

    button {
      width: 100%;
      &.done {
        @include cta.primary-cta;
        margin-left: 14px;
      }

      &.cancel {
        @include cta.tertiary-cta;
        border-radius: 4px;
        color: colors.$blue-gray-700;
      }
    }
  }
}