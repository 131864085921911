@use "../common/scrollbar" as scrollbar;
@use "../common/typography" as typography;
@use "../common/colors" as colors;
@use "../common/constants" as constants;

.table-booking-widget {
  color: #{colors.$blue-gray-900};
  padding: 24px;
  .no-table-bookings {
    text-align: center;
    margin-top: 20vh;
  }

  .table-bookings-tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    align-items: center;
    border-radius: constants.$border-radius;
    gap: 10px;
    li {
      background-color: #{colors.$blue-gray-50};
      list-style: none;
      text-align: center;
      cursor: pointer;
      padding: 14px;
      font-size: 16px;
      font-weight: 400px;
      border-radius: constants.$border-radius;
      color: colors.$blue-gray-500;
    }

    li:nth-child(2) {
      border-radius: 0px;
      border-radius: constants.$border-radius;
    }

    li.active {
      box-shadow: colors.$card-shadow;
      background-color: #343553;
      color: colors.$white;
    }
  }

  .table-bookings {
    .future-table-booking-list-widget,
    .table-booking-history-list-widget {
      display: grid;
      grid-auto-rows: min-content;

      overflow: hidden scroll;
      max-height: calc(100vh - 126px);

      @include scrollbar.hide();

      .wrapper {
        border-radius: 8px;
        display: grid;
        border: 1px solid colors.$blue-gray-100;
        grid-auto-rows: min-content;
      }
      .date-group {
        > .date {
          @include typography.h2;
          margin-top: 30px;
        }
      }
    }

    .end-list {
      text-align: center;
      margin-top: 20px;
      color: #{colors.$blue-gray-300};
    }
  }
}
