@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;
@use "../common/cta" as cta;

.pos-time-range-select-widget {
  padding: 50px;
  padding-bottom: 30px;
  width: 80vw;
  max-width: 1000px;

  .time-range-widget {
    margin-bottom: 50px;

    .form-item {
      .input-box {
        margin-top: 5px;
        background-image: url('../images/icon-calendar.svg');
        background-repeat: no-repeat;
        background-position: 95% center;
      }

      &:first-child {
        .input-box {
          background-image: url('../images/icon-ellipsis.svg');
        }
      }

      .daypicker-container {
        input {
          background-image: url('../images/icon-calendar.svg');
          background-repeat: no-repeat;
          background-position: 95% center;
        }
      }
    }

    .date-range {
      display: none;
    }
  }

  .actions {
    max-width: 350px;
    margin: 0 auto;

    .done {
      @include cta.primary-cta;
      @include typography.h4;
      width: 100%;
    }

    .cancel {
      @include cta.link-cta;
      width: 100%;
    }
  }
}
