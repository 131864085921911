@use "../common/scrollbar";

.menu-main-widget {
  @include scrollbar.hide;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;

  .spinner-container {
    width: inherit;
  }
}
