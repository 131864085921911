@use '../common/colors' as colors;
@use '../common/default-theme' as default-theme;
@use '../common/buttons' as buttons;

.pos-trading-hours-edit-widget {
  padding: 0 20px 50px 20px;

  form > .form-items {
    grid-gap: unset;

    .input-titles {
      padding-left: 80px;
      display: grid;
      grid-template-columns: 2fr 2fr 1.5fr;
      color: #{default-theme.$secondary-fg};
      font-size: 12px;
      max-width: 650px;
      text-align: center;
    }

    .weekly-hours {
      .daily-hours {
        display: grid;
        grid-template-columns: 80px 1fr;
        border-bottom: 1px solid #{colors.$blue-gray-100};
        padding: 24px 0;
        align-items: center;

        > .form-item.inline-title {
          background-color: unset;
          font-weight: 600;
        }

        .time-period {
          display: flex;
          align-items: center;

          .time-period-edit-widget > .meta {
            display: none;
          }

          .delete-trading-session.action {
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .add-time-period {
          color: #{colors.$teal-500};
          display: flex;
          cursor: pointer;
          .plus-icon {
            margin-right: 5px;
            font-size: 25px;
          }
        }
      }
    }
  }
}
