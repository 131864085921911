@use '../common/scrollbar' as scrollbar;

.print-station-list-page {
  height: 100vh;
  display: grid;
  grid-template-rows: min-content 1fr;

  .body {
    @include scrollbar.hide;
    height: unset;
    padding: 24px;
    overflow: scroll;
  }
}
