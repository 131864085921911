@use "../common/typography" as typography;
@use "../common/constants" as constants;
@use "../common/cta" as cta;
@use "../common/scrollbar" as scrollbar;
@use '../common/colors' as colors;
@use "../common/default-theme" as default-theme;

.uber-delivery-cancel-order-confirmation-widget {
  width: 23vw;
  padding: 25px;
  text-align: center;
  .title {
    @include typography.h1;
  }
  .sub-title {
    color: #{colors.$blue-gray-700};
  }
  .actions {
    margin-top: 10px;
    .cancel-button {
      width: 100%;
      @include typography.h4;
      text-align: center;
      background: #{colors.$rose-800};
      color: #{colors.$white};
      border: 2px solid #{colors.$rose-800};
      margin: 0;
      padding: 15px;
      border-radius: 10px;
      cursor: pointer;
      transition: ease-in-out all 0.2s;
      margin-bottom: 10px;
      &:focus {
        border: none;
        outline: none;
      }
    }
    .go-back-button {
      width: 100%;
      @include cta.tertiary-cta;
      color: #{colors.$blue-gray-900};
    }
  }
}
