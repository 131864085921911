@use "../common/card" as card;
@use "../common/typography" as typography;
@use "../common/default-theme"as default-theme;
@use "../common/constants"as constants;
@use "../common/cta" as cta;
@use "../common/scrollbar" as scrollbar;
@use "../common/colors" as colors;

.start-dine-in-order-widget {
  background-color: #F4F8FE;
  color: #{default-theme.$fg};

  display: grid;
  place-items: center;

  .add-table-widget {
    @include card.container;

    padding: 48px;
    width: 600px;

    box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
    border-radius: 20px;
  
    .title {
      @include typography.h1;
      text-align: center;
      margin-bottom: 38px;;
    }
  
    form {
      margin-bottom: 24px;
    }
  
    .actions {
      display: grid;
      gap: 10px;
  
      .confirm-btn {
        @include cta.primary-cta;
      }
      .back-btn {
        @include cta.link-cta;
        border: 1px solid #{default-theme.$secondary-fg};
        border-radius: 10px;
        font-size: 15px;
      }
      .cancel-btn {
        @include cta.link-cta;
      }
    }
  }

  .table-selector-widget {
    @include card.container;

    padding: 48px;
    width: 80vw;

    box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
    border-radius: 20px;
    
  
    .title {
      @include typography.h1;
      text-align: center;
      margin-bottom: 40px;
    }
  
    .tables-container {
      max-height: 50vh;
      margin-bottom: 40px;
      
      overflow: hidden scroll;
      @include scrollbar.hide;

      background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
      background-repeat: no-repeat;
      background-size: 100% 40px, 100% 40px, 100% 10px, 100% 10px;
      background-attachment: local, local, scroll, scroll;
    }
  
    .tables {
      gap: 14px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-auto-rows: 1fr;
  
      .table, .add-table {
        border-radius: constants.$border-radius;
        background: #{default-theme.$modifier-gradient-bg};
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);

        padding: 30px 0;

        cursor: pointer;

        text-align: center;
        @include typography.p;

        &.taken {
          background: #{colors.$yellow-100};
  
          .label {
            margin-top: 4px;
          }
        }
      }
  
      .add-table {
        display: flex;
        justify-content: center;
  
        .icon {
          width: 21px;
          height: 21px;
        }
      }
    }
  
    .actions {
      display: grid;
      grid-auto-flow: column;
      gap: 14px;
  
      button {
        width: 100%
      }

      .skip-table {
        @include cta.primary-cta;
      }
    
      .cancel {
        @include cta.link-cta;
        border: 1px solid #{default-theme.$secondary-fg};
        font-size: 15px;
      }
    }
  }
}