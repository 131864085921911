@use "../common/default-theme" as default-theme;
@use "../common/constants" as constants;
@use "../common/typography" as typography;
@use "../common/cta" as cta;
@use '../common/buttons' as buttons;

.pos-customer-view-widget {
  padding: constants.$modal-horizontal-gap 14px;
  color: #{default-theme.$fg};
  display: grid;
  grid-template-rows: min-content min-content min-content 1fr;
  overflow: auto;

  .basic-info {
    box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
    border-radius: constants.$border-radius;
    padding: constants.$modal-horizontal-gap;
    display: grid;
    grid-template-areas:
      'name name actions'
      'other-info other-info other-info';
    grid-template-columns: 1fr 1fr;
    gap: 6px 24px;
    align-items: center;
    margin: 0 10px;

    img {
      width: 17px;
    }

    .name {
      grid-area: name;
      font-size: 25px;
      font-weight: 600;
    }

    .other-info {
      grid-area: other-info;
      .customer-phone-and-email {
        display: flex;
        flex-wrap: wrap;
        .phone {
          padding-right: 50px;
        }
        .user-icon {
          margin-right: 8px;
        }
      }

      .customer-notes {
        text-indent: -1.6em;
        text-align: justify;
        margin: 10px 0px 0px 25px;
        padding-right: 5rem;
        .user-icon {
          margin-right: 8px;
          vertical-align: top;
        }
      }
    }

    .actions {
      grid-area: actions;
      button {
        @include buttons.secondary-transparent;
        padding: 10px 70px;
      }
    }
  }

  .order-history-text {
    font-size: 20px;
    font-weight: 600;
    margin: 0 10px;
    margin-top: 38px;
  }

  .order-category-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    align-items: center;
    border: 1px solid #{default-theme.$fg};
    border-radius: constants.$border-radius;
    margin: 24px 10px;

    .order-category {
      list-style: none;
      text-align: center;
      cursor: pointer;
      padding: 10px;
      border-bottom-left-radius: constants.$border-radius;
      border-top-left-radius: constants.$border-radius;

      &.active {
        background-color: #{default-theme.$fg};
        color: #{default-theme.$bg};
      }
    }

    .order-category:nth-child(2) {
      border-radius: 0;
      border-bottom-right-radius: constants.$border-radius;
      border-top-right-radius: constants.$border-radius;
    }
  }
}
