@use "../common/colors";
@use "../common/scrollbar";

@mixin _light {
  background: colors.$white;
  .pin-input {
    border: 1px solid colors.$blue-gray-400;
  }
  .number-pad {
    .number-button {
      background: colors.$blue-gray-100;
      color: colors.$blue-gray-900;
      font-weight: bold;
      box-shadow: none;
    }
  }
}

.employee-pin-widget {
  button {
    -webkit-tap-highlight-color: transparent; // remove blue highlight on press/touch
  }

  &.dark {
    background: linear-gradient(180deg, rgba(247, 249, 252, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%);
    .pin-input {
      border: 1px solid colors.$blue-gray-400;
    }
    .number-pad {
      .number-button {
        background: linear-gradient(180deg, #343553 0%, rgba(52, 53, 83, 0.8) 100%);
        color: colors.$white;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2), 0px 0px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.light {
    @include _light;
  }

  &.pos-order-view-pin-modal {
    @include _light;
    padding: 25px 65px;

    .title{
      font-size: 16px;
    }

    .number-pad {
      grid-gap: 21px;
      margin: 20px 0 0 0;
    
      .number-button{
        font-size: 27px;
        padding: 20px 27px;
      }
    }
  }

  box-shadow: 0px 0px 24px 8px rgba(0, 0, 0, 0.12);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px 85px;
  margin: auto 0;
  width: 470px;

  .title {
    font-weight: 600;
    font-size: 18px;
    color: colors.$blue-gray-900;
    line-height: 27px;
    white-space: pre-wrap;
  }

  .pin-inputs {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: 24px;
    :nth-child(1),
    :nth-child(2),
    :nth-child(3) {
      margin-right: 24px;
    }
  }

  .pin-input {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .pin-input-filled {
    background-color: colors.$blue-gray-900;
  }

  .pin-input-error {
    background-color: transparent;
    border-color: colors.$rose-800 !important;
  }

  .error-message {
    color: colors.$rose-800;
    font-size: 13px;
  }

  .number-pad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 23px 0;
    grid-gap: 24px;

    .number-button {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      line-height: 38px;
      border-radius: 24px;
      padding: 23px 32px;
      cursor: pointer;
    }

    .number-button:nth-child(10) {
      grid-column: 2;
      grid-row: 4;
    }

    .delete-button,
    .cancel-button {
      grid-column: 3;
      grid-row: 4;
      background-color: transparent;
      font-size: 16px;
      font-weight: bold;
      max-width: 82px;
    }

    .delete-button {
      color: colors.$blue-gray-700;
    }

    .cancel-button {
      color: colors.$blue-gray-500;
    }
  }

  .restoplus-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    .powered-by {
      font-weight: bold;
      font-size: 14px;
      margin-right: 8px;
    }
    .icon {
      width: 74px;
    }
  }
}
