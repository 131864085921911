@use "../common/constants" as constants;
@use "../common/default-theme" as default-theme;
@use "../common/colors" as colors;

.incoming-online-orders-list-item-widget {
  border-radius: constants.$border-radius;
  background: #{default-theme.$modifier-gradient-bg};
  box-shadow: 0px 3px 10px -3px rgb(0 0 0 / 30%);
  cursor: pointer;
  position: relative;
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;

  .order-data {
    padding: 13px 20px 13px 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr min-content;
    cursor: pointer;
    gap: 1%;

    .customer-info {
      .name {
        font-size: 18px;
        line-height: 24px;
        color: colors.$blue-gray-900;
      }

      .mobile,
      .address {
        font-size: 14px;
        color: colors.$blue-gray-700;
      }
    }

    .collection-info {
      .collection-type {
        margin: 0;
        color: colors.$blue-gray-900;
        font-size: 18px;
        line-height: 24px;
      }

      .preset-label {
        color: colors.$rose-600;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
      }
    }

    .grand-total {
      font-size: 18px;
    }

    .done-btn {
      color: colors.$blue-gray-900;
      background: transparent;
      border: 1px solid colors.$blue-gray-500;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 7px 12px;
      align-self: center;
      font-size: 14px;
      line-height: 20px;
      margin: 12px 0 0;
    }
  }
}