@use "../common/scrollbar";
@use "../common/typography";
@use "../common/colors";
@use "../common/menu-sidebar";
@use "../common/default-theme" as default-theme;

@keyframes highlight {
  0%,
  100% {
    background-color: #{colors.$yellow-500}00;
  }

  50% {
    background-color: #{colors.$yellow-500}80;
  }
}

.menu-sidebar-widget {
  @include scrollbar.hide;
  grid-area: menu-sidebar-widget;
  background: linear-gradient(141.27deg, #c3cee0 -6.33%, colors.$blue-gray-50 18.86%);
  overflow-y: scroll;
  background-color: colors.$blue-gray-50;
  padding: 12px 12px 12vh;
  width: 17vw;

  .header {
    @include typography.h1;
    margin-top: 12px;
    margin-bottom: 20px;
    padding-left: 4px;
  }

  .reorder-tab {
    @include menu-sidebar.item;

    &.highlight {
      animation-name: highlight;
      animation-duration: 2s;
    }  

    &.hide {
      display: none;
    }
  }

  .category-tabs {
    .category-tab {
      @include menu-sidebar.item;
    }
  }

  .footer {
    @include typography.p();
    text-align: center;
    margin-top: 12px;
    color: #{default-theme.$secondary-fg};
  }
}
