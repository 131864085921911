@use "../common/card" as card;
@use "../common/typography" as typography;
@use "../common/default-theme"as default-theme;
@use "../common/constants"as constants;
@use "../common/cta" as cta;
@use "../common/colors" as colors;

.order-flow-type-select-widget {
  background-color: #{default-theme.$secondary-bg};
  color: #{default-theme.$fg};

  .wrapper {
    display: grid;
    grid-template-rows: 1fr max-content 1.5fr;
    justify-items: center;
    height: 100vh;

    .shifts-button {
      position: absolute;
      right: 21px;
      top: 17px;
      display: flex;
      align-items: center;
      border: 1px solid colors.$teal-300;
      border-radius: 4px;
      color: colors.$teal-600;
      grid-column-gap: 8px;
      padding: 7px 12px;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      cursor: pointer;
    }

    .store-info {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .image {
        margin-right: 12px;
        img {
          width: 48px;
          border-radius: 8px;
        }
      }

      .store-name {
        @include typography.h1;
        color: #{default-theme.$tertiary};
        margin-bottom: 0;
      }
    }

    .employee-info {
      display: grid;
      gap: 12px;
      place-items: center;

      .employee-name {
        @include typography.h5;
        font-weight: 400;
        color: #{default-theme.$secondary-fg};

        align-self: end;

        span {
          color: #{default-theme.$fg};
          font-weight: 600;
        }
      }

      .employee-logout-button {
        @include cta.secondary-cta;
        padding: 15px 64px;

        align-self: start;

        display: flex;
        align-items: center;

        font-weight: 500;
        font-size: 17px;
        line-height: 23px;

        img {
          margin-right: 8px;
        }
      }
    }

    .main-panel {
      @include card.container;
      box-shadow: 0px 0px 3px rgba(46, 47, 77, 0.05), 0px 10px 25px rgba(89, 88, 123, 0.15);
      border-radius: 20px;
      margin: 9px 0px;
      padding: 22px;

      display: grid;
      grid-template-rows: max-content 1fr;

      .header {
        border-bottom: 1px solid #{default-theme.$border-light};
        padding-bottom: 18px;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .order-flow-types {
        padding-top: 15px;

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 1fr;
        gap: 18px;

        .order-flow-type {
          padding: 30px 25px;

          display: grid;
          place-items: center;
          gap: 28px;

          background: linear-gradient(180deg, #f2f7fd 0%, #ffffff 69.29%), #ffffff;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.2);
          border-radius: 8px;

          @include typography.h1;
          font-weight: 400;
          margin-bottom: 0;

          cursor: pointer;

          img {
            align-self: end;
            width: 48px;
          }
          .label {
            align-self: start;
          }
        }
      }
    }
  }
}
