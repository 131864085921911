@use "../common/default-theme" as default-theme;
@use "../common/cta" as cta;
@use "../common/typography" as typography;

.login-widget {
  height: 100vh;

  > .header {
    margin: 0 !important;
    width: 0;
  }

  > .body {
    height: 100%;
  }

  .login {
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 30% 70%;

    .illustration {
      background-image: url('../images/login-hero-image.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      padding: 70px 0;
      text-align: center;

      .text {
        @include typography.h1();
        margin: 10px auto;
        width: 90%;
        font-size: 26px;
        font-weight: 400;
        line-height: 34px;
      }
    }

    .form {
      background-color: #{default-theme.$login-bg};
      display: grid;
      grid-template-rows: min-content 1fr min-content;
      align-items: center;

      .trouble-logging-in {
        @include typography.h5();
        color: #{default-theme.$secondary-fg};
        margin: 0;
        text-align: end;
        margin-right: 60px;
        margin-top: 50px;
        cursor: pointer;
      }

      > form.default {
        width: 60%;
        max-width: 450px;
        margin: 0 auto;

        .form-input {
          position: relative;

          input {
            outline: none;
            background-color: #{default-theme.$bg}21;
            color: #{default-theme.$bg};
            border: none;

            &::placeholder {
              color: #{default-theme.$bg}90;
            }
          }

          &.invalid {
            input {
              background-color: #{default-theme.$message-error-fg}10;
              border: 1px solid #{default-theme.$message-error-fg};

              &::placeholder {
                color: #{default-theme.$bg}90;
              }
            }
          }

          .show-password {
            position: absolute;
            right: 15px;
            top: 12px;
            color: #{default-theme.$bg}90;
            font-size: 12px;
            cursor: pointer;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }

        .login-button {
          @include cta.primary-cta;
          width: 100%;
          margin-top: 20px;
        }
      }

      .footer {
        @include typography.p();
        color: #{default-theme.$secondary-fg};
        position: relative;
        text-indent: 22px;
        margin: 20px auto;

        > span {
          position: absolute;
          height: 15px;
          left: -15px;
          font-size: 22px;
          top: 4px;
        }
      }
    }
  }
}
